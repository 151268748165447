import React, { Component } from 'react';
import withCMS from 'js/hoc/CMSData';
import ImageThumbnailBox from '../ImageThumbnailBox';
import PageLink from '../core/PageLink';

class HeicoModelsSection extends Component {
  render() {
    const { cms, data } = this.props;

    if (!data) return null;

    const title = cms.translate(data.title);
    const subtitle = cms.translate(data.title);

    const currentModelsTitle = cms.translate(data.heicoModels.newModelsTitle);
    const currentModels = data.heicoModels.newModels;

    const oldModelsTitle = cms.translate(data.heicoModels.oldModelsTitle);
    const oldModels = data.heicoModels.oldModels;

    return (
      <div className="heico-models-section">
        <div className="heico-models-wrapper">
          {title && <h2>{title}</h2>}
          {subtitle && <h3>{subtitle}</h3>}
          <div className="heico-models-wrapper">
            <div className="current-models">
              <div className="max-width-wrapper">
                {currentModelsTitle && <h4>{currentModelsTitle}</h4>}
                {currentModels && (
                  <div className="content">
                    {currentModels.map((model, i) => {
                      return (
                        <PageLink link={model.link} key={i}>
                          <ImageThumbnailBox
                            imageUrl={cms.translate(model.modelImage).url}
                            thumbCaption={cms.translate(model.modelCaption)}
                          />
                        </PageLink>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="dashed-line-wrapper">
              <div className="dashed-line" />
            </div>
            <div className="old-models">
              <div className="max-width-wrapper">
                {oldModelsTitle && <h4>{oldModelsTitle}</h4>}
                {oldModels && (
                  <div className="content">
                    {oldModels.map((model, i) => {
                      return (
                        <PageLink link={model.link} key={i}>
                          <ImageThumbnailBox
                            imageUrl={cms.translate(model.modelImage).url}
                            thumbCaption={cms.translate(model.modelCaption)}
                          />
                        </PageLink>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCMS(HeicoModelsSection);
