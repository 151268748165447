import React, { Component } from 'react';
import imgName from '../../../../src/img/icons/white-checkbox.svg';

export default class LabeledSquareCheckbox extends Component {
  state = { hovered: false };
  render() {
    const { checked = false, children, className, medium = false, large = false, ...props } = this.props;
    const { hovered } = this.state;
    let clsName = 'labeled-square-checkbox';
    if (className) clsName += ` ${className}`;
    if (medium) clsName += ' --medium';
    if (large) clsName += ' large';

    return (
      <div
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        className={clsName}
        {...props}
      >
        <div className="_checkbox-wrap">
          <div
            className={`_checkbox ${checked ? '--checked' : ''} ${hovered ? '--hovered' : ''}`}
            style={checked ? { background: `#29a8e0 url(${imgName}) no-repeat center / contain`, border: 'none' } : {}}
          />
        </div>

        <label className="_label">{children}</label>
      </div>
    );
  }
}
