import React from 'react';
import TextInput from '../../components/inputs/TextInput';
import Select from '../../components/inputs/Select';
import TextArea from '../../components/inputs/TextArea';
import Button from '../core/Button';
import { Form } from 'formik';
import API from 'js/util/api';
import { isEmpty } from 'js/util/helpers';

export default class CarServiceForm extends React.Component {
  state = {
    dates: [],
    times: [],
    selectedDate: void 0,
    selectedLocation: void 0
  };

  _updateDates = async () => {
    this.setState({ datesLoading: true });
    const dates = (await API.get(`api/reservations/dates?location=${this.state.selectedLocation}`)).body || [];

    this.setState({ dates, datesLoading: false }, () => {
      this._updateTimes();
    });
  };

  _updateTimes = async () => {
    this.setState({ timesLoading: true });
    const times =
      (this.state.selectedDate &&
        (await API.get(`api/reservations?date=${this.state.selectedDate}&location=${this.state.selectedLocation}`))
          .body) ||
      [];
    this.setState({ times, timesLoading: false });
  };

  onDateChange = (e, setFieldValue) => {
    this.setState({ selectedDate: e.target.value }, () => {
      this._updateTimes(setFieldValue);
    });
  };
  onLocationChange = e => {
    this.setState({ selectedLocation: e.target.value }, () => {
      this._updateDates();
    });
  };

  render() {
    const { serviceFormData, cms, formik } = this.props;
    const { values, errors, touched, handleChange, isSubmitting, setFieldValue } = formik;
    const { dates, times, timesLoading, datesLoading } = this.state;
    const title = cms.translate(serviceFormData.title);
    const serviceInfoLocation1 = serviceFormData.serviceInfoArea.serviceInfo[0];
    const serviceInfoLocation2 = serviceFormData.serviceInfoArea.serviceInfo[1];
    const carInfoFormTitle = cms.translate(serviceFormData.carInfoForm.title);
    const serviceTypeOptions = serviceFormData.carInfoForm.serviceTypes.map(serviceType => {
      return { value: cms.translate(serviceType), label: cms.translate(serviceType) };
    });

    const customerFormTitle = cms.translate(serviceFormData.customerFormTitle);
    const serviceTimeFormTitle = cms.translate(serviceFormData.serviceTimeFormTitle);
    const buttonTitle = cms.translate(serviceFormData.buttonTitle);

    return (
      <Form>
        <div
          className="service-form-section
        "
          ref={this.props.refprop}
        >
          <div className="service-form-wrapper" ref={this.props.bookingFormRef}>
            {title && <h4>{title}</h4>}
            <div className="service-info-area">
              {serviceInfoLocation1 ? (
                <div className="service-info">
                  <div className="location">{serviceInfoLocation1.serviceLocation.et}</div>
                  <div className="opening-times">{serviceInfoLocation1.address.et}</div>
                  <div className="address">{serviceInfoLocation1.openingTimes.et}</div>
                  <div className="phone" dangerouslySetInnerHTML={{ __html: serviceInfoLocation1.phone.et }} />
                  <div className="email" dangerouslySetInnerHTML={{ __html: serviceInfoLocation1.email.et }} />
                </div>
              ) : null}

              {serviceInfoLocation2 ? (
                <div className="service-info">
                  <div className="location">{serviceInfoLocation2.serviceLocation.et}</div>
                  <div className="opening-times">{serviceInfoLocation2.address.et}</div>
                  <div className="address">{serviceInfoLocation2.openingTimes.et}</div>
                  <div className="phone" dangerouslySetInnerHTML={{ __html: serviceInfoLocation2.phone.et }} />
                  <div className="email" dangerouslySetInnerHTML={{ __html: serviceInfoLocation2.email.et }} />
                </div>
              ) : null}
            </div>
            {!isEmpty(errors) && Object.keys(touched).length ? (
              <div className="form-errors">
                <div className="error">{cms.translate('error.errorsWhenSubmittingForm')}</div>
              </div>
            ) : null}

            <div className="car-data-form">
              {carInfoFormTitle && <h2>{carInfoFormTitle}</h2>}
              <div className="car-data-flex-wrapper">
                <div className="car-data left">
                  <TextInput
                    label={cms.translate('form.make')}
                    onChange={handleChange}
                    value={values.carMake}
                    errors={cms.translate(
                      errors && errors.carMake && errors.carMake.length > 0 && cms.translate(errors.carMake[0])
                    )}
                    touched={touched.carMake}
                    id="carMake"
                  />
                  <TextInput
                    label={cms.translate('form.model')}
                    onChange={handleChange}
                    value={values.carModel}
                    errors={cms.translate(
                      errors && errors.carModel && errors.carModel.length > 0 && cms.translate(errors.carModel[0])
                    )}
                    touched={touched.carModel}
                    id="carModel"
                  />
                  <TextInput
                    label={cms.translate('form.licencePlate')}
                    onChange={handleChange}
                    value={values.licencePlate}
                    errors={cms.translate(
                      errors &&
                        errors.licencePlate &&
                        errors.licencePlate.length > 0 &&
                        cms.translate(errors.licencePlate[0])
                    )}
                    touched={touched.licencePlate}
                    id="licencePlate"
                  />
                  <TextInput
                    label={cms.translate('form.mileage')}
                    onChange={handleChange}
                    value={values.mileage}
                    errors={cms.translate(
                      errors && errors.mileage && errors.mileage.length > 0 && cms.translate(errors.mileage[0])
                    )}
                    touched={touched.mileage}
                    id="mileage"
                  />
                </div>
                <div className="car-data right">
                  <Select
                    options={serviceTypeOptions}
                    label={cms.translate('form.selectService')}
                    defaultOption={{ value: '', label: cms.translate('form.selectDefault') }}
                    onChange={handleChange}
                    value={values.selectService}
                    errors={cms.translate(
                      errors &&
                        errors.selectService &&
                        errors.selectService.length > 0 &&
                        cms.translate(errors.selectService[0])
                    )}
                    touched={touched.selectService}
                    id="selectService"
                  />
                  <TextArea
                    flex
                    label={cms.translate('form.additionalInformation')}
                    onChange={handleChange}
                    value={values.additionalInformation}
                    errors={cms.translate(
                      errors &&
                        errors.additionalInformation &&
                        errors.additionalInformation.length > 0 &&
                        cms.translate(errors.additionalInformation[0])
                    )}
                    touched={touched.additionalInformation}
                    id="additionalInformation"
                  />
                </div>
              </div>
            </div>
            <div className="booking-data">
              <div className="personal-info">
                {customerFormTitle && <h2>{customerFormTitle}</h2>}
                <div className="flex-wrapper">
                  <TextInput
                    label={cms.translate('form.name')}
                    onChange={handleChange}
                    value={values.name}
                    errors={cms.translate(
                      errors && errors.name && errors.name.length > 0 && cms.translate(errors.name[0])
                    )}
                    touched={touched.name}
                    id="name"
                  />
                  <TextInput
                    label={cms.translate('form.phone')}
                    onChange={handleChange}
                    value={values.phone}
                    errors={cms.translate(
                      errors && errors.phone && errors.phone.length > 0 && cms.translate(errors.phone[0])
                    )}
                    touched={touched.phone}
                    id="phone"
                  />
                  <TextInput
                    label={cms.translate('form.email')}
                    onChange={handleChange}
                    value={values.email}
                    errors={cms.translate(
                      errors && errors.email && errors.email.length > 0 && cms.translate(errors.email[0])
                    )}
                    touched={touched.email}
                    id="email"
                  />
                </div>
              </div>

              <div className="time-selection">
                {serviceTimeFormTitle && <h2>{serviceTimeFormTitle}</h2>}
                <div className="flex-wrapper">
                  <Select
                    options={[{ value: 1, label: 'Pärnu' }, { value: 2, label: 'Tallinn' }]}
                    label={cms.translate('form.selectLocation')}
                    defaultOption={{ value: '', label: cms.translate('form.selectLocation') }}
                    onChange={e => {
                      this.onLocationChange(e);
                      handleChange(e);
                    }}
                    value={values.selectLocation}
                    errors={cms.translate(
                      errors &&
                        errors.selectLocation &&
                        errors.selectLocation.length > 0 &&
                        cms.translate(errors.selectLocation[0])
                    )}
                    touched={touched.selectLocation}
                    id="selectLocation"
                  />
                  <Select
                    options={dates.map(d => ({ value: d, label: d }))}
                    defaultOption={{ value: '', label: cms.translate('form.selectDate') }}
                    label={cms.translate('form.selectDate')}
                    onChange={e => {
                      this.onDateChange(e, setFieldValue);
                      handleChange(e);
                    }}
                    disabled={datesLoading}
                    value={values.selectDate}
                    errors={cms.translate(
                      errors && errors.selectDate && errors.selectDate.length > 0 && cms.translate(errors.selectDate[0])
                    )}
                    touched={touched.selectDate}
                    id="selectDate"
                  />
                  <Select
                    options={times.map(d => ({ value: d, label: d }))}
                    defaultOption={{ value: '', label: cms.translate('form.selectTime') }}
                    label={cms.translate('form.selectTime')}
                    onChange={handleChange}
                    disabled={timesLoading}
                    value={values.selectTime}
                    errors={cms.translate(
                      errors && errors.selectTime && errors.selectTime.length > 0 && cms.translate(errors.selectTime[0])
                    )}
                    touched={touched.selectTime}
                    id="selectTime"
                  />
                </div>
              </div>
            </div>
            <div className="booking-button-wrapper">
              {buttonTitle && (
                <Button type="submit" disabled={isSubmitting} showLoading={isSubmitting} theme="dark-gray">
                  {buttonTitle}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Form>
    );
  }
}
