import React, { Component } from 'react';
import Dots from '../Dots';

export default class HorizontalContentSection extends Component {
  render() {
    const { children, title, centered } = this.props;
    const style = {};
    if (centered) style.justifyContent = 'center';
    return (
      <div className="horizontal-content-section">
        <Dots />
        <div className="max-width-wrapper" style={style}>
          <h2>{title}</h2>
          <div className="content">{children}</div>
        </div>
      </div>
    );
  }
}
