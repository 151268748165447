import React, { Component } from 'react';
import { animated } from 'react-spring';
import LabeledSquareCheckbox from '../inputs/LabeledSquareCheckbox';

export default class CarThumbWithCheckbox extends Component {
  render() {
    const { selected = false, onClick, car, style } = this.props;

    return (
      <animated.div
        className={`car-thumb-with-checkbox ${selected ? '--selected' : ''}`}
        onClick={onClick}
        style={{ ...style, backgroundImage: `url(${car.thumbnail.url})` }}
      >
        <LabeledSquareCheckbox checked={selected} className="_name-and-checkbox" medium={true}>
          {car.name}
        </LabeledSquareCheckbox>
      </animated.div>
    );
  }
}
