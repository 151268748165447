import React, { Component } from 'react';
import withCMS from '../hoc/CMSData';
import HeroSection from '../components/sections/HeroSection';
import InfoBox from '../components/InfoBox';
import Section from '../components/sections/Section';
import HorizontalContentSection from '../components/sections/HorizontalContentSection';
import CarLeaseFormSection from '../components/sections/CarLeaseFormSection';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';

class LeasingPage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef(); // Create a ref object
  }

  render() {
    const { cms } = this.props;
    const leasePage = cms.getPage('leasing');
    if (!leasePage) return null;

    const heroHeader = cms.translate(leasePage.meta.heroSection.title);
    const heroImage = cms.translate(leasePage.meta.heroSection.heroImage);

    let heroButton = leasePage.meta.heroSection.heroButton;
    heroButton.action = () => {
      this.formRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    };
    const leaseIntroTexts = leasePage.meta.introSection.textbox;

    const leasePartners = leasePage.meta.partnerSection.partners;

    const conditionTitle = cms.translate(leasePage.meta.horizontalSection.title);
    const leaseConditions = leasePage.meta.horizontalSection.leaseConditions;

    if (!heroHeader || !heroButton || !leaseIntroTexts || !leasePartners || !conditionTitle || !leaseConditions)
      return null;

    return (
      <div className="page car-lease">
        <ScrollToTopOnMount />
        <HeroSection imageUrl={heroImage.url} headerText={heroHeader} button={heroButton} />
        <Section>
          <InfoBox>
            {leaseIntroTexts.map((introText, i) => (
              <span key={i}>
                {i !== 0 && <br />}
                {i !== 0 && <br />}
                {cms.translate(introText)}
              </span>
            ))}
          </InfoBox>
          <div className="lease-partners">
            {leasePartners.map((partner, i) => {
              const partnerImage = cms.translate(partner.partnerImage);
              return partnerImage ? (
                <img
                  key={i}
                  src={partnerImage.url}
                  alt={'Import Auto, sõltumatu Volvo spetsialist - ' + cms.translate(partner.altText)}
                />
              ) : null;
            })}
          </div>
        </Section>
        <HorizontalContentSection title={conditionTitle}>
          <ul>
            {leaseConditions.map((condition, i) => (
              <li key={i}>{cms.translate(condition)}</li>
            ))}
          </ul>
        </HorizontalContentSection>
        <CarLeaseFormSection refprop={this.formRef} page={leasePage} />
      </div>
    );
  }
}

export default withCMS(LeasingPage);
