import React, { PureComponent } from 'react';
import withCMS from 'js/hoc/CMSData';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';
import HeroSection from 'js/components/sections/HeroSection';
import HeicoInfoSection from 'js/components/sections/HeicoInfoSection';

class AboutPage extends PureComponent {
  render() {
    const { cms } = this.props;
    const aboutPage = cms.getPage('about');
    if (!aboutPage) return null;

    const heroHeader = cms.translate(aboutPage.meta.heroSection.title);
    const smallHeader = cms.translate(aboutPage.meta.heroSection.smallHeader);
    const heroImage = cms.translate(aboutPage.meta.heroSection.heroImage);
    const heroButton = aboutPage.meta.heroSection.heroButton;

    const data = aboutPage.meta.importAutoInfo;

    return (
      <div className="page about-page">
        <ScrollToTopOnMount />
        {heroHeader && heroImage && (
          <HeroSection headerText={heroHeader} imageUrl={heroImage.url} button={heroButton} smallHeader={smallHeader} />
        )}
        <HeicoInfoSection data={data} />
      </div>
    );
  }
}

export default withCMS(AboutPage);
