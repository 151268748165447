import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

export default class Select extends Component {
  static propTypes = {
    options: PropTypes.array // { value, label }
  };

  render() {
    const { label, errors, touched, value, options = [], defaultOption, ...props } = this.props;

    return (
      <Input label={label}>
        <div className="select-wrapper">
          <select
            defaultValue={''}
            value={value}
            className={`input input--select ${touched && errors ? 'invalid' : ''} ${
              value === '-' || value === undefined ? 'untouched' : ''
            }`}
            {...props}
          >
            {defaultOption && (options.length > 0 || options) && (
              <option disabled value={''}>
                {defaultOption.label}
              </option>
            )}
            {options.map((option, i) => (
              <option value={option.value} key={i} disabled={option.disabled} hidden={option.hidden}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {touched && errors && <span className={'input-errors'}>{errors}</span>}
      </Input>
    );
  }
}
