import React from 'react';
import withSubscriptions from './SubscriptionHandler';

export default function withCarComparison(WrappedComponent, selectData) {

  class CarComparison extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        cars: [],
        message: '',
        showMessage: false
      };

      this.subscribed = false;
    }

    onLocalStorageChange = () => {
      this.loadFromLocalStorage();
    }

    loadFromLocalStorage() {
      const carsJson = localStorage.getItem('comparable-cars');
      if (carsJson !== null) {
        this.setState({cars: JSON.parse(carsJson)});
      }
    }

    componentDidMount() {

      if (localStorage != null && localStorage.getItem('ls-exists') === null) {
        this.loadFromLocalStorage();

        window.addEventListener('storage', this.onLocalStorageChange);
        this.subscribed = true;
      }
    }

    componentWillUnmount() {
      if (this.subscribed) {
        window.removeEventListener('storage', this.onLocalStorageChange);
      }
    }

    addCarToComparison = car => {
      const { showMessage, message } = this.state;

      const newMessage = {
        message: (<div>Lisatud <span className="clickable" onClick={this.openModal}>võrdlusesse</span> ({this.state.cars.length + 1}/5)</div>),
        icon: 'check'
      };

      const newCars = [...this.state.cars, car];
      this.setState({
        cars: newCars,
        showMessage: !showMessage,
        message: !showMessage ? newMessage : message,
      }, () => this.updateLocalStorage(newCars));

      if (showMessage === true) {
        this.props.setTimeout(() => {
          this.setState({
            showMessage: true,
            message: newMessage
          });
        }, 200);
      }

      this.props.setTimeout(() => {
        this.setState({
          showMessage: false
        });
      }, 3000);
    }


    removeCarFromComparison = car => {
      const { showMessage, message } = this.state;

      const index = this.state.cars.findIndex(_car => _car.id === car.id);

      const newCars = [...this.state.cars];
      newCars.splice(index, 1);

      if (newCars.length === 0) {
        document.body.classList.remove('modal-open');
      }

      const newMessage = {
        message: (<div>Eemaldatud võrdlusest</div>),
        icon: 'cross'
      };

      this.setState({
        cars: newCars,
        showMessage: !showMessage,
        message: !showMessage ? newMessage : message,
      }, () => this.updateLocalStorage(newCars));


      if (showMessage === true) {
        this.props.setTimeout(() => {
          this.setState({
            showMessage: true,
            message: newMessage
          });
        }, 200);
      }

      this.props.setTimeout(() => {
        this.setState({
          showMessage: false
        });
      }, 3000);
    }

    clearCarsFromComparison = () => {
      this.setState({
        cars: []
      }, () => this.updateLocalStorage([]));
    }

    updateLocalStorage = cars => {

      if (localStorage != null && localStorage.getItem('ls-exists') === null) {
        localStorage.setItem('comparable-cars', JSON.stringify(cars));
      }

      const event = document.createEvent('Event');
      event.initEvent('storage', true, true);
      window.dispatchEvent(event);
    }

    toggleCarToComparison = car => {

      const index = this.state.cars.findIndex(_car => _car.id === car.id);

      if (index < 0) {

        if (this.state.cars.length >= 5) {

          this.setState({
            showMessage: true,
            message: {
              message: (<span>Limiit täis (5/5). <span onClick={this.clearCarsFromComparison} className="bold">Nulli võrdlus!</span></span>),
              icon: 'warn'
            },
          });

          this.props.setTimeout(() => {
            this.setState({
              showMessage: false
            });
          }, 3000);

          return false;
        }

        this.addCarToComparison(car);
      } else {
        this.removeCarFromComparison(car);
      }
    }

    openModal = () => {
      if (this.state.cars.length) {
        document.body.classList.add('modal-open');
      }
    };

    renderToggleMessages = () => {
      const { showMessage, message } = this.state;

      return (
        <div className={`car-comparison-toggle-message status-${message.icon} ${!showMessage ? 'inactive' : ''}`}>
          <div className="tooltip-content-wrap">
            <div className={`tooltip-icon icon-${message.icon}`} />
            <div className="tooltip-message">
              {message.message}
            </div>
          </div>
        </div>
      );
    }

    render() {
      return <WrappedComponent
        comparedCars={this.state.cars}
        addCarToComparison={this.addCarToComparison}
        toggleCarToComparison={this.toggleCarToComparison}
        removeCarFromComparison={this.removeCarFromComparison}
        clearCarsFromComparison={this.clearCarsFromComparison}
        renderToggleMessages={this.renderToggleMessages}
        {...this.props}
      />
    }
  }

  return withSubscriptions(CarComparison);
}
