import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import { withRouter } from 'react-router-dom';
import { animated } from 'react-spring';
import * as CarUtils from '../../util/cars';

class CarsForSaleListItem extends Component {
  render() {
    const { car, style, cms } = this.props;
    const mainImageUrl = car.mainMedia ? car.mainMedia.mediumUrl : void 0;

    return (
      <animated.div className="cars-for-sale-list-item" style={style}>
        <a
          className="_car-link"
          href={this.getCarUrl(car)}
          onClick={e => {
            e.preventDefault();
            this.navigateToCar(car);
          }}
        >
          <div
            className="_image"
            style={
              mainImageUrl
                ? { backgroundImage: `url(${mainImageUrl})` }
                : {
                    background: `url(${require('img/placeholder.png')}) #f1f2fa no-repeat 50%/60%`,
                    MozTransform: 'scaleX(-1)',
                    OTransform: 'scaleX(-1)',
                    WebkitTransform: 'scaleX(-1)',
                    transform: 'scaleX(-1)',
                    filter: 'FlipH',
                    MsFilter: 'FlipH'
                  }
            }
          />

          <div className="_content-area">
            <h4 className="_title">{CarUtils.getTitle(cms, car)}</h4>
            <div className="_icons-area">
              {this.renderContentIcon('car', CarUtils.getBodyType(cms, car))}
              {this.renderContentIcon('calendar', CarUtils.getRegistrationDate(cms, car))}
              {this.renderContentIcon('fuel', CarUtils.getFuelTypeShort(cms, car))}
              {this.renderContentIcon('mileage', CarUtils.getMileage(cms, car))}
              {this.renderContentIcon('paint', CarUtils.getColor(cms, car))}
              {this.renderContentIcon('gearbox', CarUtils.getTransmissionShort(cms, car))}
            </div>
          </div>

          <div className="_prices-area">
            <div className="_center-prices">
              <span className="_price">{CarUtils.getCurrentPrice(cms, car)} €</span>
              <span className="_monthly-price">
                {cms.translate('car.monthlyPayment')} {CarUtils.getMonthlyPayment(cms, car)} €
              </span>
            </div>

            <div className="_tax-text">{CarUtils.getTaxString(cms, car)}</div>
          </div>

          <div className="_mobile-content">
            <h4 className="_title">{CarUtils.getTitle(cms, car)}</h4>
            <div className="_bottom-content">
              <div className="_car-data">
                {this.renderContentIcon('car', CarUtils.getBodyType(cms, car))}
                {this.renderContentIcon('mileage', CarUtils.getMileage(cms, car))}
                {this.renderContentIcon('calendar', CarUtils.getRegistrationDate(cms, car))}
                {this.renderContentIcon('paint', CarUtils.getColor(cms, car))}
                {this.renderContentIcon('fuel', CarUtils.getFuelTypeShort(cms, car))}
                {this.renderContentIcon('gearbox', CarUtils.getTransmissionShort(cms, car))}
              </div>
              <div className="_price-wrap">
                <div className="_price">{CarUtils.getCurrentPrice(cms, car)} €</div>
              </div>
            </div>
          </div>
        </a>
      </animated.div>
    );
  }

  getRegDate = car => {
    const _splitRegDate = car.registrationDate.split('-');
    const year = _splitRegDate[0];
    const month = _splitRegDate[1];
    if (year === void 0 && month === void 0) return '-';
    if (year !== void 0 && month !== void 0) return `${month}/${year}`;
    return `${month || year}`;
  };

  renderContentIcon(iconName, value) {
    return (
      <div className="_content-icon">
        <div className={`_icon --${iconName}`} />
        <div className="_value">{value}</div>
      </div>
    );
  }

  getCarUrl = car => {
    const { cms } = this.props;
    return cms.getCarUrl(car);
  };

  navigateToCar = car => {
    this.props.history.push(this.getCarUrl(car));
  };
}

export default withCMS(withRouter(CarsForSaleListItem));
