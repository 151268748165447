import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import withCMS from '../../hoc/CMSData';
import PageLink from '../core/PageLink';
import ServicesDropdown from './ServicesDropdown';

class MainNavHeader extends Component {
  state = {
    showServicesMenu: false
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleOutsideClick, false);
  };

  handleOutsideClick = e => {
    if (!this.state.showServicesMenu) return;
    this.toggleServicesMenu(e);
  };

  toggleServicesMenu = (e, forceHide = false) => {
    if (this._disallowClick) return;
    const shown = this.state.showServicesMenu;
    if (shown) {
      if (!forceHide && this._svcDropdown && this._svcDropdown.contains(e.target)) return;
      this.setState({ showServicesMenu: false });
      document.removeEventListener('click', this.handleOutsideClick, false);
      this._disallowClick = true;
      setTimeout(() => (this._disallowClick = false), 300);
    } else {
      this.setState({ showServicesMenu: true });
      document.addEventListener('click', this.handleOutsideClick, false);
    }
  };

  render() {
    const { cms } = this.props;
    const { showServicesMenu } = this.state;

    const landingPage = cms.data.pages.find(page => page.template === 'landing');

    // Header
    let header = cms.data.regions.find(r => r.template === 'header');
    if (!header) return null;
    else header = header.meta;

    // Links
    const links = header.links.links;
    const logo = cms.translate(header.logo.logo);

    return (
      <div className="main-nav-header">
        {/* Logo */}
        {logo && (
          <PageLink
            page={landingPage}
            className="_logo"
            style={{ backgroundImage: `url(${logo.url})` }}
            aria-label="Header logo"
          />
        )}

        {/* Center links */}
        <div className="_links">
          <div
            className={`_dropdown ${showServicesMenu ? '--open' : ''}`}
            ref={el => (this._dropdownButtonEl = el)}
            onClick={this.toggleServicesMenu}
          >
            {cms.translate(header.servicesDropdown.title)} <span className="_chevron" />
            {/* Services Dropdown */}
            <ServicesDropdown
              close={e => this.toggleServicesMenu(e, true)}
              show={showServicesMenu}
              contentRef={el => (this._svcDropdown = el)}
            />
          </div>

          {links.map((link, i) => {
            const title = cms.translate(link.title);
            return (
              title && (
                <PageLink key={i} link={link.link} className="_link">
                  {title}
                </PageLink>
              )
            );
          })}
        </div>

        {/* Languages */}
        {/* Disabled temporarily */}
        {false && <div className="_languages">{this.renderLocaleButtons()}</div>}
      </div>
    );
  }

  renderLocaleButtons() {
    const { cms } = this.props;

    const keys = Object.keys(cms.data.locales);
    return keys.map((locale, i) => {
      return (
        <Fragment key={locale}>
          <a className="_lang" onClick={() => cms.setLocale(locale)}>
            {locale.toUpperCase()}
          </a>
          {i < keys.length - 1 && <div className="_bullet">&bull;</div>}
        </Fragment>
      );
    });
  }
}

export default withRouter(withCMS(MainNavHeader));
