import React from 'react';
import { withRouter } from 'react-router-dom';
import API from 'js/util/api';

const { Provider, Consumer } = React.createContext();

const Translations = {
  en: require('../../../locales/en.json'),
  et: require('../../../locales/et.json'),
  ru: require('../../../locales/ru.json'),
};

class _CMSDataProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.cmsData,
      page: props.page,
      locale: props.cmsData.locale,
      translate: this.translate,
      translateLink: this.translateLink,
      setPage: (page) => this.setState({ page }),
      setLocale: this.setLocale,
      getLocale: this.getLocale,
      getRegion: this.getRegion,
      getPage: this.getPage,
      getCarUrl: this.getCarUrl,
      getCarFromUrl: this.getCarFromUrl,
    };
  }

  translate = (value) => {
    if (!value) return void 0;
    const locale = this.state.locale;

    if (typeof value === 'string') {
      if (!Translations[locale]) return;
      const parts = value.split('.');
      let T = Translations[locale];
      for (const part of parts) {
        T = T[part];
        if (T === void 0) break;
      }
      return T || value;
    }

    if (typeof value === 'object') {
      if (value[locale]) return value[locale];
    }
  };

  translateLink = (value) => {
    if (!value) return '#';
    if (value[this.state.locale]) return `/${this.state.locale}${value[this.state.locale]}`;
    return `/${this.state.data.defaultLocale}${value[this.state.data.defaultLocale]}`;
  };

  getRegion = (template) => {
    if (!this.state || !this.state.data || !this.state.data.regions) return void 0;
    return this.state.data.regions.find((r) => r.template === template);
  };

  getPage = (template) => {
    if (!this.state || !this.state.data || !this.state.data.pages) return void 0;
    return this.state.data.pages.find((p) => p.template === template);
  };

  setLocale = (locale) => {
    if (this.state.locale !== locale) {
      const page = this.state.page;
      if (!page.slug[locale]) return;
      this.setState({ locale }, () => {
        this.props.history.push(`/${locale}${page.slug[locale]}`);
      });
    }
  };

  getLocale = () => {
    return this.state.locale;
  };

  getCarUrl = (car) => {
    const r = (s) => s.replace(/[\s/\-()?:]/g, '-').replace(/-+/g, '-');
    const carsForSalePage = this.getPage('cars-for-sale');
    const slug = this.translate(carsForSalePage.slug);
    return `${slug}/${car.id}-${r(car.make)}-${r(car.model.name)}-${r(car.model.trim)}`.toLowerCase();
  };

  getCarFromUrl = async (url) => {
    const parts = url.split('-');
    const carId = Number(parts[0]);
    return (await API.get(`api/car/${carId}`)).body;
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export const CMSDataProvider = withRouter(_CMSDataProvider);

export default (Component) => {
  class CMSWrapper extends React.Component {
    render() {
      return <Consumer>{(context) => <Component cms={context} {...this.props} />}</Consumer>;
    }
  }
  CMSWrapper.displayName = `CMS(${Component.displayName || Component.name || 'Component'})`;
  return CMSWrapper;
};
