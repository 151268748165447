import React from 'react';
import withCMS from './CMSData';

const { Provider, Consumer } = React.createContext();

export const LeaseTypes = (leasePage, cms) => {
  return leasePage.meta.leaseForm.leaseTypeForm.leaseTypes.map(leaseType => {
    return {
      value: cms.translate(leaseType.leaseTypeValue),
      label: cms.translate(leaseType.leaseTypeLabel)
    };
  });
};

export const LeasePeriods = (leasePage, cms) => {
  return leasePage.meta.leaseForm.leaseTypeForm.leaseDuration
    .map(leaseDuration => {
      return {
        value: cms.translate(leaseDuration.leaseDuration),
        label: cms.translate(leaseDuration.leaseDurationLabel)
      };
    })
    .sort((a, b) => a.value - b.value);
};

class _LeasingDataProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carPrice: void 0,
      carMake: '-',
      carModel: '-',
      carProductionYear: '-',
      downPayment: '10',
      downPaymentType: 'percent',
      leasePeriod: 60,
      interestRate: 2.5,
      residualValuePercentage: 25,
      leaseType: 'operating',
      setPrice: this.setPrice,
      setDownPayment: this.setDownPayment,
      setLeasePeriod: this.setLeasePeriod,
      setInterestRate: this.setInterestRate,
      setResidualValuePercentage: this.setResidualValuePercentage,
      setLeaseType: this.setLeaseType,
      setCarMake: this.setCarMake,
      setCarModel: this.setCarModel,
      setCarProductionYear: this.setCarProductionYear
    };
  }

  setCarMake = carMake => {
    this.setState({ carMake: carMake });
  };

  setCarModel = carModel => {
    this.setState({ carModel: carModel });
  };

  setCarProductionYear = carProductionYear => {
    this.setState({ carProductionYear: carProductionYear });
  };

  setPrice = carPrice => {
    this.setState({ carPrice: carPrice });
  };

  setDownPayment = (type, downPaymentAmount) => {
    this.setState({ downPayment: downPaymentAmount, downPaymentType: type });
  };

  setLeasePeriod = leasePeriod => {
    this.setState({ leasePeriod: leasePeriod });
  };

  setLeaseType = leaseType => {
    this.setState({ leaseType: leaseType });
  };

  setInterestRate = interestRate => {
    interestRate = interestRate.replace(',', '.');
    this.setState({ interestRate: interestRate });
  };

  setResidualValuePercentage = residualValuePercentage => {
    this.setState({ residualValuePercentage: residualValuePercentage });
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}
export const LeasingDataProvider = withCMS(_LeasingDataProvider);

export default Component => {
  class LeasingData extends React.Component {
    render() {
      return <Consumer>{context => <Component leasingData={context} {...this.props} />}</Consumer>;
    }
  }
  LeasingData.displayName = `Leasing(${Component.displayName || Component.name || 'Component'})`;
  return LeasingData;
};
