import React, { Component } from 'react';
import Dots from '../Dots';
import withCMS from '../../hoc/CMSData';
import PageLink from '../core/PageLink';
import Button from '../core/Button';

class OpeningTimesSection extends Component {
  render() {
    const { cms } = this.props;

    let data = cms.getRegion('opening-times');
    if (!data) return null;
    data = data.meta.openingTimesSection;

    const background = cms.translate(data.backgroundImage);

    return (
      <div className="opening-times-section" style={background ? { backgroundImage: `url(${background.url})` } : {}}>
        <Dots mirrored />
        {this.renderBottomArea(data)}
      </div>
    );
  }

  renderBottomArea = data => {
    const { cms } = this.props;

    return (
      <div className="_opening-times-footer">
        <div className="_content-wrapper">
          <div className="_content">
            <p className="_small-text">{cms.translate(data.textAreas[0].title)}</p>
            <p className="_large-text">{cms.translate(data.textAreas[0].text)}</p>
            <p className="_large-text">{cms.translate(data.textAreas[0].phone)}</p>
            <p className="_large-text">{cms.translate(data.textAreas[0].email)}</p>
          </div>

          <div className="_content">
            <p className="_small-text">{cms.translate(data.textAreas[1].title)}</p>
            <p className="_large-text">{cms.translate(data.textAreas[1].text)}</p>
            <p className="_large-text">{cms.translate(data.textAreas[1].phone)}</p>
            <p className="_large-text">{cms.translate(data.textAreas[1].email)}</p>
          </div>

          <PageLink link={data.link.link}>
            <Button>{cms.translate(data.link.title)}</Button>
          </PageLink>
        </div>
      </div>
    );
  };
}

export default withCMS(OpeningTimesSection);
