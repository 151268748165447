import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import * as CarUtils from '../../util/cars';
import { withRouter } from 'react-router-dom';
import API from 'js/util/api';

class CarsForSaleSingleSeeAlsoOffers extends Component {
  state = {
    relatedCars: []
  };

  componentDidMount() {
    this.getRelatedCars();
  }

  render() {
    const { cms } = this.props;
    const { relatedCars } = this.state;

    return (
      <div className="cars-for-sale-single-see-also-offers">
        <h2 className="_title">{cms.translate('carsForSaleView.seeAlsoOffers')}</h2>
        <div className="_offered-cars-list">{relatedCars.map(car => this.renderOfferBox(car))}</div>
      </div>
    );
  }

  navigateToCar = car => {
    const { cms } = this.props;
    const url = cms.getCarUrl(car);
    this.props.history.push(url);
  };

  getRelatedCars = async () => {
    const { car } = this.props;
    const cars = (await API.get(`api/car/${car.id}/related`)).body;
    this.setState({ relatedCars: cars });
  };

  renderOfferBox = car => {
    const { cms } = this.props;
    const mainMedia = car.mainMedia && car.mainMedia.mediumUrl;

    return (
      <div className="_offered-car" key={car.id} onClick={() => this.navigateToCar(car)}>
        {mainMedia ? (
          <img className="_img" src={mainMedia} alt="Import Auto, sõltumatu Volvo spetsialist - Car" />
        ) : (
          <div className="_placeholder" />
        )}

        <div className="_car-data">
          <h3 className="_car-title">{CarUtils.getTitle(cms, car)}</h3>
          <div className="_data">
            <img
              src={require('../../../img/icons/blue-icons/car.svg')}
              alt="Import Auto, sõltumatu Volvo spetsialist - Car"
            />
            {CarUtils.getBodyType(cms, car)}
          </div>
          <div className="_data">
            <img
              src={require('../../../img/icons/blue-icons/calendar.svg')}
              alt="Import Auto, sõltumatu Volvo spetsialist - Calendar"
            />
            {CarUtils.getRegistrationDate(cms, car)}
          </div>
          <div className="_data">
            <img
              src={require('../../../img/icons/blue-icons/fuel.svg')}
              alt="Import Auto, sõltumatu Volvo spetsialist - Fuel"
            />
            {car.fuel.type ? CarUtils.getFuelTypeShort(cms, car) : null}
          </div>
          <div className="_data">
            <img
              src={require('../../../img/icons/blue-icons/mileage.svg')}
              alt="Import Auto, sõltumatu Volvo spetsialist - Mileage"
            />
            {CarUtils.getMileage(cms, car)}
          </div>
          <div className="_data">
            <img
              src={require('../../../img/icons/blue-icons/paint.svg')}
              alt="Import Auto, sõltumatu Volvo spetsialist - Color"
            />
            {CarUtils.getColor(cms, car)}
          </div>
          <div className="_data">
            <img
              src={require('../../../img/icons/blue-icons/gearbox.svg')}
              alt="Import Auto, sõltumatu Volvo spetsialist - Gearbox"
            />
            {CarUtils.getTransmissionShort(cms, car)}
          </div>
        </div>

        <div className="_bottom-area">
          <div className="_prices-wrap">
            <span className="_price">{CarUtils.getCurrentPrice(cms, car)} €</span>
            <p className="_monthly-payment">
              {cms.translate('car.monthlyPayment')} {CarUtils.getMonthlyPayment(cms, car)} €
            </p>
          </div>

          <p className="_tax-text">{CarUtils.getTaxString(cms, car)}</p>
        </div>
      </div>
    );
  };
}

export default withRouter(withCMS(CarsForSaleSingleSeeAlsoOffers));
