import React, { Component } from 'react';
import withCMS from '../hoc/CMSData';
import HeroSection from '../components/sections/HeroSection';
import ContactInfoWithMapSection from '../components/sections/ContactInfoWithMapSection';
import TeamInfoSection from '../components/sections/TeamInfoSection';
import ContactUsSection from '../components/sections/ContactUsSection';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef(); // Create a ref object
  }

  render() {
    const { cms } = this.props;
    const contactPage = cms.getPage('contact');
    if (!contactPage) return null;

    const heroHeader = cms.translate(contactPage.meta.heroSection.title);
    const heroImage = cms.translate(contactPage.meta.heroSection.heroImage);
    const heroButton = contactPage.meta.heroSection.heroButton;
    heroButton.action = () => {
      this.formRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    };
    const contactInfo = contactPage.meta.contactInfo;
    const mapMarkers = contactPage.meta.mapMarkers.mapMarker.map((marker, index) => {
      marker.id = index;
      return marker;
    });
    const contactFormData = contactPage.meta.contactForm;
    const teamInfo = contactPage.meta.teamInfo;

    return (
      <div className="page contact">
        <ScrollToTopOnMount />

        {heroHeader && heroImage && heroButton && (
          <HeroSection headerText={heroHeader} imageUrl={heroImage.url} button={heroButton} />
        )}
        {contactInfo && <ContactInfoWithMapSection data={contactInfo} mapMarkers={mapMarkers} />}
        {teamInfo && <TeamInfoSection data={teamInfo} />}
        {contactFormData && <ContactUsSection refprop={this.formRef} data={contactFormData} />}
      </div>
    );
  }
}

export default withCMS(ContactPage);
