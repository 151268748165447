import React, { Component } from 'react';

export default class SingleIcon extends Component {
  render() {
    const { iconText, imgUrl } = this.props;
    return (
      <div className="single-icon">
        {imgUrl && <img src={imgUrl} alt="Import Auto, sõltumatu Volvo spetsialist - icon" />}
        <p>{iconText}</p>
      </div>
    );
  }
}
