import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withCMS from '../hoc/CMSData';
import * as CarUtils from '../util/cars';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';
import CarsForSaleSingleDetailsBox from 'js/components/cars-for-sale-single/CarsForSaleSingleDetailsBox';
import CarsForSaleSingleSeeAlsoOffers from 'js/components/cars-for-sale-single/CarsForSaleSingleSeeAlsoOffers';
import LeaseModalSection from 'js/components/sections/LeaseModalSection';
import Loader from 'js/components/Loader';

class CarsForSaleSinglePage extends Component {
  state = {
    leaseModal: false,
    comparisonModal: false,
    car: void 0,
    loading: true
  };

  constructor(props) {
    super(props);
    this.updateCar();
  }

  async updateCar() {
    const carUrl = this.props.match.params.car;
    try {
      const car = await this.props.cms.getCarFromUrl(carUrl);
      this.setState({ car, loading: false });
    } catch (e) {
      this.setState({ loading: false });
      throw new Error('No car found.');
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if ((this.leaseModal && this.leaseModal.contains(e.target)) || e.target.className === '_leasing-button') {
      return;
    }
    this.setState({ leaseModal: false });
  };

  stopMenuScrolling = () => {
    if (this.state.leaseModal && window.innerWidth < 450) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
  };

  render() {
    const { car, loading } = this.state;
    const { cms } = this.props;

    if (loading)
      return (
        <div className="view _loading">
          <ScrollToTopOnMount />
          <Loader size={60} />
        </div>
      );
    if (!car) return null;

    return (
      <div className="page cars-for-sale-single-page">
        <ScrollToTopOnMount />
        <div className="_content-wrapper">
          {this.renderBackButton('--top')}
          <h1 className="_car-title">{CarUtils.getTitle(cms, car)}</h1>
          <CarsForSaleSingleDetailsBox openLeaseModal={() => this.setState({ leaseModal: true })} car={car} />
          <CarsForSaleSingleSeeAlsoOffers car={car} />
          {this.renderBackButton('--bottom')}

          <LeaseModalSection
            contentRef={el => (this.leaseModal = el)}
            show={this.state.leaseModal}
            close={() => this.setState({ leaseModal: false })}
            car={car}
          />
        </div>
      </div>
    );
  }

  renderBackButton = extraClass => (
    <div onClick={this.props.history.goBack} className={`_back-button ${extraClass}`}>
      {this.props.cms.translate('general.back')}
    </div>
  );
}

export default withCMS(withRouter(CarsForSaleSinglePage));
