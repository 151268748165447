import React, { Component } from 'react';
import BusinessCard from '../BusinessCard';
import withCMS from 'js/hoc/CMSData';

class TeamInfoSection extends Component {
  render() {
    const { data, cms } = this.props;

    if (!data && !data.teamMembers) return null;

    return (
      <div className="team-info-section">
        <div className="team-info-wrapper">
          <h2>{cms.translate('general.team')}</h2>
          <div className="business-card-wrapper">
            {data.teamMembers.map((member, i) => (
              <BusinessCard key={i} member={member} defaultImage={data.defaultImage} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withCMS(TeamInfoSection);
