import React from 'react';
import Observer from '@researchgate/react-intersection-observer';

export default class IntersectionObserver extends React.PureComponent {
  state = {
    isIntersecting: false
  };

  handleChange = (event, unobserve) => {
    if (event.isIntersecting) {
      unobserve();
      this.setState({ isIntersecting: true });
    }
  };

  render() {
    const { children } = this.props;
    const { isIntersecting } = this.state;

    return (
      <Observer onChange={this.handleChange} rootMargin="0% 0% -5% 0%">
        {children(isIntersecting)}
      </Observer>
    );
  }
}
