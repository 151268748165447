export const _getCmsUrl = () => {
  let cmsUrl = typeof window !== 'undefined' ? window.__CMS_URL__ : process.env.CMS_URL;
  const match = cmsUrl.match('//(.+)@');
  const credentials = match && match.length >= 1 && match[1];
  if (credentials) cmsUrl = cmsUrl.replace(`${credentials}@`, '');
  return cmsUrl;
};

const _getCmsCredentials = () => {
  const cmsUrl = typeof window !== 'undefined' ? window.__CMS_URL__ : process.env.CMS_URL;
  const match = cmsUrl.match('//(.+)@');
  const credentials = match && match.length >= 1 && match[1];
  return credentials;
};

const _btoa = str => {
  const buffer = str instanceof Buffer ? str : Buffer.from(str.toString(), 'binary');
  return buffer.toString('base64');
};

export const DEFAULT_OPTS = {
  credentials: 'include',
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(_getCmsCredentials() ? { Authorization: `Basic ${_btoa(_getCmsCredentials())}` } : {})
  }
};

// ------------------------------------
// API Client
// ------------------------------------
const get = (path, opts = {}) => fetch(path, { ...opts, method: 'GET' });
const post = (path, opts = {}) => fetch(path, { ...opts, method: 'POST' });
const put = (path, opts = {}) => fetch(path, { ...opts, method: 'PUT' });
const patch = (path, opts = {}) => fetch(path, { ...opts, method: 'PATCH' });
const del = (path, opts = {}) => fetch(path, { ...opts, method: 'DELETE' });

const fetch = (path, customOpts = {}) =>
  new Promise((resolve, reject) => {
    const opts = { ...DEFAULT_OPTS, ...customOpts, ...{ headers: { ...DEFAULT_OPTS.headers, ...customOpts.headers } } };

    if (opts.jsonBody) opts.body = JSON.stringify(opts.jsonBody);

    if (opts.formBody) {
      const formData = new FormData();
      Object.keys(opts.formBody).forEach(key => {
        const val = opts.formBody[key];
        if (val) formData.append(key, val);
      });
      opts.body = formData;
      delete opts.headers['Content-Type']; // Let fetch generate boundaries automatically
    }

    const fetch = typeof window !== 'undefined' ? window.fetch : require('node-fetch');
    const url = `${_getCmsUrl()}/${path}`;

    fetch(url, opts)
      .then(res => {
        if (!res.ok) return Promise.reject(res);

        const contentType = res.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
          if (res.status === 204) {
            return {
              response: res,
              body: null
            };
          }

          return res.json().then(json => ({ response: res, body: json }));
        }
        return res.text().then(text => ({ response: res, body: text }));
      })
      .then(resolve)
      .catch(err => {
        console.error(`** API request failed for ${url}:`, err);
        return reject({ response: err });
      });
  });

export default {
  get,
  post,
  del,
  put,
  patch,
  fetch
};
