import React, { Component } from 'react';
import withCMS from '../hoc/CMSData';

class BusinessCard extends Component {
  render() {
    const { member, cms } = this.props;

    const profileImage = cms.translate(member.profileImage);

    if (!member || !cms.translate(member.name)) return null;

    return (
      <div className="business-card">
        <div className="image-wrapper">
          <div className="_image" style={{ backgroundColor: '#E9F6FC' }}>
            {profileImage && (
              <img
                src={profileImage.url}
                alt={
                  'Import Auto, sõltumatu Volvo spetsialist - ' + cms.translate(member.name) ||
                  'Import Auto, sõltumatu Volvo spetsialist'
                }
              />
            )}
          </div>
        </div>
        <div className="business-card-details">
          <h3>{cms.translate(member.name)}</h3>
          <span>{cms.translate(member.jobTitle)}</span>
          <span className="phone">{cms.translate(member.contact)}</span>
          <span className="email">
            <a href={`mailto:${cms.translate(member.email)}`} target="_top">
              {cms.translate(member.email)}
            </a>
          </span>
        </div>
      </div>
    );
  }
}

export default withCMS(BusinessCard);
