export const createEqualizedGroups = cms => {
  const volvoModels = cms.data.volvoModels;

  const volvoGroups = {};
  for (const volvo of volvoModels) {
    if (!volvoGroups[volvo.type]) volvoGroups[volvo.type] = [];
    volvoGroups[volvo.type].push(volvo);
  }

  // Equalize groups
  let longestGroup = 0;
  for (const group of Object.values(volvoGroups)) {
    longestGroup = group.length > longestGroup ? group.length : longestGroup;
  }

  const uniqueID = () => String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();

  for (const group of Object.values(volvoGroups)) {
    while (group.length < longestGroup) group.push({ id: uniqueID(), _empty: true });
  }

  return volvoGroups;
};
