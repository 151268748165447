export const calcMonthlyPayment = (
  carPrice,
  downPaymentPercent = 10,
  leaseType = 'operating',
  interestRate = 2.5,
  residualValuePercentage = 25,
  leasePeriod = 60
) => {
  let monthlyPayment = 0;
  const VAT = 0.2;
  const interest = interestRate / 100;
  const downPaymentAmount = (carPrice * downPaymentPercent) / 100;

  if (leaseType === 'operating') {
    // Calculate residual value without VAT
    const residualValue = (carPrice / (1 + VAT)) * (residualValuePercentage / 100);

    // Calculate price left after downpayment without VAT
    const netValue = (carPrice - downPaymentAmount) / (1 + VAT);

    //Formulas taken from old code. Legacy formulas
    const brutValue = netValue * (1 + VAT);
    const interestCoefficient = interest * (1 / (11.8275 * (1 + VAT)));

    const annuity =
      brutValue *
      ((interestCoefficient * Math.pow(1 + interestCoefficient, leasePeriod)) /
        (Math.pow(1 + interestCoefficient, leasePeriod) - 1));
    const monthlyInterest = (netValue * interest) / 11.8275;

    monthlyPayment = Math.round((annuity - (residualValue / netValue) * (annuity - monthlyInterest)) * 100) / 100;
  }
  if (leaseType === 'financial' || leaseType === 'financing') {
    const residualValue = (carPrice * residualValuePercentage) / 100;
    const netValue = carPrice - downPaymentAmount;
    const interestCoefficient = interest / 11.8275;
    const annuity =
      netValue *
      ((interestCoefficient * Math.pow(1 + interestCoefficient, leasePeriod)) /
        (Math.pow(1 + interestCoefficient, leasePeriod) - 1));
    const monthlyInterest = netValue * interestCoefficient;
    monthlyPayment = Math.round((annuity - (residualValue / netValue) * (annuity - monthlyInterest)) * 100) / 100;
  }
  if (Number.isNaN(monthlyPayment) || monthlyPayment < 0) {
    monthlyPayment = 0;
  }
  return monthlyPayment;
};

export const tryConvert = (downPayment, carPrice, convert) => {
  const input = parseFloat(downPayment);
  if (Number.isNaN(input)) {
    return '';
  }
  const output = convert(input, carPrice);
  const rounded = Math.round(output * 100) / 100;
  return rounded;
};
