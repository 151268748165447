import React, { PureComponent } from 'react';

export default class Section extends PureComponent {
  render() {
    const { children, className, ...props } = this.props;
    return (
      <section className={`section${className ? ` ${className}` : ''}`} {...props}>
        {children}
      </section>
    );
  }
}
