import React, { Component } from 'react';
import Dots from '../Dots';

export default class SingleImageSection extends Component {
  state = {
    show: false,
  };

  componentDidMount() {
    this.timeoutId = setTimeout(
      function () {
        this.setState({ show: true });
      }.bind(this),
      1500
    );
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { imageUrl, mobileImageUrl, headerText, smallHeader } = this.props;
    return (
      <div className="single-image-section">
        <div
          className={this.state.show ? 'hero-image show desktop' : 'hero-image desktop'}
          style={
            imageUrl ? { backgroundImage: `url(${imageUrl})` } : {} || this.state.show ? 'opacity:0.85' : 'opacity:1'
          }
        />
        <div
          className={this.state.show ? 'hero-image show mobile' : 'hero-image mobile'}
          style={
            mobileImageUrl
              ? { backgroundImage: `url(${mobileImageUrl})` }
              : {} || this.state.show
              ? 'opacity:0.85'
              : 'opacity:1'
          }
        />
        <div className={!this.state.show ? 'hero-content-wrapper' : 'hero-content-wrapper fade-in'}>
          {this.state.show ? <Dots /> : null}
          <div className="hero-content">
            <h1>{headerText}</h1>
            {smallHeader && <h2 dangerouslySetInnerHTML={{ __html: smallHeader }} />}
          </div>
        </div>
      </div>
    );
  }
}
