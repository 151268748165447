import React, { Component, Fragment } from 'react';
import withCMS from '../hoc/CMSData';
import TextInput from './inputs/TextInput';
import Button from './core/Button';
import PageLink from './core/PageLink';
import API from 'js/util/api';

const States = {
  Form: 'form',
  Success: 'success',
  Error: 'error'
};

class PageFooter extends Component {
  state = { email: '', newsLetterState: States.Form };
  render() {
    const { cms } = this.props;

    const landingPage = cms.getPage('landing');
    let footer = cms.getRegion('footer');
    if (!footer) return null;
    footer = footer.meta.footer;

    const logo = cms.translate(footer.logo);

    return (
      <div className="page-footer">
        <div className="_content-wrapper">
          <div className="_content-top">
            {logo && (
              <PageLink
                page={landingPage}
                className="_logo"
                style={{ backgroundImage: `url(${logo.url})` }}
                aria-label="Footer logo"
              />
            )}
            {this.renderLinks(footer)}
            {this.state.newsLetterState === States.Form && this.renderJoinNewsletter(footer)}
            {this.state.newsLetterState === States.Success && this.renderNewsletterResult()}
            {this.state.newsLetterState === States.Error && this.renderNewsletterResult()}
          </div>
          <div className="_content-bottom">{this.renderBottomContent(footer)}</div>
        </div>
      </div>
    );
  }

  renderLinks = footer => {
    const { cms } = this.props;

    return (
      <div className="_links-area">
        {footer.links.map((link, i) => {
          const title = cms.translate(link.title);
          return (
            title && (
              <div className="_link-container" key={i}>
                <PageLink link={link.link} className="_link">
                  {title}
                </PageLink>
              </div>
            )
          );
        })}
      </div>
    );
  };

  renderJoinNewsletter = () => {
    const { cms } = this.props;
    const { email } = this.state;
    return (
      <div className="_join-newsletter-area">
        <h3>{cms.translate('footer.signUpForNewsletter')}</h3>
        <div className="_input-wrapper">
          <TextInput
            value={email}
            onChange={e => this.setState({ email: e.target.value })}
            onKeyPress={e => {
              if (e.key === 'Enter') this.joinNewsletter();
            }}
            errors={this.state.newsletterValidation}
            touched
            placeholder={cms.translate('general.emailPlaceholder')}
          />
          <Button onClick={this.joinNewsletter} small>
            {cms.translate('footer.signUpForNewsletterButton')}
          </Button>
        </div>
      </div>
    );
  };

  renderNewsletterResult = () => {
    const { cms } = this.props;
    const responseText = this.state.newsLetterState === States.Success ? 'success.newsletter' : 'error.newsletter';
    return <div className="_newsletter-result">{cms.translate(responseText)}</div>;
  };

  renderBottomContent = footer => {
    const { cms } = this.props;

    const fbUrl = cms.translate(footer.facebookUrl);
    const igUrl = cms.translate(footer.instagramUrl);

    return (
      <Fragment>
        <div className="_social-icons">
          {igUrl && (
            <a href={igUrl} target="_blank" rel="noopener noreferrer">
              <img src={require('../../img/icons/instagram-logo.svg')} />
            </a>
          )}
          {fbUrl && (
            <a href={fbUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={require('../../img/icons/facebook-logo.svg')}
                alt="Import Auto, sõltumatu Volvo spetsialist - facebook logo"
              />
            </a>
          )}
        </div>
        <div className="_bottom-text" dangerouslySetInnerHTML={{ __html: cms.translate(footer.footerText) }} />
      </Fragment>
    );
  };

  joinNewsletter = () => {
    const { cms } = this.props;
    const { email } = this.state;

    API.post('api/forms/newsletter', { jsonBody: { email, language: cms.getLocale() } })
      .then(() => {
        this.setState({ newsLetterState: States.Success });
        setTimeout(() => this.setState({ newsLetterState: States.Form }), 5000);
      })
      .catch(e => {
        console.error('Subscribing to newsletter failed', e);
        return e.response.json().then(resp => {
          if (resp.error === 'The given data was invalid.') {
            this.setState({
              newsletterValidation: cms.translate('validation.emailFormat')
            });
            setTimeout(() => this.setState({ newsletterValidation: void 0 }), 5000);
          } else {
            setTimeout(() => this.setState({ newsLetterState: States.Error, newsletterValidation: void 0 }), 500);
            setTimeout(() => this.setState({ newsLetterState: States.Form }), 6000);
          }
        });
      });
  };
}

export default withCMS(PageFooter);
