import { PureComponent } from 'react';
import withCMS from 'js/hoc/CMSData';

class ScrollToTopOnMount extends PureComponent {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    window.setTimeout(
      () => (document.title = `${this.props.cms.page.title} - Import Auto, sõltumatu Volvo spetsialist!`)
    );
  };

  render = () => null;
}

export default withCMS(ScrollToTopOnMount);
