import React, { Component } from 'react';
import Input from './Input';

export default class TextInput extends Component {
  render() {
    const { label, errors, touched, value, onChange, ...props } = this.props;

    return (
      <Input label={label}>
        <input
          value={value}
          onChange={onChange}
          className={`input input--text ${touched && errors ? 'invalid' : ''}`}
          type="text"
          {...props}
        />
        {touched && errors && <span className={'input-errors'}>{errors}</span>}
      </Input>
    );
  }
}
