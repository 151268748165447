import React, { Component } from 'react';
import withCarComparison from 'js/hoc/CarComparison';
import * as CarUtils from '../../util/cars';
import withCMS from '../../hoc/CMSData';

class CarComparisonSection extends Component {
  componentWillUnmount() {
    this.closeModal();
  }

  closeModal() {
    document.body.classList.remove('modal-open');
  }

  comparisonKeyHandler = {
    mainMedia: car => (
      <div className="img-wrapper">
        <img
          src={car.mainMedia ? car.mainMedia.mediumUrl : require('img/placeholder.png')}
          alt={car.mainMedia ? car.mainMedia.alt : ''}
        />
        <span className="remove" onClick={() => this.props.removeCarFromComparison(car)} />
      </div>
    ),
    name: car => <div className="separator">{this.parseName(car)}</div>,
    model: car => this.renderSingleCell({ label: 'Keretüüp', value: this.parseType(car) }),
    registrationDate: car =>
      this.renderSingleCell({
        label: 'Esmane registreerimine',
        value: this.parseRegDate(car.registrationDate)
      }),
    price: car => this.renderSingleCell({ label: 'Hind', value: `${+car.price.bargain || car.price.default} EUR` }),
    engine: car =>
      this.renderSingleCell({
        label: 'Mootor',
        value: `${car.engine.capacity.l} ${car.engine.type} ${car.engine.power.kW} kW`
      }),
    vin: car => this.renderSingleCell({ label: 'VIN-kood', value: car.vin })
  };

  parseName = car => {
    return CarUtils.getTitle(null, car);
  };

  parseRegDate = dateString => {
    const dateSplit = dateString.split('-');
    return `${dateSplit[1]}/${dateSplit[0]}`;
  };

  parseType = car => {
    return `${car.type[this.props.cms.data.locale]}, ${car.bodyType.doors} ust, ${car.bodyType.seats} istekohta`;
  };

  renderSingleCell = cellData => {
    const { label, value, checked } = cellData;
    return (
      <div className={`single-cell ${typeof checked !== 'undefined' ? 'check-cell' : ''}`}>
        <div className="cell-label">{label}</div>
        {checked ? <span className="checkmark" /> : <span className="checkmark hidden" />}
        {value ? <div className="cell-value">{value}</div> : null}
      </div>
    );
  };

  renderComparisonRowByKey = (cars, key) => {
    return (
      <div key={key} className="single-row">
        {cars.map(car => (
          <div key={car.id} className="single-column">
            {this.comparisonKeyHandler[key](car)}
          </div>
        ))}
      </div>
    );
  };

  renderComparisonRow = (row, key) => {
    return (
      <div key={key} className="single-row">
        {row.map((col, key) => (
          <div key={key} className="single-column">
            {col}
          </div>
        ))}
      </div>
    );
  };

  createOtherEquipmentKeyMap = comparedCars => {
    const keyMap = {};

    comparedCars.forEach(car => {
      const otherEquipment = car.otherEquipment || [];
      otherEquipment.forEach(equipment => {
        if (typeof keyMap[equipment] === 'undefined') {
          keyMap[equipment] = [];
        }

        if (!keyMap[equipment].includes(car.id)) {
          keyMap[equipment].push(car.id);
        }
      });
    });

    const orderKeyMap = Object.keys(keyMap);

    return {
      comparisonTable: keyMap,
      comparisonSortMap: orderKeyMap.sort((a, b) => {
        if (keyMap[a].length === keyMap[b].length) {
          return 0;
        }

        if (keyMap[a].length > keyMap[b].length) {
          return -1;
        } else {
          return 1;
        }
      })
    };
  };

  createEquipmentKeyMap = comparedCars => {
    const keyMap = {};
    const orderKeyMap = {};

    comparedCars.forEach(car => {
      const carEquipment = car.equipment || [];
      carEquipment.forEach(equipCat => {
        if (typeof keyMap[equipCat.category] === 'undefined') {
          keyMap[equipCat.category] = {};
        }

        equipCat.items.forEach(item => {
          if (typeof keyMap[equipCat.category][item.id] === 'undefined') {
            keyMap[equipCat.category][item.id] = [car.id];
          } else {
            keyMap[equipCat.category][item.id].push(car.id);
          }
        });

        const keys = Object.keys(keyMap[equipCat.category]);

        keys.sort((key1, key2) => {
          if (keyMap[equipCat.category][key1].length === keyMap[equipCat.category][key2].length) {
            return 0;
          }

          if (keyMap[equipCat.category][key1].length > keyMap[equipCat.category][key2].length) {
            return -1;
          } else {
            return 1;
          }
        });

        orderKeyMap[equipCat.category] = keys;
      });
    });

    return {
      comparisonTable: keyMap,
      comparisonSortMap: orderKeyMap
    };
  };

  renderOtherEquipmentComparison = comparedCars => {
    const { comparisonSortMap } = this.createOtherEquipmentKeyMap(comparedCars);

    const rows = [];
    comparisonSortMap.forEach(key => {
      rows.push(
        comparedCars.map(car => {
          return this.renderSingleCell({
            label: key,
            checked: car.otherEquipment && car.otherEquipment.includes(key) ? key : null
          });
        })
      );
    });

    return (
      <div className="separator-wrapper">
        <div className="separator">Oluline lisavarustus</div>
        <div className="comparison-columns">
          {rows.map((equipmentKey, key) => this.renderComparisonRow(equipmentKey, key))}
        </div>
      </div>
    );
  };

  renderEquipmentComparison = comparedCars => {
    const { comparisonTable, comparisonSortMap } = this.createEquipmentKeyMap(comparedCars);

    const rows = {};
    Object.keys(comparisonTable).map(cat => {
      rows[cat] = {};
      return comparisonSortMap[cat].forEach(equipmentKey => {
        const carIds = comparisonTable[cat][equipmentKey];

        carIds.forEach(carId => {
          if (typeof rows[cat][equipmentKey] === 'undefined') {
            rows[cat][equipmentKey] = [];
          }
          const car = comparedCars.find(car => car.id === carId);
          const category = car.equipment.find(equipCat => equipCat.category === cat);
          const item = category.items.find(item => item.id === equipmentKey);
          rows[cat][equipmentKey].push(this.renderSingleCell({ label: item.value.et }));
        });
      });
    });

    return Object.keys(rows).map((category, key) => {
      return (
        <div key={key}>
          <div className="separator">{category}</div>

          <div className="comparison-columns">
            {comparisonSortMap[category].map((equipmentKey, key) =>
              this.renderComparisonRow(rows[category][equipmentKey], key)
            )}
          </div>
        </div>
      );
    });
  };

  render() {
    const { comparedCars } = this.props;

    if (!comparedCars || comparedCars.length === 0) {
      return null;
    }

    //             {this.renderEquipmentComparison(comparedCars)}
    return (
      <div className="car-comparison-section">
        <div className="car-comparison-container">
          <div className="car-comparison-modal">
            <h2>
              Autode võrdlus
              <span className="close" onClick={() => this.closeModal()} />
            </h2>

            <div className="car-comparison-wrapper">
              <div className="comparison-rows">
                {Object.keys(this.comparisonKeyHandler).map(key => this.renderComparisonRowByKey(comparedCars, key))}
              </div>

              {comparedCars && this.renderOtherEquipmentComparison(comparedCars)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCMS(withCarComparison(CarComparisonSection));
