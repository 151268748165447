import React, { Component } from 'react';
import withCMS from '../hoc/CMSData';
import HeroSection from '../components/sections/HeroSection';
import Section from '../components/sections/Section';
import InfoBox from '../components/InfoBox';
import ContactUsSection from '../components/sections/ContactUsSection';
import HeicoModelsSection from '../components/sections/HeicoModelsSection';
import HeicoInfoSection from '../components/sections/HeicoInfoSection';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';

class HeicoPage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef(); // Create a ref object
  }

  render() {
    const { cms } = this.props;
    const heicoPage = cms.getPage('heico');
    if (!heicoPage) return null;

    const heroHeader = cms.translate(heicoPage.meta.heroSection.title);
    const heroSmallHeader = cms.translate(heicoPage.meta.heroSection.smallHeader);
    const heroButton = heicoPage.meta.heroSection.heroButton;
    heroButton.action = () => {
      this.formRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    };
    const heroImage = cms.translate(heicoPage.meta.heroSection.heroImage);

    const heicoIntroTexts = heicoPage.meta.introSection.textbox;

    const heicoModelsData = heicoPage.meta.heicoCars;

    const heicoInfoData = heicoPage.meta.heicoInfo;

    const contactFormData = heicoPage.meta.contactForm;

    if (!heroHeader || !heroSmallHeader || !heroButton) return null;

    return (
      <div className="page heico-page">
        <ScrollToTopOnMount />

        <HeroSection
          imageUrl={heroImage.url}
          headerText={heroHeader}
          smallHeader={heroSmallHeader}
          button={heroButton}
        />
        <Section>
          <InfoBox>
            {heicoIntroTexts.map((introText, i) => (
              <span key={i}>
                {i !== 0 && <br />}
                {i !== 0 && <br />}
                {cms.translate(introText)}
              </span>
            ))}
          </InfoBox>
        </Section>
        <HeicoModelsSection data={heicoModelsData} />
        <HeicoInfoSection data={heicoInfoData} />
        <ContactUsSection refprop={this.formRef} data={contactFormData} />
      </div>
    );
  }
}

export default withCMS(HeicoPage);
