import React, { Component } from 'react';
import withCMS from '../hoc/CMSData';
import HeroSection from '../components/sections/HeroSection';
import Section from '../components/sections/Section';
import HorizontalContentSection from '../components/sections/HorizontalContentSection';
import InfoBox from '../components/InfoBox';
import SingleIcon from '../components/SingleIcon';
import ServiceFormSection from '../components/sections/ServiceFormSection';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';

class ServicePage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  render() {
    const { cms } = this.props;
    const servicePage = cms.getPage('service');
    if (!servicePage) return null;

    //Hero area
    const heroImage = cms.translate(servicePage.meta.heroSection.heroImage);
    const heroButton = servicePage.meta.heroSection.heroButton;
    heroButton.action = () => {
      this.formRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    };
    const heroTitle = cms.translate(servicePage.meta.heroSection.title);
    //Service info area
    const serviceInfo = servicePage.meta.section.sectionArea;
    //Horizontal service reason area
    const serviceReasonTitle = cms.translate(servicePage.meta.horizontalSection.title);
    const serviceReasons = servicePage.meta.horizontalSection.reasons;
    //Form area
    const serviceFormData = servicePage.meta.serviceForm;

    return (
      <div className="page service-page">
        <ScrollToTopOnMount />

        {heroImage && heroButton && heroTitle && (
          <HeroSection imageUrl={heroImage.url} button={heroButton} headerText={heroTitle} />
        )}
        {serviceInfo && (
          <Section>
            <InfoBox>
              {serviceInfo.map((paragraph, i) => (
                <span key={i}>
                  {i !== 0 && <br />}
                  {i !== 0 && <br />}
                  {cms.translate(paragraph)}
                </span>
              ))}
            </InfoBox>
          </Section>
        )}
        {serviceReasonTitle && (
          <HorizontalContentSection title={serviceReasonTitle}>
            <div className="icons">
              {serviceReasons &&
                serviceReasons.map((reason, i) => {
                  const icon = cms.translate(reason.iconImage);
                  return <SingleIcon key={i} imgUrl={icon && icon.url} iconText={cms.translate(reason.iconText)} />;
                })}
            </div>
          </HorizontalContentSection>
        )}
        <div ref={this.formRef} />
        {serviceFormData && <ServiceFormSection serviceFormData={serviceFormData} />}
      </div>
    );
  }
}

export default withCMS(ServicePage);
