import '../scss/entry.scss';
import React, { Fragment } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import ErrorPage from './templates/ErrorPage';
import withCMS from './hoc/CMSData';
import CMSTemplates from '../cms-templates';
import { findPage } from './util/path';
import MobileNavHeader from './components/header/MobileNavHeader';
import MainCarHeader from './components/header/MainCarHeader';
import MainNavHeader from './components/header/MainNavHeader';
import OpeningTimesSection from './components/sections/OpeningTimesSection';
import CarComparisonSection from './components/sections/CarComparisonSection';
import PageFooter from './components/PageFooter';
import CarComparisonBox from 'js/components/CarComparisonBox';
import LazyLoad from 'react-lazyload';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: props.error || false,
      statusCode: props.statusCode,
      showComparisonBar: props.cms.page.template.includes('cars-for-sale')
    };
  }

  goHome = () => {
    this.props.history.push('/');
    setTimeout(() => this.setState({ hasError: false, statusCode: 200 }), 0);
  };

  updateComparison() {
    const { cms, location } = this.props;

    let urlPartial = null;

    cms.data.pages.forEach(page => {
      if (page.template === 'cars-for-sale') {
        urlPartial = page.slug[cms.locale];
      }
    });

    this.setState({ showComparisonBar: location.pathname.includes(urlPartial) });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { location, cms } = this.props;

    if (this.props.location !== prevProps.location) {
      const locale = cms.locale;
      const page = findPage(location.pathname, cms.data.pages, locale);

      this.updateComparison();

      if (page) {
        this.props.cms.setPage(page);
        this.setState({ hasError: false, statusCode: 200 });
        window.__CMS_ERROR__ = false; // HMR fix
        window.__CMS_STATUS_CODE__ = 200; // HMR fix
      }
    }
  };

  render() {
    return (
      <Fragment>
        <MainNavHeader />
        <MainCarHeader />
        {this.state.showComparisonBar ? <CarComparisonBox /> : null}
        <MobileNavHeader />
        {this.state.showComparisonBar ? <CarComparisonSection /> : null}
        <Route render={({ location }) => this.RoutesSwitch(location, this.props.cms)} />
        <LazyLoad offset={300}>{!this.state.hasError && <OpeningTimesSection />}</LazyLoad>
        <LazyLoad offset={300}>{!this.state.hasError && <PageFooter />}</LazyLoad>
      </Fragment>
    );
  }

  goHome = () => {
    this.props.history.push('/');
    setTimeout(() => {
      this.setState({ hasError: false });
      window.__CMS_ERROR__ = false; // HMR fix
      window.__CMS_STATUS_CODE__ = 200; // HMR fix
    }, 0);
  };

  RoutesSwitch = (location, cms) => {
    if (this.state.hasError) {
      return <ErrorPage goHome={this.goHome} statusCode={this.state.statusCode} />;
    }
    if (location.pathname === '/') {
      return (
        <Switch location={location}>
          <Redirect to={`/${cms.data.defaultLocale}/`} />
        </Switch>
      );
    }
    return (
      <Switch location={location}>
        {/* Default routes */}
        {cms.data.pages.map(page => {
          const PageTemplateComponent = CMSTemplates[page.template];
          return (
            <Route
              key={location.pathname}
              path={page.slug[cms.data.defaultLocale]}
              state={5}
              exact={true}
              render={props => <PageTemplateComponent {...{ ...props, pageName: page.name, page }} />}
            />
          );
        })}

        {/* Multilingual routes */}
        {cms.data.pages.map(page => {
          const PageTemplateComponent = CMSTemplates[page.template];
          return Object.keys(cms.data.locales).map(locale => {
            if (!page.slug[locale]) return null;
            return (
              <Route
                key={location.pathname}
                path={`/${locale}${page.slug[locale]}`}
                exact={true}
                render={props => <PageTemplateComponent {...{ ...props, pageName: page.name, page }} />}
              />
            );
          });
        })}
        <Route
          path="*"
          render={() => {
            this.setState({ hasError: true, statusCode: 404 });
            return <ErrorPage goHome={this.goHome} statusCode={404} />;
          }}
        />
      </Switch>
    );
  };

  componentDidCatch() {
    this.setState({ hasError: true, statusCode: 500 });
  }
}

export default withCMS(withRouter(App));
