import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import CarServiceForm from './CarServiceForm';
import API from 'js/util/api';
import { Formik } from 'formik';
import * as yup from 'yup';
import { TextDecoder } from 'text-encoding';
import Button from '../core/Button';

class ServiceFormSection extends Component {
  state = {
    successSubmitForm: false,
    formErrors: {}
  };

  scrollToForm() {
    setTimeout(() => {
      this.bookingFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 50);
  }

  setSuccessView() {
    this.setState({ successSubmitForm: true });
    setTimeout(() => {
      this.successRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 50);
  }

  successRef = React.createRef();

  bookingFormRef = React.createRef();

  renderSuccessSubmit() {
    const { cms } = this.props;

    return (
      <div ref={this.successRef}>
        <div className="service-form-section">
          <div className="service-form-wrapper">
            <h4>{cms.translate('form.submit-successful')}</h4>

            <div className="booking-button-wrapper">
              <Button onClick={() => this.setState({ successSubmitForm: false })} type="submit" theme="dark-gray">
                {cms.translate('form.fill-again')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { cms, serviceFormData } = this.props;

    if (this.state.successSubmitForm) {
      return this.renderSuccessSubmit();
    }

    return (
      <Formik
        onSubmit={(values, { setSubmitting, setErrors }) => {
          this.setState({ formValues: values });
          setSubmitting(true);
          setErrors({});
          this.setState({ formErrors: {} });
          API.post('api/forms/car-service', {
            formBody: values
          })
            .then(e => {
              setSubmitting(false);
              this.setSuccessView();
            })
            .catch(async e => {
              const data = await e.response.json();
              setSubmitting(false);
              this.setState({ formErrors: data.errors });
              this.scrollToForm();
            });
        }}
        initialValues={{
          ...yup
        }}
      >
        {props => {
          const handleChange = event => {
            props.setTouched({ ...props.touched, [event.target.id]: false });
            props.handleChange(event);
          };
          return (
            <CarServiceForm
              formik={{ ...props, errors: { ...props.errors, ...this.state.formErrors }, handleChange }}
              cms={cms}
              serviceFormData={serviceFormData}
              successRef={this.successRef}
              bookingFormRef={this.bookingFormRef}
            />
          );
        }}
      </Formik>
    );
  }

  schema = {
    carMake: yup.string().default(''),
    carModel: yup.string().default(''),
    licencePlate: yup.string().default(''),
    mileage: yup.string().default(''),
    selectService: yup.string().default(''),
    additionalInformation: yup.string().default(''),
    name: yup.string().default(''),
    phone: yup.string().default(''),
    email: yup.string().default(''),
    selectDate: yup.string().default(''),
    selectTime: yup.string().default(''),
    selectLocation: yup.string().default(''),
  };
}

export default withCMS(ServiceFormSection);
