import React, { PureComponent } from 'react';
import withCMS from '../hoc/CMSData';
import HeroSection from '../components/sections/HeroSection';
import Section from '../components/sections/Section';
import InfoBox from '../components/InfoBox';
import HorizontalContentSection from '../components/sections/HorizontalContentSection';
import SingleIcon from '../components/SingleIcon';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';

class WarrantyPage extends PureComponent {
  render() {
    const { cms } = this.props;
    const warrantyPage = cms.getPage('warranty');
    if (!warrantyPage) return null;

    const heroHeader = cms.translate(warrantyPage.meta.heroSection.title);
    const heroImage = cms.translate(warrantyPage.meta.heroSection.heroImage);

    const heroButton = warrantyPage.meta.heroSection.heroButton;

    const warrantyTexts = warrantyPage.meta.warrantySection.textbox;

    const warrantyReasonTitle = cms.translate(warrantyPage.meta.horizontalSection.title);
    const warrantyReasons = warrantyPage.meta.horizontalSection.reasons;

    return (
      <div className="page warranty-page">
        <ScrollToTopOnMount />

        {heroImage && <HeroSection imageUrl={heroImage.url} button={heroButton} headerText={heroHeader} />}

        {warrantyTexts && (
          <Section>
            <InfoBox>
              {warrantyTexts.map((introText, i) => {
                const text = cms.translate(introText);
                return (
                  text && (
                    <span key={i}>
                      {i !== 0 && <br />}
                      {i !== 0 && <br />}
                      {text}
                    </span>
                  )
                );
              })}
            </InfoBox>
          </Section>
        )}

        {warrantyReasonTitle && warrantyReasons && (
          <HorizontalContentSection title={warrantyReasonTitle}>
            <div className="icons">
              {warrantyReasons.map((reason, i) => {
                const icon = cms.translate(reason.iconImage);
                return <SingleIcon key={i} imgUrl={icon && icon.url} iconText={cms.translate(reason.iconText)} />;
              })}
            </div>
          </HorizontalContentSection>
        )}
      </div>
    );
  }
}

export default withCMS(WarrantyPage);
