import React from 'react';

export default function withSubscriptions(WrappedComponent, selectData) {

  return class CarComparison extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        timeoutSubscriptions: []
      };
    }

    clearAllSubscriptions = () => {
      this.state.timeoutSubscriptions.map(sub => clearTimeout(sub));
    }

    componentWillUnmount() {
      this.clearAllSubscriptions();
    }

    setTimeout = (cb, time) => {
      const sub = setTimeout(cb, time);
      this.setState({timeoutSubscriptions: [...this.state.timeoutSubscriptions, sub]})
    }

    render() {
      return <WrappedComponent
        setTimeout={this.setTimeout}
        clearAllSubscriptions={this.clearAllSubscriptions}
        {...this.props}
      />
    }
  }
}
