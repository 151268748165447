import { calcMonthlyPayment } from './lease';

export const getBodyType = (cms, car) => {
  return cms.translate(car.bodyType.value) || cms.translate(car.type) || '-';
};

export const getBodyTypeDescription = (cms, car) => {
  return `${getBodyType(cms, car)}, ${getDoors(cms, car)}, ${getSeats(cms, car)}`;
};

export const getDoors = (cms, car) => {
  return `${car.bodyType.doors} ${cms.translate('car.doors')}`;
};

export const getSeats = (cms, car) => {
  return `${car.bodyType.seats} ${cms.translate('car.seats')}`;
};

export const getRegistrationDate = (cms, car) => {
  const _splitRegDate = car.registrationDate.split('-');
  const year = _splitRegDate[0];
  const month = _splitRegDate[1];
  if (year === void 0 && month === void 0) return '-';
  if (year !== void 0 && month !== void 0) return `${month}/${year}`;
  return `${month || year}`;
};

export const getRegistrationYear = (cms, car) => {
  const _splitRegDate = car.registrationDate.split('-');
  const year = _splitRegDate[0];
  if (year === void 0) return '-';
  return year;
};

export const getRegistrationDateObject = car => {
  const datum = car.registrationDate.split('-');
  const regDate = new Date(datum[0], datum[1]);
  return regDate;
};

export const getFuelType = (cms, car) => {
  return cms.translate(car.fuel.type);
};

export const getFuelTypeShort = (cms, car) => {
  return getFuelType(cms, car)[0].toUpperCase();
};

export const getColor = (cms, car) => {
  return cms.translate(car.color.value) || '-';
};

export const getTransmission = (cms, car) => {
  const T = car.transmission;
  return `${cms.translate(T.type)}${T.description ? ` (${T.description})` : ''}`;
};

export const getTransmissionShort = (cms, car) => {
  return getTransmission(cms, car)[0].toUpperCase();
};

export const getMileage = (cms, car) => {
  return `${car.mileage} km`;
};

export const getTaxString = (cms, car) => {
  if (car.price.vat === '1') return cms.translate('car.VATincluded');
  if (car.price.vat === '2') return cms.translate('car.VATtoBeAdded');
  if (car.price.vat === '3') return cms.translate('car.VATzero');
};

export const getDefaultPrice = (cms, car) => {
  return Number(car.price.default ? car.price.default : '');
};

export const getCurrentPrice = (cms, car) => {
  return Number(car.price.bargain && car.price.bargain !== '0' ? car.price.bargain : car.price.default);
};

export const getDiscountPrice = (cms, car) => {
  return Number(car.price.bargain && car.price.bargain !== '0' ? car.price.bargain : null);
};

export const getTitle = (cms, car) => {
  return `${car.make} ${car.model.model} ${car.model.trim.replace('&amp;', '&')}`;
};

export const getEngine = (cms, car) => {
  return `${car.engine.capacity.l}L ${car.engine.type} ${car.engine.power.kW} kW`;
};

export const getDrive = (cms, car) => {
  return cms.translate(car.drive);
};

const orderBy = (a, b) => {
  a = a.value.toLowerCase();
  b = b.value.toLowerCase();

  return a > b ? 1 : b > a ? -1 : 0;
};

export const filterModelsByMake = (make, carsArray) => {
  const specificMakeModels = [];

  carsArray
    .filter(car => car.make === make && car.model !== undefined)
    .forEach(c => {
      if (!specificMakeModels.find(fc => fc.value === c.model.model)) {
        specificMakeModels.push({ value: c.model.model, label: c.model.model });
      }
    });
  return specificMakeModels.sort(orderBy);
};

export const filterYearsByMakeAndModel = (carMake, model, carArray) => {
  const carProductionYears = [];
  carArray
    .filter(car => car.make === carMake && car.model !== undefined && car.model.model === model)
    .forEach(c => {
      const carRegistrationYear = getRegistrationYear(null, c);
      if (!carProductionYears.find(fc => fc.value === carRegistrationYear)) {
        carProductionYears.push({ value: carRegistrationYear, label: carRegistrationYear });
      }
    });
  return carProductionYears.sort(orderBy);
};

export const filterCarsByMakeAndModel = (carMake, model, carArray) => {
  return carArray
    .filter(car => car.make === carMake && car.model.model === model)
    .sort((a, b) => {
      a = getCurrentPrice(void 0, a);
      b = getCurrentPrice(void 0, b);

      return a > b ? 1 : b > a ? -1 : 0;
    });
};

export const getMonthlyPayment = (cms, car) => {
  return Math.round(calcMonthlyPayment(getCurrentPrice(cms, car)));
};
