import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import withFilters from '../../hoc/FiltersData';
import MobileMenuModal from './MobileMenuModal';
import PageLink from '../core/PageLink';
import MobileCarSelectionModal from './MobileCarSelectionModal';

class MobileNavHeader extends Component {
  state = {
    showMenu: false,
    showCarSelection: false
  };

  render() {
    const { cms } = this.props;
    const { showMenu, showCarSelection } = this.state;

    const header = cms.getRegion('header');
    const landingPage = cms.getPage('landing');
    if (!header || !landingPage) return null;

    const logo = cms.translate(header.meta.logo.logo);

    return (
      <div className="mobile-nav-header">
        {logo && (
          <div className="_logo-area">
            <PageLink
              page={landingPage}
              className="_logo"
              style={{ backgroundImage: `url(${logo.url})` }}
              aria-label="Header logo"
            />
          </div>
        )}

        <div className="_buttons">
          <div
            className="_all-cars-button _btn"
            onClick={() => {
              this.props.filters.deleteFilters();
              this.setState({ showCarSelection: true });
            }}
          >
            <span>KÕIK AUTOD</span>
            <div className="_chevron" />
          </div>

          <div className="_menu-button _btn" onClick={() => this.setState({ showMenu: true })}>
            <span>MENÜÜ</span>
            <div className="_menu-icon" />
          </div>
        </div>

        <MobileMenuModal show={showMenu} close={() => this.setState({ showMenu: false })} />
        <MobileCarSelectionModal show={showCarSelection} close={() => this.setState({ showCarSelection: false })} />
      </div>
    );
  }
}

export default withFilters(withCMS(MobileNavHeader));
