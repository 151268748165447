import React, { Component } from 'react';

export default class InfoBox extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="info-box">
        <div className="rectangle" />
        <p className="text-box">{children}</p>
      </div>
    );
  }
}
