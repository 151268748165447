import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import { withRouter } from 'react-router-dom';
import Select from '../inputs/Select';
import { createEqualizedGroups } from 'js/util/volvo-models';
import withFilters, { SortingOptions, LocationOptions } from '../../hoc/FiltersData';
import { Transition, animated, config } from 'react-spring';
import LabeledSquareCheckbox from '../inputs/LabeledSquareCheckbox';

class CarsForSaleFilters extends Component {
  state = {
    showMobileFilterDropdown: false,
    locationFocused: false
  };

  render() {
    const { filters } = this.props;

    return (
      <div className="cars-for-sale-filters">
        <div className="_desktop-content-wrapper">
          {this.renderVolvoModelsArea()}
          {this.renderGenericCheckboxes()}

          <div className="_sorting-area">
            <Select
              value={filters.sortBy || '-'}
              options={SortingOptions}
              onChange={e => {
                this.props.history.push(filters.getUrlParams('orderBy', e.target.value));
                filters.setSortBy(e.target.value);
              }}
            />
            <Select
              options={LocationOptions}
              onChange={e => {
                filters.setSalonLocation(e.target.value);
              }}
              value={filters.salonLocation || '-'}
              onFocus={() => this.setState({ locationFocused: true })}
              onBlur={() => this.setState({ locationFocused: false })}
            />
          </div>
        </div>

        {this.renderMobileContent()}
      </div>
    );
  }

  renderMobileContent = () => {
    const { filters } = this.props;
    const { showMobileFilterDropdown: showDrpd, sortFocused, locationFocused } = this.state;

    return (
      <div className="_mobile-wrapper">
        <div className="_buttons">
          <button
            className={`_button ${showDrpd ? '--active' : ''}`}
            onClick={() => this.setState({ showMobileFilterDropdown: !showDrpd })}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="_icon" viewBox="0 0 24 24">
              <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            Filter
          </button>

          <button className={`_button ${sortFocused ? '--active' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" className="_icon" viewBox="0 0 24 24">
              <path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            Sorteeri
            <Select
              options={SortingOptions}
              onChange={e => {
                filters.setSortBy(e.target.value);
              }}
              value={filters.sortBy || '-'}
              onFocus={() => this.setState({ sortFocused: true })}
              onBlur={() => this.setState({ sortFocused: false })}
            />
          </button>

          <button className={`_button ${locationFocused ? '--active' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" className="_icon" viewBox="0 0 24 24">
              <path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            Asukoht
            <Select
              options={LocationOptions}
              onChange={e => {
                filters.setSalonLocation(e.target.value);
              }}
              value={filters.salonLocation || '-'}
              onFocus={() => this.setState({ locationFocused: true })}
              onBlur={() => this.setState({ locationFocused: false })}
            />
          </button>
        </div>

        <Transition
          native
          unique
          items={showDrpd}
          from={{ height: showDrpd ? 0 : 'auto', paddingTop: showDrpd ? 0 : 24, paddingBottom: showDrpd ? 0 : 24 }}
          enter={{ height: showDrpd ? 'auto' : 0, paddingTop: showDrpd ? 24 : 0, paddingBottom: showDrpd ? 24 : 0 }}
          leave={{ height: showDrpd ? 'auto' : 0, paddingTop: showDrpd ? 24 : 0, paddingBottom: showDrpd ? 24 : 0 }}
          config={{ ...config.default, friction: 32, tension: 330 }}
        >
          {show => (show ? style => this.renderMobileFilterDropdown(style) : null)}
        </Transition>
      </div>
    );
  };

  renderMobileFilterDropdown = style => (
    <animated.div className="_filter-dropdown" style={style}>
      <div className="_content-wrap">
        {this.renderVolvoModelsArea()}
        {this.renderGenericCheckboxes()}
      </div>
    </animated.div>
  );

  renderVolvoModelsArea = () => {
    const { cms, filters } = this.props;
    const volvoGroups = createEqualizedGroups(cms);

    return (
      <div className="_model-checkboxes-area">
        {Object.values(volvoGroups).map((group, i) => (
          <div className="_group" key={i}>
            {group.map(volvo =>
              volvo._empty ? (
                <div className="_empty" key={volvo.id} />
              ) : (
                <LabeledSquareCheckbox
                  className="_model-checkbox"
                  key={volvo.id}
                  onClick={() => {
                    this.props.history.push(filters.getUrlParams('models', volvo.name));
                    filters.toggleModel(volvo.name);
                  }}
                  checked={filters.selectedVolvoModels.includes(volvo.name)}
                >
                  {volvo.name}
                </LabeledSquareCheckbox>
              )
            )}
          </div>
        ))}
      </div>
    );
  };

  renderGenericCheckboxes = () => {
    const { filters, cms } = this.props;

    return (
      <div className="_generic-checkboxes-area">
        <LabeledSquareCheckbox
          className="_generic-checkbox"
          onClick={() => {
            this.props.history.push(filters.getUrlParams('filters', 'new'));
            filters.toggleOnlyNew();
          }}
          checked={filters.onlyNew}
        >
          {cms.translate('carFilters.onlyNew')}
        </LabeledSquareCheckbox>

        <LabeledSquareCheckbox
          className="_generic-checkbox"
          onClick={() => {
            this.props.history.push(filters.getUrlParams('filters', 'heico'));
            filters.toggleOnlyHeicoSportiv();
          }}
          checked={filters.onlyHeicoSportiv}
        >
          {cms.translate('carFilters.onlyHeicoSportiv')}
        </LabeledSquareCheckbox>

        <LabeledSquareCheckbox
          className="_generic-checkbox"
          onClick={() => {
            this.props.history.push(filters.getUrlParams('filters', 'others'));
            filters.toggleOtherMakes();
          }}
          checked={filters.otherMakes}
        >
          {cms.translate('carFilters.otherMakes')}
        </LabeledSquareCheckbox>
      </div>
    );
  };
}

export default withFilters(withCMS(withRouter(CarsForSaleFilters)));
