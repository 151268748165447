import React, { Component } from 'react';
import Dots from '../Dots';
import Button from '../core/Button';
import PageLink from '../core/PageLink';
import withCMS from '../../hoc/CMSData';

export class VerticalContentSection extends Component {
  render() {
    const { title, btnText, cms } = this.props;

    const orderPage = cms.getPage('pre-order');
    return (
      <div className="vertical-content-section">
        <Dots />
        <div className="max-width-wrapper">
          <h3>{title}</h3>
          <PageLink page={orderPage}>
            <Button>{btnText}</Button>
          </PageLink>
        </div>
      </div>
    );
  }
}
export default withCMS(VerticalContentSection);
