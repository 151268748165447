import React, { Component } from 'react';
import withCMS from '../hoc/CMSData';
import CarsForSaleListItem from 'js/components/cars-for-sale/CarsForSaleListItem';
import CarsForSaleFilters from 'js/components/cars-for-sale/CarsForSaleFilters';
import { config, Transition } from 'react-spring';
import withFilters, { PageSizeOptions } from '../hoc/FiltersData';
import VerticalContentSection from 'js/components/sections/VerticalContentSection';
import CarsForSalePaginator from 'js/components/cars-for-sale/CarsForSalePaginator';
import Select from 'js/components/inputs/Select';
import Loader from 'js/components/Loader';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';

class CarsForSalePage extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    const { filters, cms } = this.props;
    const cars = filters.filteredCars;
    return (
      <div className="page cars-for-sale-page">
        <ScrollToTopOnMount />
        <CarsForSaleFilters />

        {this.state.loading ? (
          <div className="view _loading">
            <ScrollToTopOnMount />
            <Loader size={60} />
          </div>
        ) : (
          <div className="_cars-list">
            {filters.maxPages >= 1 && (
              <Transition
                native
                unique
                items={cars}
                keys={car => car.id}
                initial={{ opacity: 0, transform: `scale(0.95)` }}
                from={{ opacity: 0, transform: `scale(0.95)`, height: 0, marginTop: 0, borderWidth: 0 }}
                enter={{ opacity: 1, transform: `scale(1)`, height: 'auto', marginTop: 4, borderWidth: 1 }}
                leave={{ opacity: 0, transform: `scale(0.95)`, height: 0, marginTop: 0, borderWidth: 0 }}
                config={{ ...config.default, precision: 0.01 }}
              >
                {car => style => <CarsForSaleListItem car={car} style={style} />}
              </Transition>
            )}

            {filters.maxPages === 0 && <div className="_empty-view">{cms.translate('carFilters.noCarsFound')}</div>}
          </div>
        )}

        <div className="_paginator-section">
          {filters.maxPages > 1 && <CarsForSalePaginator />}
          <Select
            onChange={e => {
              filters.setPerPage(e.target.value);
              //When decreasing number of cars per page scroll to top
              this.props.history.push(this.props.filters.getUrlParams('perPage', e.target.value));
              if (e.target.value < filters.perPage) window.scrollTo(0, 0);
            }}
            value={filters.perPage}
            options={PageSizeOptions}
          />
        </div>

        <VerticalContentSection title="Ei leidnud sobivat autot?" btnText="Telli auto meilt" />
      </div>
    );
  }
}

export default withFilters(withCMS(CarsForSalePage));
