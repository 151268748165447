import React, { Component } from 'react';

export default class Input extends Component {
  render() {
    const { flex } = this.props;

    return (
      <div className={`input-wrapper ${flex ? '--flex' : ''}`}>
        {this.props.label && <label>{this.props.label}</label>}
        {this.props.children}
      </div>
    );
  }
}
