import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withCMS from '../../hoc/CMSData';
import CarSelectionDropdown from './CarSelectionDropdown';
import PageLink from '../core/PageLink';
import withFilters from '../../hoc/FiltersData';

class MainCarHeader extends Component {
  state = {
    showCarSelection: false
  };

  _disallowClick = false;

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleOutsideClick, false);
  };

  toggleCarSelection = (e, forceClose = false) => {
    if (this._disallowClick) return;
    const shown = this.state.showCarSelection;
    if (shown) {
      if (!forceClose && this._carMenuEl && this._carMenuEl.contains(e.target)) return;
      this.setState({ showCarSelection: false });
      document.removeEventListener('click', this.handleOutsideClick, false);
      this._disallowClick = true;
      setTimeout(() => (this._disallowClick = false), 300);
    } else {
      this.setState({ showCarSelection: true });
      this.props.filters.deleteFilters();
      document.addEventListener('click', this.handleOutsideClick, false);
    }
  };

  handleOutsideClick = e => {
    if (!this.state.showCarSelection) return;
    this.toggleCarSelection(e);
  };

  renderSingleCompareCar = car => {
    if (typeof car.media[0] === 'undefined') {
      return null;
    }

    const img = car.media[0];
    const imgUrl = img.thumbnailUrl;

    return (
      <div key={car.id} className="single-compare-car-overview">
        <img src={imgUrl} alt="" />
      </div>
    );
  };

  render() {
    const { cms, filters } = this.props;
    const { showCarSelection } = this.state;

    const volvoModels = cms.data.volvoModels || [];
    const carsForSalePage = cms.getPage('cars-for-sale');

    return (
      <div className="main-car-header">
        {/* Dropdown button */}
        <div onClick={this.toggleCarSelection} className={`_dropdown ${showCarSelection ? '--open' : ''}`}>
          {cms.translate('header.allCars')}
          <svg viewBox="0 0 10 15" xmlns="http://www.w3.org/2000/svg" className="_chevron">
            <path
              d="M10 11.2727273L2.72727273 18.5454545 0 15.8181818 4.54545455 11.2727273 4.11992261e-13 6.72727273 2.72727273 4z"
              transform="translate(0 -4)"
              fill="#29A8E0"
              fillRule="evenodd"
            />
          </svg>
          {/* Dropdown */}
          <CarSelectionDropdown
            show={showCarSelection}
            contentRef={el => (this._carMenuEl = el)}
            close={e => this.toggleCarSelection(e, true)}
          />
        </div>

        {/* Links */}
        {volvoModels.map(volvo => (
          <PageLink
            key={volvo.id}
            page={carsForSalePage}
            className="_link"
            urlParams={filters.getUrlParams('solo-model', volvo.name)}
            onClick={() => filters.onlySelectModel(volvo.name)}
          >
            {volvo.name}
          </PageLink>
        ))}
      </div>
    );
  }
}

export default withFilters(withCMS(withRouter(MainCarHeader)));
