import React, { Component } from 'react';
import withCMS from '../hoc/CMSData';
import { withRouter } from 'react-router-dom';
import HeroSection from '../components/sections/HeroSection';
import Section from '../components/sections/Section';
import InfoBox from '../components/InfoBox';
import HorizontalContentSection from '../components/sections/HorizontalContentSection';
import CarChangeFormSection from '../components/sections/CarChangeFormSection';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';
class CarExchangePage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef(); // Create a ref object
  }

  render() {
    const { cms } = this.props;
    const exchangePage = cms.getPage('car-exchange');
    if (!exchangePage) return null;

    const heroHeader = cms.translate(exchangePage.meta.heroSection.title);
    let heroButton = exchangePage.meta.heroSection.heroButton;
    heroButton.action = () => {
      this.formRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    };
    const heroImage = cms.translate(exchangePage.meta.heroSection.heroImage);

    const exchangeInfoTexts = exchangePage.meta.introSection.textbox;

    const exchangeConditionTitle = cms.translate(exchangePage.meta.horizontalSection.title);
    const exchangeConditions = exchangePage.meta.horizontalSection.exchangeConditions;

    const exchangeForm = exchangePage.meta.exchangeForm;

    return (
      <div className="page car-change">
        <ScrollToTopOnMount />

        {heroHeader && heroButton && heroImage && (
          <HeroSection imageUrl={heroImage.url} headerText={heroHeader} button={heroButton} />
        )}
        <Section>
          <InfoBox>
            {exchangeInfoTexts.map((introText, i) => (
              <span key={i}>
                {i !== 0 && <br />}
                {i !== 0 && <br />}
                {cms.translate(introText)}
              </span>
            ))}
          </InfoBox>
        </Section>
        {exchangeConditions && (
          <HorizontalContentSection title={exchangeConditionTitle}>
            <ul>
              {exchangeConditions.map((condition, i) => (
                <li key={i}>{cms.translate(condition)}</li>
              ))}
            </ul>
          </HorizontalContentSection>
        )}
        <CarChangeFormSection data={exchangeForm} refprop={this.formRef} />
      </div>
    );
  }
}

export default withCMS(withRouter(CarExchangePage));
