import React, { Component } from 'react';
import HorizontalContentSection from '../sections/HorizontalContentSection';
import Button from '../core/Button';
import CarsForSaleSingleTopDetails from './CarsForSaleSingleTopDetails';
import withCMS from '../../hoc/CMSData';
import CarsForSaleSingleEquipmentLists from './CarsForSaleSingleEquipmentLists';
import PageLink from '../core/PageLink';

class CarsForSaleSingleDetailsBox extends Component {
  render() {
    const { cms, car, openLeaseModal } = this.props;

    return (
      <div className="cars-for-sale-single-details-box">
        <CarsForSaleSingleTopDetails openLeaseModal={openLeaseModal} car={car} />
        <CarsForSaleSingleEquipmentLists car={car} />
        <HorizontalContentSection centered>
          <PageLink page={cms.getPage('contact')}>
            <Button>Tule proovisõidule</Button>
          </PageLink>
        </HorizontalContentSection>
      </div>
    );
  }
}

export default withCMS(CarsForSaleSingleDetailsBox);
