import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import loadImage from 'blueimp-load-image';
export default class ImageInput extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  state = { src: void 0, loading: false, orientation: 1, isError: false, fileName: '' };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const event = { currentTarget: { files: acceptedFiles } };
    this.handeFileChange(event);
  };

  handeFileChange = event => {
    this.setState({ loading: true, isError: false, fileName: '' });

    const fileName = event.currentTarget.files[0].name;
    const image = loadImage(event.currentTarget.files[0], image => {
      if (image.type === 'error' && fileName.endsWith('.pdf')) {
        this.setState({ isError: true, fileName });
      }
      return image;
    });
    loadImage.parseMetaData(event.currentTarget.files[0], data => {
      if (!data.exif) {
        return;
      }
      this.setState({ orientation: data.exif.get('Orientation') });
    });
    this.setState({
      src: image.src,
      loading: false
    });

    this.props.onChange(event);
  };

  componentDidMount() {
    if (this.state.src) {
      return;
    }
    if (this.props.value) {
      const image = loadImage(this.props.value, function(img) {
        return img;
      });
      loadImage.parseMetaData(this.props.value, data => {
        if (!data.exif) {
          return;
        }
        this.setState({ orientation: data.exif.get('Orientation') });
      });
      this.setState({
        src: image.src,
        loading: false
      });
    }
  }

  render() {
    const { id, setFieldValue, value, errors } = this.props;

    return (
      <Dropzone maxSize={5000000} accept="*" onDrop={this.onDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => {
          const inputProps = { ...getInputProps() };
          return (
            <div
              {...getRootProps()}
              onClick={() => {
                if (this.input.current) this.input.current.click();
              }}
              className="image-input"
            >
              {value ? this.DisplayPicture() : this.AddPicture(inputProps)}
              {value && (
                <div
                  onClick={event => {
                    event.stopPropagation();
                    setFieldValue(id, void 0);
                  }}
                  className="remove-button"
                />
              )}

              {errors && !value && <span className={'input-errors'}>{errors}</span>}
            </div>
          );
        }}
      </Dropzone>
    );
  }

  AddPicture = inputProps => {
    const { label, type = 'file', onChange, setFieldValue, ...props } = this.props;
    return (
      <div className="content-wrapper">
        <span>{label}</span>
        <svg className="add-icon" width="22" height="23" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.368 22.008v-9.36H.632V9.672h8.736V.312h3.12v9.36h8.736v2.976h-8.736v9.36z"
            fill="#0D0D10"
            fillRule="evenodd"
          />
        </svg>
        <input {...inputProps} type={type} ref={this.input} onChange={this.handeFileChange} {...props} />
      </div>
    );
  };

  DisplayPicture = () => {
    const { value, label } = this.props;
    const { src, loading, isError, fileName } = this.state;

    return (
      <div className="image-upload-display">
        <img
          className={`orientation-${this.state.orientation} ${isError ? 'error' : ''}`}
          src={isError ? require('img/icons/pdf.svg') : src}
          alt={loading ? 'Loading..' : value.name}
        />
        {!isError && <div className="under-label">{label}</div>}
        {fileName && <div className="file-name">{fileName}</div>}
      </div>
    );
  };
}
