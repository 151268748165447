import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import PageLink from '../core/PageLink';
import { Transition, animated, config } from 'react-spring';
import { Portal } from 'react-portal';

class ServicesDropdown extends Component {
  renderDropdown = style => {
    const { contentRef, cms, close } = this.props;
    const header = cms.data.regions.find(r => r.template === 'header').meta;
    const dropdownData = header.servicesDropdown;

    return (
      <animated.div className="services-dropdown" style={style} ref={contentRef}>
        <div className="_left-titles">
          <h3 className="_title">{cms.translate(dropdownData.title)}</h3>
          <h4 className="_subtitle">{cms.translate(dropdownData.subtitle)}</h4>
        </div>

        <div className="_right-links">
          {dropdownData.links.map((link, i) => {
            const title = cms.translate(link.title);
            return (
              title && (
                <div className="_link-wrapper" key={i}>
                  <PageLink onClick={close} link={link.link}>
                    {title}
                  </PageLink>
                </div>
              )
            );
          })}
        </div>
      </animated.div>
    );
  };

  render() {
    const { show } = this.props;

    return (
      <Portal>
        <Transition
          native
          from={{ opacity: show ? 0 : 1, transform: `translateY(${show ? -175 : 0}px)` }}
          enter={{ opacity: show ? 1 : 0, transform: `translateY(${show ? 0 : -175}px)` }}
          leave={{ opacity: show ? 1 : 0, transform: `translateY(${show ? 0 : -175}px)` }}
          items={show}
          config={{ ...config.default, friction: 32, tension: 330 }}
        >
          {show => (show ? style => this.renderDropdown(style) : null)}
        </Transition>
      </Portal>
    );
  }
}

export default withCMS(ServicesDropdown);
