import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import TextInput from '../inputs/TextInput';
import TextArea from '../inputs/TextArea';
import Button from '../core/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Transition, animated, config } from 'react-spring';
import API from '../../util/api';

const States = {
  Form: 'form',
  Success: 'success',
  Error: 'error'
};

class ContactUsSection extends Component {
  views = () => ({ [States.Form]: this.FormView, [States.Success]: this.SuccessView, [States.Error]: this.ErrorView });

  state = { state: States.Form, formValues: void 0 };
  render() {
    const { data, cms, ...props } = this.props;
    const title = cms.translate(data.title);
    const subtitle = cms.translate(data.subtitle);

    const { state } = this.state;

    return (
      <div ref={this.props.refprop} className="contact-us-section" {...props}>
        <div className="section-content-wrapper">
          {title && state === States.Form && <h4>{title}</h4>}
          {subtitle && state === States.Form && <h2>{subtitle}</h2>}
          <div className="_content">
            <Transition
              native
              items={state}
              config={config.default}
              from={{ opacity: 0, transform: 'scale3d(0.9, 0.9, 0.9)' }}
              enter={{ opacity: 1, transform: 'scale3d(1, 1, 1)', display: 'block' }}
              leave={{ opacity: 0, transform: 'scale3d(0.9, 0.9, 0.9)', display: 'none' }}
            >
              {state => this.views()[state]}
            </Transition>
          </div>
        </div>
      </div>
    );
  }

  FormView = style => {
    const { cms, data, refprop } = this.props;
    const button = data.contactButton;

    return (
      <animated.div style={style}>
        <Formik
          validationSchema={this.schema}
          validateOnBlur
          onSubmit={(values, { setSubmitting, resetForm }) => {
            this.setState({ formValues: values });
            API.post('api/forms/contact', { jsonBody: values })
              .then(() => {
                setSubmitting(false);
                this.setState({ state: States.Success, formValues: void 0 });
                setTimeout(() => this.scrollToForm(refprop));
              })
              .catch(e => {
                setSubmitting(false);
                console.error('Failed to send email', e);
                setTimeout(() => this.setState({ state: States.Error }), 1000);
                setTimeout(() => this.setState({ state: States.Form }), 5000);
                setTimeout(() => this.scrollToForm(refprop));
              });
          }}
          initialValues={this.state.formValues || this.schema.cast()}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="inputs">
                <TextInput
                  label={cms.translate('form.name')}
                  value={values.name}
                  id="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.name}
                  touched={touched.name}
                />
                <TextInput
                  type="tel"
                  label={cms.translate('form.phone')}
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="phone"
                  errors={errors.phone}
                  touched={touched.phone}
                />
                <TextInput
                  type="email"
                  label={cms.translate('form.email')}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="email"
                  errors={errors.email}
                  touched={touched.email}
                />
                <TextArea
                  label={cms.translate('form.message')}
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="message"
                  errors={errors.message}
                  touched={touched.message}
                  flex
                />
              </div>
              {button && (
                <Button type="submit" disabled={isSubmitting} theme="dark-gray">
                  {cms.translate(button.text)}
                </Button>
              )}
            </form>
          )}
        </Formik>
      </animated.div>
    );
  };

  ErrorView = style => {
    const { cms } = this.props;
    return (
      <animated.div className="_success-view" style={style}>
        <span>{cms.translate('error.sendEmail')}</span>
        <div className="form-retry-button">
          <Button theme="dark-gray" onClick={() => this.setState({ state: States.Form })}>
            {cms.translate('error.tryAgain')}
          </Button>
        </div>
      </animated.div>
    );
  };

  SuccessView = style => {
    const { cms } = this.props;
    return (
      <animated.div className="_success-view" style={style}>
        <span>{cms.translate('success.sendEmail')}</span>
        <div className="form-retry-button">
          <Button theme="dark-gray" onClick={() => this.setState({ state: States.Form })}>
            {cms.translate('form.fill-again')}
          </Button>
        </div>
      </animated.div>
    );
  };

  schema = yup.object().shape({
    name: yup
      .string()
      .required(this.props.cms.translate('validation.name.required'))
      .min(3, this.props.cms.translate('validation.name.length'))
      .default(''),
    phone: yup
      .string()
      .trim()
      .default('')
      .matches(
        /^([+][0-9]{1,3}([ .-])?)?([(]{1}[0-9]{3}[)])?([0-9A-Z .-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/,
        this.props.cms.translate('validation.phone')
      ),
    email: yup
      .string()
      .email(this.props.cms.translate('validation.emailFormat'))
      .default('')
      .required(this.props.cms.translate('validation.email')),
    message: yup
      .string()
      .default('')
      .max(4000)
      .required(this.props.cms.translate('validation.message'))
  });

  scrollToForm = refprop => {
    refprop.current.scrollIntoView({
      behavior: 'smooth'
    });
  };
}

export default withCMS(ContactUsSection);
