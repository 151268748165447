import React, { Component } from 'react';
import withFilters from '../../hoc/FiltersData';
import { withRouter } from 'react-router-dom';

class CarsForSalePaginator extends Component {
  render() {
    const { filters } = this.props;

    let pages = new Array(filters.maxPages).fill().map((_, i) => i + 1);
    let paginationStart = Math.max(Math.min(filters.maxPages - 6, filters.currentPage - 3), 0);
    let paginationEnd = Math.max(6, filters.currentPage + 3);
    pages = pages.slice(paginationStart, paginationEnd);

    return (
      <div className="cars-for-sale-paginator">
        <div
          className={`_arrow --left ${filters.currentPage === 1 ? '--disabled' : void 0}`}
          onClick={this.goToPrevPage}
        />
        {filters.currentPage > 3 && (
          <a
            href={this.props.filters.getUrlParams('page', 1)}
            className={`_page`}
            onClick={event => {
              event.preventDefault();
              this.setPage(1);
            }}
          >
            ...
          </a>
        )}

        {pages.map(p => (
          <a
            href={this.props.filters.getUrlParams('page', p)}
            key={p}
            className={`_page ${p === filters.currentPage ? '--active' : ''}`}
            onClick={event => {
              event.preventDefault();
              this.setPage(p);
            }}
          >
            {p}
          </a>
        ))}
        {filters.maxPages - filters.currentPage > 3 && (
          <a
            href={this.props.filters.getUrlParams('page', filters.maxPages)}
            className={`_page`}
            onClick={event => {
              event.preventDefault();
              this.setPage(filters.maxPages);
            }}
          >
            ...
          </a>
        )}
        <div
          className={`_arrow --right ${filters.currentPage === filters.maxPages ? '--disabled' : void 0}`}
          onClick={this.goToNextPage}
        />
      </div>
    );
  }

  goToNextPage = () => {
    this.props.filters.goToNextPage();
    window.scrollTo(0, 0);
  };

  goToPrevPage = () => {
    this.props.filters.goToPrevPage();
    window.scrollTo(0, 0);
  };

  setPage = page => {
    this.props.filters.setPage(page);
    this.props.history.push(this.props.filters.getUrlParams('page', page));
    window.scrollTo(0, 0);
  };
}

export default withRouter(withFilters(CarsForSalePaginator));
