import React, { Component } from 'react';
import withCMS from 'js/hoc/CMSData';
import TextInput from '../inputs/TextInput';
import TextArea from '../inputs/TextArea';
import Select from '../inputs/Select';
import ImageInput from '../inputs/ImageInput';
import Button from '../core/Button';
import * as CarUtils from 'js/util/cars';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Transition, animated, config } from 'react-spring';
import API from 'js/util/api';

const States = {
  Form: 'form',
  Success: 'success',
  Error: 'error'
};

class CarChangeFormSection extends Component {
  state = { state: States.Form, formValues: void 0 };

  render() {
    const { cms, data, ...props } = this.props;
    const { state } = this.state;

    if (!data) return null;

    const formTitle = cms.translate(data.title);
    const subtitle = cms.translate(data.subtitle);

    return (
      <div className="car-change-form-section" ref={this.props.refprop} {...props}>
        <div className="section-content-wrapper">
          {formTitle && state === States.Form && <h2>{formTitle}</h2>}
          {subtitle && state === States.Form && <div className="subheading">{subtitle}</div>}

          <Transition
            native
            items={state}
            config={config.default}
            from={{ opacity: 0, transform: 'scale3d(0.9, 0.9, 0.9)' }}
            enter={{ opacity: 1, transform: 'scale3d(1, 1, 1)', display: 'block' }}
            leave={{ opacity: 0, transform: 'scale3d(0.9, 0.9, 0.9)', display: 'none' }}
          >
            {state => {
              if (state === States.Form) return this.FormView;

              if (state === States.Success) return this.SuccessView;

              if (state === States.Error) return this.ErrorView;
            }}
          </Transition>
        </div>
      </div>
    );
  }

  FormView = style => {
    const { cms, data, refprop } = this.props;
    const buttonTitle = cms.translate(data.buttonTitle);

    return (
      <animated.div style={style}>
        <Formik
          validationSchema={this.CarExchangeSchema}
          onSubmit={values => {
            //Set default values for the form reload
            this.setState({ formValues: values });

            API.post('api/forms/car-exchange', { formBody: values })
              .then(() => {
                this.setState({ state: States.Success });
                setTimeout(() => this.scrollToForm(refprop));
              })
              .catch(e => {
                console.error('Failed to send email', e);
                setTimeout(() => this.setState({ state: States.Error }), 500);
                setTimeout(() => this.setState({ state: States.Form }), 5000);
                setTimeout(() => this.scrollToForm(refprop));
              });
          }}
          mapPropsToValues
          initialValues={this.state.formValues || this.CarExchangeSchema.cast()}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <Form>
              {this.OldCarForm(values, touched, errors, handleChange, handleBlur)}
              {this.PictureForm(values, touched, errors, handleChange, handleBlur, setFieldValue)}
              {this.NewCarForm(values, touched, errors, handleChange, handleBlur, setFieldValue)}

              <div className="car-change-btn">
                {buttonTitle && (
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    showLoading={isSubmitting}
                    theme="dark-gray"
                  >
                    {buttonTitle}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </animated.div>
    );
  };

  ErrorView = style => {
    const { cms } = this.props;
    return (
      <animated.div className="_success-view" style={style}>
        <span>{cms.translate('error.sendEmail')}</span>
        <div className="form-retry-button">
          <Button theme="dark-gray" onClick={() => this.setState({ state: States.Form })}>
            {cms.translate('error.tryAgain')}
          </Button>
        </div>
      </animated.div>
    );
  };

  SuccessView = style => {
    const { cms } = this.props;
    return (
      <animated.div className="_success-view" style={style}>
        <span>{cms.translate('success.sendCarExchange')}</span>
        <div className="form-retry-button">
          <Button theme="dark-gray" onClick={() => this.setState({ state: States.Form })}>
            {cms.translate('form.fill-again')}
          </Button>
        </div>
      </animated.div>
    );
  };

  OldCarForm = (values, touched, errors, handleChange, handleBlur) => {
    const { cms, data } = this.props;

    const exchangeOldCarFormTitle = cms.translate(data.exchangeOldCar.title);
    const transmissionOptions =
      data.exchangeOldCar.gearboxTypes && data.exchangeOldCar.gearboxTypes.length > 0
        ? data.exchangeOldCar.gearboxTypes.map(gearbox => {
            return { label: cms.translate(gearbox), value: cms.translate(gearbox) };
          })
        : [];
    transmissionOptions.splice(0, 0, { label: cms.translate('form.selectDefault'), value: '-' });

    return (
      <div className="old-car-subform">
        {exchangeOldCarFormTitle && <h3>{exchangeOldCarFormTitle}</h3>}
        <div className="form-wrapper">
          <div className="left">
            <TextInput
              label={cms.translate('form.make')}
              value={values.make}
              id="make"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.make}
              touched={touched.make}
            />
            <TextInput
              label={cms.translate('form.model')}
              value={values.model}
              id="model"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.model}
              touched={touched.model}
            />
            <Select
              id="gearbox"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.gearbox}
              touched={touched.gearbox}
              value={values.gearbox}
              label={cms.translate('form.gearbox')}
              options={transmissionOptions}
            />
            <TextInput
              label={cms.translate('form.engine')}
              value={values.engine}
              id="engine"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.engine}
              touched={touched.engine}
            />
            <TextInput
              label={cms.translate('form.initialRegistration')}
              value={values.initialRegistration}
              id="initialRegistration"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.initialRegistration}
              touched={touched.initialRegistration}
            />
            <TextInput
              label={cms.translate('form.mileage')}
              value={values.mileage}
              id="mileage"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.mileage}
              touched={touched.mileage}
            />
          </div>
          <div className="right">
            <TextInput
              label={cms.translate('form.color')}
              value={values.color}
              id="color"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.color}
              touched={touched.color}
            />
            <TextInput
              label={cms.translate('form.equipment')}
              value={values.equipment}
              id="equipment"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.equipment}
              touched={touched.equipment}
            />
            <TextInput
              label={cms.translate('form.askingPrice')}
              value={values.askingPrice}
              id="askingPrice"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.askingPrice}
              touched={touched.askingPrice}
            />
            <TextArea
              label={cms.translate('form.serviceHistory')}
              value={values.serviceHistory}
              id="serviceHistory"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.serviceHistory}
              touched={touched.serviceHistory}
              flex
            />
          </div>
        </div>
      </div>
    );
  };

  //Picture form
  PictureForm = (values, touched, errors, handleChange, handleBlur, setFieldValue) => {
    const { cms, data } = this.props;

    const pictureFormTitle = cms.translate(data.imageFormTitle);

    return (
      <div className="add-pictures-subform">
        {pictureFormTitle && <h3>{pictureFormTitle}</h3>}
        <div className="max-filesize-notice">Maksimaalne piltide kogusuurus 25MB</div>
        <div className="image-inputs-wrapper">
          <ImageInput
            id="externalPicture1"
            label={cms.translate('carExchangeView.externalPicture1')}
            type="file"
            onChange={event => {
              setFieldValue('externalPicture1', event.currentTarget.files[0]);
            }}
            setFieldValue={setFieldValue}
            value={values.externalPicture1}
          />
          <ImageInput
            id="externalPicture2"
            label={cms.translate('carExchangeView.externalPicture2')}
            type="file"
            onChange={event => {
              setFieldValue('externalPicture2', event.currentTarget.files[0]);
            }}
            setFieldValue={setFieldValue}
            value={values.externalPicture2}
          />
          <ImageInput
            id="externalPicture3"
            label={cms.translate('carExchangeView.externalPicture3')}
            type="file"
            onChange={event => {
              setFieldValue('externalPicture3', event.currentTarget.files[0]);
            }}
            setFieldValue={setFieldValue}
            value={values.externalPicture3}
          />
          <ImageInput
            id="internalPicture1"
            label={cms.translate('carExchangeView.internalPicture1')}
            type="file"
            onChange={event => {
              setFieldValue('internalPicture1', event.currentTarget.files[0]);
            }}
            setFieldValue={setFieldValue}
            value={values.internalPicture1}
          />
          <ImageInput
            id="internalPicture2"
            label={cms.translate('carExchangeView.internalPicture2')}
            type="file"
            onChange={event => {
              setFieldValue('internalPicture2', event.currentTarget.files[0]);
            }}
            setFieldValue={setFieldValue}
            value={values.internalPicture2}
          />
        </div>
      </div>
    );
  };

  //New car form
  NewCarForm = (values, touched, errors, handleChange, handleBlur, setFieldValue) => {
    const { cms, data } = this.props;

    const newCarFormTitle = cms.translate(data.newCarTitle);

    const customerInfoFormTitle = cms.translate(data.customerFormTitle);

    const carMakes = [{ value: '-', label: 'Vali mark', disabled: true }];
    cms.data.cars.forEach(car => {
      if (!carMakes.find(c => c.value === car.make)) {
        carMakes.push({ label: car.make, value: car.make });
      }
    });

    const make = values.newCarMake;
    const carModels = CarUtils.filterModelsByMake(make, this.props.cms.data.cars);

    const pricedCars = CarUtils.filterCarsByMakeAndModel(make, values.newCarModel, this.props.cms.data.cars).map(
      car => {
        return {
          label: `${CarUtils.getTitle(this.props.cms, car)} ${CarUtils.getCurrentPrice(
            this.props.cms,
            car
          ).toLocaleString()} €`,
          value: car.id
        };
      }
    );

    return (
      <div className="new-car-subform">
        <div className="form-wrapper">
          <div className="left">
            {newCarFormTitle && <h3>{newCarFormTitle} </h3>}
            <Select
              id="newCarMake"
              label={cms.translate('form.make')}
              onBlur={handleBlur}
              onChange={event => {
                setFieldValue('newCarModel', void 0, false);
                handleChange(event);
              }}
              errors={errors.newCarMake}
              touched={touched.newCarMake}
              options={carMakes}
              value={values.newCarMake}
            />
            <Select
              id="newCarModel"
              label={cms.translate('form.model')}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.newCarModel}
              touched={touched.newCarModel}
              defaultOption={{ value: '-', label: cms.translate('form.selectDefault') }}
              options={carModels}
              value={values.newCarModel || '-'}
            />
            <Select
              id="newCar"
              label={cms.translate('carExchangeView.selectCar')}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.newCar}
              touched={touched.newCar}
              defaultOption={{ value: '-', label: cms.translate('form.selectDefault') }}
              options={pricedCars}
              value={values.newCar || '-'}
            />
          </div>
          <div className="right">
            {customerInfoFormTitle && <h3>{customerInfoFormTitle}</h3>}
            <TextInput
              id="name"
              label={cms.translate('form.name')}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.name}
              touched={touched.name}
            />
            <TextInput
              id="phone"
              type="tel"
              label={cms.translate('form.phone')}
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.phone}
              touched={touched.phone}
            />
            <TextInput
              id="email"
              type="email"
              label={cms.translate('form.email')}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.email}
              touched={touched.email}
            />
          </div>
        </div>
      </div>
    );
  };

  CarExchangeSchema = yup.object().shape({
    make: yup
      .string()
      .required(this.props.cms.translate('validation.make'))
      .default(''),
    model: yup
      .string()
      .required(this.props.cms.translate('validation.model'))
      .default(''),
    gearbox: yup.string().default('-'),
    engine: yup.string().default(''),
    initialRegistration: yup.string().default(''),
    mileage: yup.string().default(''),
    color: yup.string().default(''),
    equipment: yup.string().default(''),
    askingPrice: yup.string().default(''),
    serviceHistory: yup.string().default(''),
    newCarMake: yup
      .string()
      .default('-')
      .required(),
    newCarModel: yup.string(),
    newCar: yup.number().required(this.props.cms.translate('validation.car')),
    name: yup.string().default(''),
    phone: yup.string().default(''),
    email: yup
      .string()
      .required(this.props.cms.translate('validation.email'))
      .email(this.props.cms.translate('validation.emailFormat'))
      .default('')
  });
  scrollToForm = refprop => {
    refprop.current.scrollIntoView({
      behavior: 'smooth'
    });
  };
}

export default withCMS(CarChangeFormSection);
