import 'whatwg-fetch';
import 'babel-polyfill';
import 'intersection-observer';

import App from './js/App';
import React from 'react';
import { hydrate } from 'react-dom';
import { CMSDataProvider } from './js/hoc/CMSData';
import { BrowserRouter } from 'react-router-dom';
import { FiltersDataProvider } from 'js/hoc/FiltersData';
import { LeasingDataProvider } from 'js/hoc/LeasingData';

hydrate(
  <BrowserRouter>
    <CMSDataProvider cmsData={window.__CMS_DATA__} page={window.__PAGE__}>
      <FiltersDataProvider>
        <LeasingDataProvider>
          <App error={window.__CMS_ERROR__} statusCode={window.__CMS_STATUS_CODE__} />
        </LeasingDataProvider>
      </FiltersDataProvider>
    </CMSDataProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
