import React, { PureComponent } from 'react';
import Dots from '../components/Dots';
import Button from '../components/core/Button';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';

export default class ErrorPage extends PureComponent {
  render() {
    const { statusCode } = this.props;

    const heroHeader = statusCode === 404 ? 'LEHEKÜLGE EI LEITUD' : 'LEHEKÜLJE LAADIMISEL TEKKIS VIGA';

    const buttonText = 'AVALEHT';

    return (
      <div className="page error-page">
        <ScrollToTopOnMount />

        <div className="hero-section">
          <div
            className="hero-image"
            style={{ backgroundImage: `url(${require('../../img/banners/error-page-banner.jpg')})` }}
          />
          <div className="hero-content-wrapper">
            <Dots />
            <div className="hero-content">
              <h1>{heroHeader}</h1>
              <Button onClick={this.props.goHome}>{buttonText}</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
