import React, { Component } from 'react';
import SingleImageSection from '../components/sections/SingleImageSection';
import FourBoxSection from '../components/sections/FourBoxSection';
import TwoCarColumnSection from '../components/sections/TwoCarColumnSection';
import withCMS from '../hoc/CMSData';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';
import LazyLoad from 'react-lazyload';

class LandingPage extends Component {
  render() {
    const { cms } = this.props;

    const landingPage = cms.getPage('landing');
    if (!landingPage) return null;

    const heroImage = cms.translate(landingPage.meta.heroSection.heroImage);
    const heroImageMobile = cms.translate(landingPage.meta.heroSection.heroImageMobile);
    const heroHeader = cms.translate(landingPage.meta.heroSection.title);
    const heroSmallHeader = cms.translate(landingPage.meta.heroSection.smallHeader);

    const fourBoxSectionLinks = landingPage.meta.linksSection.linksArea;
    const fourBoxSectionData = fourBoxSectionLinks.map(link => ({
      title: cms.translate(link.title),
      imageUrl: cms.translate(link.backgroundImage) && cms.translate(link.backgroundImage).url,
      description: cms.translate(link.description),
      links: link.links.map(l => ({
        link: l.link,
        title: cms.translate(l.title)
      }))
    }));

    const twoCarColumnSection = landingPage.meta.twoColumnSection;

    return (
      <div className="page landing-page">
        <ScrollToTopOnMount />
        {heroImage && (
          <SingleImageSection
            imageUrl={heroImage.url}
            mobileImageUrl={heroImageMobile.url}
            headerText={heroHeader}
            smallHeader={heroSmallHeader}
          />
        )}
        <FourBoxSection data={fourBoxSectionData} />
        <LazyLoad offset={200}>
          <TwoCarColumnSection data={twoCarColumnSection} />
        </LazyLoad>
      </div>
    );
  }
}

export default withCMS(LandingPage);
