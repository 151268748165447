import React, { Component } from 'react';
import imgName from '../../../../src/img/icons/white-checkbox.svg';

export default class SquareCheckbox extends Component {
  render() {
    const { iconUrl, checked = false, onChange = () => true, ...others } = this.props;
    return (
      <div className="round-checkbox-container">
        <input type="checkbox" className="hidden-checkbox" onChange={onChange} value={checked} {...others} />
        <div
          className={`styled-checkbox ${checked ? '--checked' : ''}`}
          style={checked ? { background: `#29a8e0 url(${imgName}) no-repeat center / contain`, border: 'none' } : {}}
        >
          <div className="checkbox-icon">{iconUrl && <img src={iconUrl} />}</div>
        </div>
      </div>
    );
  }
}
