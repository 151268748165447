import React, { Component } from 'react';
import Dots from './Dots';
import PageLink from './core/PageLink';
import Button from './core/Button';

const DEFAULT_CONTENT_HEIGHT_PX = 86;
const PADDING_PX = 32;
const CONTENT_TOP_MARGIN_PX = 4;

export default class ImageContentBox extends Component {
  state = {
    hovered: false,
    contentHeight: void 0
  };

  contentRef = null;

  setContentRef = element => {
    this.contentRef = element;
  };

  componentDidMount() {
    this.calculateContentHeight(this.contentRef);
    //Fix content height calculation on initial load in local
    setTimeout(() => {
      this.calculateContentHeight(this.contentRef);
    }, 200);
  }

  render() {
    const { imageUrl, title, text, links, dots = false } = this.props;
    const { hovered, contentHeight } = this.state;

    const contentHeightPx = hovered
      ? DEFAULT_CONTENT_HEIGHT_PX + (text || (links && links.length) ? CONTENT_TOP_MARGIN_PX : 0) + (contentHeight || 0)
      : DEFAULT_CONTENT_HEIGHT_PX;

    const contentTopPx = PADDING_PX + 22 + CONTENT_TOP_MARGIN_PX;

    return (
      <div
        className="image-content-box"
        onMouseEnter={() => this.setState({ hovered: true })}
        onClick={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
      >
        <div className={`_background${hovered ? ' --hovered' : ''}`} style={{ backgroundImage: `url(${imageUrl})` }}>
          {dots && <Dots hideOnMobile />}
        </div>

        <div
          className={`_content-area${hovered ? ' --hovered' : ''}`}
          style={{ maxHeight: contentHeightPx, padding: PADDING_PX }}
        >
          <div className="_title">{title}</div>

          {text || (links && links.length) ? (
            <div
              className={`_content${hovered ? ' --hovered' : ''}`}
              ref={this.setContentRef}
              style={hovered ? { opacity: 1, top: contentTopPx } : { top: contentTopPx }}
            >
              {text && <div className="_text">{text}</div>}

              {links && links.length ? (
                <div className="_buttons">
                  {links.map((link, i) => (
                    <PageLink key={i} link={link.link}>
                      <Button key={i}>{link.title}</Button>
                    </PageLink>
                  ))}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  calculateContentHeight(el) {
    if (!el) return;
    this.setState({ contentHeight: el.clientHeight });
  }
}
