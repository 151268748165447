import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';

class ContactInfoWithMapSection extends Component {
  render() {
    const { background, cms, data } = this.props;

    return (
      <section className="section contact-info-with-map" style={{ background }}>
        <div className="iframe-container">
          <iframe
            title="google-map"
            src="https://www.google.com/maps/d/embed?hl=et&mid=1Pgb9R1BU4-KQzV1xXg8Z1P6muDpUgPdx&ll=58.92506628315922%2C24.474369896093776&z=7"
            width="100%"
            height="690"
            frameBorder="0"
            style={{ border: '0', marginTop: '-50px' }}
          ></iframe>
        </div>
        <div className="_content-wrap">
          {data.contactType.map((contactType, i) => (
            <div className="contact-info" key={i} dangerouslySetInnerHTML={{ __html: cms.translate(contactType) }} />
          ))}
        </div>
      </section>
    );
  }
}

export default withCMS(ContactInfoWithMapSection);
