import LandingPage from './js/templates/LandingPage';
import InsurancePage from './js/templates/InsurancePage';
import HeicoPage from './js/templates/HeicoPage';
import ContactPage from './js/templates/ContactPage';
import LeasingPage from './js/templates/LeasingPage';
import CarExchangePage from './js/templates/CarExchangePage';
import AboutPage from './js/templates/AboutPage';
import WarrantyPage from './js/templates/WarrantyPage';
import CarsForSaleSinglePage from './js/templates/CarsForSaleSinglePage';
import ServicePage from './js/templates/ServicePage';
import CarsForSalePage from 'js/templates/CarsForSalePage';
import PreOrderPage from 'js/templates/PreOrderPage';
import UniversalPage from 'js/templates/UniversalPage';

export default {
  landing: LandingPage,
  insurance: InsurancePage,
  heico: HeicoPage,
  contact: ContactPage,
  leasing: LeasingPage,
  'car-exchange': CarExchangePage,
  about: AboutPage,
  warranty: WarrantyPage,
  'cars-for-sale-single': CarsForSaleSinglePage,
  'pre-order': PreOrderPage,
  service: ServicePage,
  'cars-for-sale': CarsForSalePage,
  universal: UniversalPage
};
