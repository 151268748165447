import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import TextInput from '../inputs/TextInput';
import RoundCheckbox from '../inputs/RoundCheckbox';
import LabeledSquareCheckbox from '../inputs/LabeledSquareCheckbox';
import Select from '../inputs/Select';
import ImageInput from '../inputs/ImageInput';
import Button from '../core/Button';
import { calcMonthlyPayment } from 'js/util/lease';
import withLeasingData, { LeaseTypes, LeasePeriods } from '../../hoc/LeasingData';
import * as CarUtils from 'js/util/cars';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import API from 'js/util/api';
import { TextDecoder } from 'text-encoding';

function tryConvert(downPayment, carPrice, convert) {
  const input = parseFloat(downPayment);
  if (Number.isNaN(input)) {
    return '';
  }
  const output = convert(input, carPrice);
  if (Number.isNaN(output)) {
    return '';
  }
  if (!output) return '';
  const rounded = Math.round(output * 100) / 100;
  return rounded;
}

function toNumber(percentage, carPrice) {
  if (percentage === 0 || +carPrice === 0) {
    return '0';
  }
  return carPrice * (percentage / 100);
}

function toPercentage(downPayment, carPrice) {
  if (downPayment === 0 || +carPrice === 0) {
    return '0';
  }
  return (downPayment / carPrice) * 100;
}

class CarLeaseFormSection extends Component {
  handleChange = e => {
    const item = e.target.name;
    const isChecked = e.target.checked;

    this.state.leaseBankSelection.set(item, isChecked);

    this.setState({
      leaseBankSelection: this.state.leaseBankSelection
    });
  };

  handleCustomerType = event => {
    if (event.target.id === 'businessClient') {
      this.setState({
        businessClient: true,
        privateClient: false
      });
    } else if (event.target.id === 'privateClient') {
      this.setState({
        privateClient: true,
        businessClient: false
      });
    } else {
      this.setState(prevState => ({
        businessClient: !prevState.businessClient,
        privateClient: !prevState.privateClient
      }));
    }
  };

  loadModels = (make, setFieldValue) => {
    let specificMakeModels = CarUtils.filterModelsByMake(make, this.props.cms.data.cars);

    this.setState({ carModels: specificMakeModels, carMake: make }, () => {
      //Set the model from lease modal view
      let model = this.props.leasingData.carModel;
      //If the make is changed check if the year exists in the new makes models
      if (
        specificMakeModels.length > 0 &&
        !specificMakeModels.find(model => model.value === this.props.leasingData.carModel)
      ) {
        const updatedErrors = { ...this.state.formErrors };
        delete updatedErrors['carModel'];

        setTimeout(() => {
          this.setState({ formErrors: { ...updatedErrors } });
        });
      }

      setTimeout(() => {
        this.loadProductionYears(model, setFieldValue);
      });
    });
  };

  loadProductionYears = (model, setFieldValue) => {
    const make = this.state.carMake;
    const carProductionYears = CarUtils.filterYearsByMakeAndModel(make, model, this.props.cms.data.cars);

    if (setFieldValue) setFieldValue('carProductionYear', this.props.leasingData.carProductionYear);

    const updatedErrors = { ...this.state.formErrors };
    delete updatedErrors['carProductionYear'];

    this.setState({
      carModel: model,
      carProductionYears: carProductionYears,
      carProductionYear: this.props.leasingData.carProductionYear || carProductionYears[0],
      formErrors: updatedErrors
    });
  };

  successRef = React.createRef();

  leaseForm = React.createRef();

  state = {
    businessClient: true,
    privateClient: false,
    leaseBankSelection: new Map(),
    leaseType: 'operating',
    carMake: this.props.leasingData.carMake,
    carModels: [],
    carModel: this.props.leasingData.carModel,
    carProductionYears: [],
    carProductionYear: this.props.leasingData.carProductionYear,
    successSubmitForm: false,
    formErrors: {}
  };

  setSuccessView() {
    this.setState({ formErrors: {} });
    this.setState({ successSubmitForm: true });
    setTimeout(() => {
      this.successRef.current.scrollIntoView({ left: 0, behavior: 'smooth' });
    }, 50);
  }

  scrollToForm() {
    setTimeout(() => {
      this.leaseForm.current.scrollIntoView({ left: 0, behavior: 'smooth' });
    }, 50);
  }

  getSchema = () => {
    return this.schema;
  };

  componentDidMount() {
    const carMake = this.props.leasingData.carMake;
    if (carMake) {
      this.loadModels(carMake, void 0);
    }
  }

  renderSuccessSubmit() {
    const { cms } = this.props;

    return (
      <div ref={this.successRef}>
        <div className="car-lease-form-section">
          <div className="section-content-wrapper">
            <h2>{cms.translate('form.submit-successful')}</h2>

            <div className="car-change-btn">
              <Button onClick={() => this.setState({ successSubmitForm: false })} type="submit" theme="dark-gray">
                {cms.translate('form.fill-again')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { cms, page, leasingData, refprop, ...props } = this.props;
    const { formErrors } = this.state;
    if (this.state.successSubmitForm) {
      return this.renderSuccessSubmit();
    }

    const data = page.meta.leaseForm;

    if (!data) {
      return null;
    }
    const title = cms.translate(data.title);

    const leaseTypeFormTitle = cms.translate(data.leaseTypeForm.title);

    const carFormTitle = cms.translate(data.vehicleForm.title);

    const carMakes = [];
    cms.data.cars.forEach(car => {
      if (!carMakes.find(c => c.value === car.make)) {
        carMakes.push({ label: car.make, value: car.make });
      }
    });

    const applicantFormTitle = cms.translate(data.applicantForm.title);
    const applicantInstructions = data.applicantForm.applicantInstructions;

    const leaseSubmissionFormTitle = cms.translate(data.leaseSubmissionForm.title);
    const leasePartners = data.leaseSubmissionForm.leasePartners;
    const leaseDisclaimers = data.leaseSubmissionForm.leaseDisclaimers;
    const buttonTitle = cms.translate(data.leaseSubmissionForm.buttonTitle);

    const { businessClient, privateClient } = this.state;

    const errors = formErrors;

    const downPaymentAmount =
      leasingData.downPaymentType === 'percent'
        ? tryConvert(leasingData.downPayment, leasingData.carPrice, toNumber)
        : +leasingData.carPrice
        ? leasingData.downPayment
        : leasingData.carPrice;

    const downPaymentPercent =
      leasingData.downPaymentType === 'percent'
        ? +leasingData.carPrice
          ? leasingData.downPayment
          : leasingData.carPrice
        : tryConvert(leasingData.downPayment, leasingData.carPrice, toPercentage);

    return (
      <Formik
        onSubmit={(values, { setSubmitting, setErrors }) => {
          this.setState({ formValues: values });
          setSubmitting(true);
          API.post('api/forms/car-lease', {
            formBody: {
              ...values,
              leaseType: LeaseTypes(page, cms).find(type => type.value === values.leaseType).label,
              forwardSubmission: values.forwardSubmission,
              clientType: businessClient ? 'business' : 'private'
            }
          })
            .then(e => {
              setSubmitting(false);
              this.setSuccessView();
            })
            .catch(async e => {
              const data = await e.response.json();
              setSubmitting(false);
              this.setState({ formErrors: data.errors });
              this.scrollToForm();
            });
        }}
        initialValues={yup
          .object()
          .shape(this.getSchema())
          .cast()}
      >
        {({
          values,
          _errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched
        }) => {
          const _handleChange = handleChange;
          handleChange = event => {
            setTouched({ ...touched, [event.target.id]: false });
            const updatedErrors = { ...errors };
            delete updatedErrors[event.target.id];
            this.setState({ formErrors: updatedErrors });
            _handleChange(event);
          };
          return (
            <Form>
              <div className="car-lease-form-section" {...props} ref={refprop}>
                <div className="section-content-wrapper" ref={this.leaseForm}>
                  {title && <h2>{title}</h2>}

                  {errors && Object.keys(errors).length ? (
                    <div className="form-errors">
                      <div className="error">{cms.translate('error.errorsWhenSubmittingForm')}</div>
                    </div>
                  ) : null}

                  <div className="lease-subform">
                    <div className="form-wrapper">
                      <div className="left">
                        {leaseTypeFormTitle && <h3>{leaseTypeFormTitle}</h3>}

                        <Select
                          label={cms.translate('leaseView.leaseType')}
                          options={LeaseTypes(page, cms)}
                          // value={leaseType}
                          onChange={event => {
                            leasingData.setLeaseType(event.target.value);
                            handleChange(event);
                          }}
                          value={values.leaseType}
                          errors={errors.leaseType && errors.leaseType.length > 0 && cms.translate(errors.leaseType[0])}
                          touched={touched.leaseType}
                          id="leaseType"
                          // onChange={handleChange}
                        />
                        <Select
                          label={cms.translate('leaseView.leasePeriod')}
                          options={LeasePeriods(page, cms)}
                          // value={leasePeriod}
                          onChange={event => {
                            leasingData.setLeasePeriod(event.target.value);
                            handleChange(event);
                          }}
                          value={values.leasePeriod}
                          errors={
                            errors.leasePeriod && errors.leasePeriod.length > 0 && cms.translate(errors.leasePeriod[0])
                          }
                          touched={touched.leasePeriod}
                          id="leasePeriod"
                          // onChange={handleChange}
                        />
                        <div className="affix-label-wrapper">
                          <TextInput
                            label={cms.translate('leaseView.initialDownPaymentPercent')}
                            // value={downPaymentPercentage}
                            onChange={event => {
                              let value = event.target.value;
                              if (+value > 100) {
                                value = 100;
                              }
                              leasingData.setDownPayment('percent', value);
                              setFieldValue('initialDownPaymentPercent', value);
                            }}
                            value={downPaymentPercent}
                            errors={
                              errors.initialDownPaymentPercent &&
                              errors.initialDownPaymentPercent.length > 0 &&
                              cms.translate(errors.initialDownPaymentPercent[0])
                            }
                            touched={touched.initialDownPaymentPercent}
                            id="initialDownPaymentPercent"
                            // onChange={handleChange}
                          />
                          <div className="affix">{cms.translate('leaseView.downPaymentTypeSeparator')}</div>
                        </div>
                        <TextInput
                          label={cms.translate('leaseView.initialDownPaymentNumber')}
                          // value={downPaymentAmount}
                          onChange={event => {
                            let value = event.target.value;
                            if (+value > +leasingData.carPrice) {
                              value = leasingData.carPrice;
                            }
                            leasingData.setDownPayment('downPaymentAmount', value);
                            setFieldValue('downPaymentAmount', value);
                          }}
                          value={downPaymentAmount}
                          errors={
                            errors.downPaymentAmount &&
                            errors.downPaymentAmount.length > 0 &&
                            cms.translate(errors.downPaymentAmount[0])
                          }
                          touched={touched.downPaymentAmount}
                          id="downPaymentAmount"
                          // onChange={handleChange}
                        />
                        <TextInput
                          label={cms.translate('leaseView.interestRate')}
                          // value={interestRate}
                          onChange={event => {
                            leasingData.setInterestRate(event.target.value);
                            handleChange(event);
                          }}
                          value={values.interestRate}
                          errors={
                            errors.interestRate &&
                            errors.interestRate.length > 0 &&
                            cms.translate(errors.interestRate[0])
                          }
                          touched={touched.interestRate}
                          id="interestRate"
                          // onChange={handleChange}
                        />
                        <TextInput
                          label={cms.translate('leaseView.residualValue')}
                          // value={residualValuePercentage}
                          onChange={event => {
                            leasingData.setResidualValuePercentage(event.target.value);
                            handleChange(event);
                          }}
                          value={values.residualValuePercentage}
                          errors={
                            errors.residualValuePercentage &&
                            errors.residualValuePercentage.length > 0 &&
                            cms.translate(errors.residualValuePercentage[0])
                          }
                          touched={touched.residualValuePercentage}
                          id="residualValuePercentage"
                          // onChange={handleChange}
                        />
                      </div>
                      <div className="right">
                        {carFormTitle && <h3>{carFormTitle}</h3>}
                        <Select
                          label={cms.translate('form.make')}
                          // value={this.state.carMake}
                          onChange={event => {
                            this.loadModels(event.target.value, setFieldValue);
                            handleChange(event);
                          }}
                          options={carMakes}
                          value={values.carMake}
                          defaultOption={{ value: '-', label: cms.translate('form.choose-mark') }}
                          errors={errors.carMake && errors.carMake.length > 0 && cms.translate(errors.carMake[0])}
                          touched={touched.carMake}
                          id="carMake"
                          // onChange={handleChange}
                        />
                        <Select
                          label={cms.translate('form.model')}
                          // value={this.state.carModel}
                          onChange={event => {
                            this.loadProductionYears(event.target.value, setFieldValue);
                            handleChange(event);
                          }}
                          options={this.state.carModels}
                          value={values.carModel}
                          defaultOption={{ value: '-', label: cms.translate('form.selectDefault') }}
                          errors={errors.carModel && errors.carModel.length > 0 && cms.translate(errors.carModel[0])}
                          touched={touched.carModel}
                          id="carModel"
                          // onChange={handleChange}
                        />
                        <Select
                          label={cms.translate('leaseView.releaseYear')}
                          // value={this.state.carProductionYear}
                          onChange={event => {
                            this.setState({ carProductionYear: event.target.value });
                            handleChange(event);
                          }}
                          options={this.state.carProductionYears}
                          value={values.carProductionYear || '-'}
                          defaultOption={{ value: '-', label: cms.translate('form.selectDefault') }}
                          errors={
                            errors.carProductionYear &&
                            errors.carProductionYear.length > 0 &&
                            cms.translate(errors.carProductionYear[0])
                          }
                          touched={touched.carProductionYear}
                          id="carProductionYear"
                          // onChange={handleChange}
                        />
                        <TextInput
                          label={cms.translate('form.price')}
                          // value={carPrice}
                          onChange={event => {
                            leasingData.setPrice(event.target.value);
                            handleChange(event);
                          }}
                          value={values.carPrice}
                          errors={errors.carPrice && errors.carPrice.length > 0 && cms.translate(errors.carPrice[0])}
                          touched={touched.carPrice}
                          id="carPrice"
                          // onChange={handleChange}
                        />
                        <div className="monthly-payment">
                          {cms.translate('form.monthlyPayment')}:
                          <span>
                            {calcMonthlyPayment(
                              values.carPrice,
                              values.initialDownPaymentPercent,
                              values.leaseType,
                              values.interestRate,
                              values.residualValuePercentage,
                              values.leasePeriod
                            )}{' '}
                            €
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="applicant-subform">
                    {applicantFormTitle && <h2>{applicantFormTitle}</h2>}
                    <div className="customer-type-selection">
                      <label>
                        <RoundCheckbox
                          id="businessClient"
                          checked={businessClient}
                          value={values.businessClient}
                          onChange={event => {
                            this.handleCustomerType(event);
                            handleChange(event);
                          }}
                        />
                        {cms.translate('leaseView.businessClient')}
                      </label>
                      <label>
                        <RoundCheckbox
                          id="privateClient"
                          checked={privateClient}
                          value={values.privateClient}
                          onChange={event => {
                            this.handleCustomerType(event);
                            handleChange(event);
                          }}
                        />
                        {cms.translate('leaseView.privateClient')}
                      </label>
                    </div>
                    {applicantInstructions && (
                      <div className="applicant-intructions">
                        {applicantInstructions.map((instruction, key) => {
                          const instr = cms.translate(instruction);
                          if (!instr) return null;
                          return <div key={key}>{instr}</div>;
                        })}
                      </div>
                    )}
                    <div className="form-wrapper">
                      <div className="left">
                        <TextInput
                          label={`${businessClient ? cms.translate('form.companyName') : cms.translate('form.name')}`}
                          // value={void 0}
                          // onChange={() => void 0}
                          value={businessClient ? values.companyName : values.name}
                          id={`${businessClient ? 'companyName' : 'name'}`}
                          errors={
                            businessClient
                              ? errors.companyName &&
                                errors.companyName.length > 0 &&
                                cms.translate(errors.companyName[0])
                              : errors.name && errors.name.length > 0 && cms.translate(errors.name[0])
                          }
                          touched={businessClient ? touched.companyName : touched.name}
                          onChange={handleChange}
                        />
                        <TextInput
                          label={`${
                            businessClient ? cms.translate('form.businessRegisterCode') : cms.translate('form.idCode')
                          }`}
                          // value={void 0}
                          // onChange={() => void 0}
                          value={businessClient ? values.businessRegisterCode : values.idCode}
                          id={`${businessClient ? 'businessRegisterCode' : 'idCode'}`}
                          errors={
                            businessClient
                              ? errors.businessRegisterCode &&
                                errors.businessRegisterCode.length > 0 &&
                                cms.translate(errors.businessRegisterCode[0])
                              : errors.idCode && errors.idCode.length > 0 && cms.translate(errors.idCode[0])
                          }
                          touched={businessClient ? touched.businessRegisterCode : touched.idCode}
                          onChange={handleChange}
                        />
                        <TextInput
                          label={cms.translate('form.address')}
                          value={values.address}
                          id="address"
                          errors={errors.address && errors.address.length > 0 && cms.translate(errors.address[0])}
                          touched={touched.address}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="right">
                        <TextInput
                          label={cms.translate('form.phone')}
                          value={values.phone}
                          id="phone"
                          errors={errors.phone && errors.phone.length > 0 && cms.translate(errors.phone[0])}
                          touched={touched.phone}
                          onChange={handleChange}
                        />
                        <TextInput
                          label={cms.translate('form.email')}
                          value={values.email}
                          id="email"
                          errors={errors.email && errors.email.length > 0 && cms.translate(errors.email[0])}
                          touched={touched.email}
                          onChange={handleChange}
                        />
                        {businessClient && (
                          <TextInput
                            label={cms.translate('leaseView.leaseRepresentative')}
                            value={values.leaseRepresentative}
                            id="leaseRepresentative"
                            onChange={handleChange}
                            errors={
                              errors.leaseRepresentative &&
                              errors.leaseRepresentative.length > 0 &&
                              cms.translate(errors.leaseRepresentative[0])
                            }
                            touched={touched.leaseRepresentative}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="add-documents-subform">
                    <div className="image-inputs-wrapper">
                      <ImageInput
                        label={`${
                          businessClient
                            ? cms.translate('leaseView.balanceSheet')
                            : cms.translate('leaseView.idDocument')
                        }`}
                        value={businessClient ? values.balanceSheet : values.idDocument}
                        id={businessClient ? 'balanceSheet' : 'idDocument'}
                        // onChange={handleChange}
                        onChange={event => {
                          setFieldValue(businessClient ? 'balanceSheet' : 'idDocument', event.currentTarget.files[0]);
                        }}
                        type="file"
                        setFieldValue={setFieldValue}
                        errors={
                          businessClient
                            ? errors.balanceSheet &&
                              errors.balanceSheet.length > 0 &&
                              cms.translate(errors.balanceSheet[0])
                            : errors.idDocument && errors.idDocument.length > 0 && cms.translate(errors.idDocument[0])
                        }
                      />
                      <ImageInput
                        label={`${
                          businessClient
                            ? cms.translate('leaseView.incomeStatement')
                            : cms.translate('leaseView.bankStatement')
                        }`}
                        value={businessClient ? values.incomeStatement : values.bankStatement}
                        id={businessClient ? 'incomeStatement' : 'bankStatement'}
                        // onChange={handleChange}
                        onChange={event => {
                          setFieldValue(
                            businessClient ? 'incomeStatement' : 'bankStatement',
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        setFieldValue={setFieldValue}
                        errors={
                          businessClient
                            ? errors.incomeStatement &&
                              errors.incomeStatement.length > 0 &&
                              cms.translate(errors.incomeStatement[0])
                            : errors.bankStatement &&
                              errors.bankStatement.length > 0 &&
                              cms.translate(errors.bankStatement[0])
                        }
                      />
                    </div>
                  </div>

                  <div className="bank-selection-subform">
                    {leaseSubmissionFormTitle && <h4>{leaseSubmissionFormTitle}</h4>}
                    <div className="bank-checkboxes">
                      {leasePartners.map((checkbox, index) => {
                        const name = cms.translate(checkbox);
                        return (
                          <LabeledSquareCheckbox
                            className="_model-checkbox"
                            key={index}
                            onClick={() => {
                              let forwardSubmissions = values['forwardSubmission'];
                              const indexOfSubmission = (forwardSubmissions || []).indexOf(name);
                              if (indexOfSubmission > -1) {
                                forwardSubmissions.splice(indexOfSubmission, 1);
                                setFieldValue('forwardSubmission', forwardSubmissions);
                              } else {
                                setFieldValue('forwardSubmission', [...(forwardSubmissions || []), name]);
                              }
                            }}
                            id={name}
                            name={name}
                            checked={values['forwardSubmission'] ? values['forwardSubmission'].includes(name) : false}
                          >
                            {name}
                          </LabeledSquareCheckbox>
                        );
                      })}
                    </div>
                  </div>

                  <div className="legal-reminder">
                    {leaseDisclaimers.map((disclaimer, i) => {
                      const disc = cms.translate(disclaimer);
                      if (!disc) return null;
                      return (
                        <div key={i}>
                          {`${i + 1})`} {disc}
                        </div>
                      );
                    })}
                  </div>

                  <div className="car-change-btn">
                    {buttonTitle && (
                      <Button type="submit" disabled={isSubmitting} showLoading={isSubmitting} theme="dark-gray">
                        {buttonTitle}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  schema = {
    leaseType: yup.string().default(this.props.leasingData.leaseType),
    leasePeriod: yup.number().default(this.props.leasingData.leasePeriod),
    initialDownPaymentPercent: yup.number().default(this.props.leasingData.downPayment),
    downPaymentAmount: yup.number().default(''),
    downPaymentType: yup.string().default(''),
    interestRate: yup.number().default(this.props.leasingData.interestRate),
    residualValuePercentage: yup.number().default(this.props.leasingData.residualValuePercentage),
    carMake: yup.string().default(this.props.leasingData.carMake),
    carModel: yup.string().default(this.props.leasingData.carModel),
    carProductionYear: yup.string().default(this.props.leasingData.carProductionYear),
    carPrice: yup.string().default(this.props.leasingData.carPrice || ''),
    businessClient: yup.string().default(''),
    privateClient: yup.string().default(''),
    companyName: yup.string().default(''),
    name: yup.string().default(''),
    businessRegisterCode: yup.string().default(''),
    idCode: yup.string().default(''),
    address: yup.string().default(''),
    phone: yup.string().default(''),
    email: yup.string().default(''),
    leaseRepresentative: yup.string().default(''),
    nameWithIndex: yup.string().default(''),
    forwardSubmission: yup.array()
  };
}

export default withLeasingData(withCMS(CarLeaseFormSection));
