import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import Button from '../core/Button';
import Dots from '../Dots';
import PageLink from 'js/components/core/PageLink';
import withFilters from '../../hoc/FiltersData';

class HeroSection extends Component {
  render() {
    const { imageUrl, button, headerText, smallHeader, cms } = this.props;
    const buttonText = button && cms.translate(button.title);

    if (!imageUrl || !headerText) return null;

    return (
      <div className="hero-section">
        <div className="hero-image" style={{ backgroundImage: `url(${imageUrl})` }} />
        <div className="hero-content-wrapper">
          <Dots />
          <div className="hero-content">
            {smallHeader && <h2>{smallHeader}</h2>}
            <h1>{headerText}</h1>
            {buttonText && (
              <PageLink link={button.link} className="_link">
                <Button
                  onClick={e => {
                    this.props.filters.deleteFilters();
                    if (typeof button.action === 'function') {
                      button.action(e);
                    }
                  }}
                >
                  {buttonText}
                </Button>
              </PageLink>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withFilters(withCMS(HeroSection));
