import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withCMS from '../../hoc/CMSData';

class PageLink extends Component {
  render() {
    const { page, link, cms, children, urlParams = '?', ...props } = this.props;

    let linkData;
    let href = '#';

    if (link) linkData = cms.translate(link);
    if (page && page.slug) href = cms.translateLink(page.slug).concat(urlParams);

    // Page Link
    if (linkData && linkData.type === 'page_link') {
      // Find page
      const linkedPage = cms.data.pages.find(p => Number(p.id) === Number(linkData.value));
      if (linkedPage && cms.translate(linkedPage.slug)) href = cms.translateLink(linkedPage.slug);
    } else if (linkData && linkData.type === 'external_link') {
      href = linkData.value;
      return (
        <a href={href} rel="noopener noreferrer" target="_blank">
          {children}
        </a>
      );
    } else if (linkData && linkData.type === 'internal_link') {
      href = linkData.value;
      return (
        <a className="_link" href={href}>
          {children}
        </a>
      );
    } else if (linkData) {
      href = linkData.value;
    }

    if (!href) return null;

    return (
      <Link to={href} {...props}>
        {children}
      </Link>
    );
  }
}

export default withCMS(PageLink);
