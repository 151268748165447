import React, { Component } from 'react';
import MobileModal from './MobileModal';
import withCMS from '../../hoc/CMSData';
import { Trail, animated, config } from 'react-spring';
import Button from '../core/Button';
import CarThumbWithCheckbox from './CarThumbWithCheckbox';
import PageLink from '../core/PageLink';
import LabeledSquareCheckbox from '../inputs/LabeledSquareCheckbox';
import withFilters from '../../hoc/FiltersData';

class MobileCarSelectionModal extends Component {
  renderCarSelection = style => {
    const { close, cms, filters } = this.props;

    return (
      <animated.div className="mobile-car-selection-modal mobile-modal" style={style}>
        <div className="_title-area">
          <div className="_title">VAATA PAKKUMISI</div>
          <div className="_close-button" onClick={close} />
        </div>

        <div className="_cars-list">{this.renderCarsList()}</div>

        <div className="_buttons-area">
          <PageLink page={cms.getPage('cars-for-sale')} onClick={close}>
            <Button theme="dark-gray">{cms.translate('carFilters.showOffersButton')}</Button>
          </PageLink>

          <LabeledSquareCheckbox checked={filters.otherMakes} onClick={filters.toggleOtherMakes}>
            {cms.translate('carFilters.otherMakes')}
          </LabeledSquareCheckbox>

          <LabeledSquareCheckbox checked={filters.onlyHeicoSportiv} onClick={filters.toggleOnlyHeicoSportiv}>
            {cms.translate('carFilters.onlyHeicoSportiv')}
          </LabeledSquareCheckbox>

          <LabeledSquareCheckbox checked={filters.onlyNew} onClick={filters.toggleOnlyNew}>
            {cms.translate('carFilters.onlyNew')}
          </LabeledSquareCheckbox>
        </div>
      </animated.div>
    );
  };

  renderCarsList = () => {
    const { cms, filters } = this.props;
    const volvoModels = cms.data.volvoModels || [];

    return (
      <Trail
        native
        items={volvoModels}
        keys={volvo => volvo.id}
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        clamp={true}
        config={{ ...config.default, friction: 32, tension: 330 }}
      >
        {volvo => style => (
          <CarThumbWithCheckbox
            style={style}
            key={volvo.id}
            car={volvo}
            selected={filters.selectedVolvoModels.includes(volvo.name)}
            onClick={() => filters.toggleModel(volvo.name)}
          />
        )}
      </Trail>
    );
  };

  render() {
    const { show } = this.props;
    return <MobileModal show={show}>{style => this.renderCarSelection(style)}</MobileModal>;
  }
}

export default withFilters(withCMS(MobileCarSelectionModal));
