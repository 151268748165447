export const findPage = (pathname, pages, preferLocale = null) => {
  const doesPageMatch = (_path, page, locale = null) => {
    if (locale) {
      if (_path === page.slug[locale]) return true;
      if (_path === `/${locale}${page.slug[locale]}`) return true;
      if (page.slug[locale] === '/') return _path === `/${locale}`;
    } else {
      for (const slug of Object.keys(page.slug)) {
        if (doesPageMatch(_path, page, slug)) return true;
      }
    }
    return false;
  };

  let page = pages.find(page => doesPageMatch(pathname, page, preferLocale));
  if (page) return page;
  if (!preferLocale) return null;

  // No page was found? Try again without a preferred locale, just in case.
  return pages.find(page => doesPageMatch(pathname, page));
};
