import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';

class CarsForSaleSingleEquipmentLists extends Component {
  render() {
    const { cms, car } = this.props;

    const importantEquipment = car.otherEquipment || [];
    return (
      <div className="cars-for-sale-single-equipment-lists">
        <div className="_important-equipment equipment-list">
          <h3 className="_list-title">{cms.translate('equipmentCategory.important')}</h3>

          <ul className="_list">
            {importantEquipment.map((eq, i) => (
              <div className="_list-item-wrapper" key={i}>
                <div className="_list-item">
                  <div className="_list-icon" />
                  <div className="_list-content">{eq.trim()}</div>
                </div>
              </div>
            ))}
          </ul>
        </div>

        <div className="_other-equipment-wrapper">
          <div className="_lists-list">
            {this.renderList(this.createList('safetyEquipment'))}
            {this.renderList(this.createList('lights'))}
          </div>

          <div className="_lists-list">
            {this.renderList(this.createList('multimedia'))}
            {this.renderList(this.createList('tires'))}
            {this.renderList(this.createList('comfort'))}
          </div>

          <div className="_lists-list">
            {this.renderList(this.createList('interior'))}
            {this.renderList(this.createList('sport'))}
            {this.renderList(this.createList('other'))}
          </div>
        </div>

        <div className="_additional-info">
          <h3 className="_title">{cms.translate('carsForSaleView.additionalInfo')}</h3>
          {car.otherInfo}
        </div>
      </div>
    );
  }

  renderList = (list = {}) => (
    <div className="equipment-list">
      <h3 className="_list-title">{list.title}</h3>

      <ul className="_list">
        {list.items.map((eq, i) => (
          <li className="_list-item" key={i}>
            {eq}
          </li>
        ))}
      </ul>
    </div>
  );

  createList = categoryName => {
    const { cms, car } = this.props;
    const foundList = car.equipment.find(c => c.category === categoryName);
    const list = { title: cms.translate(`equipmentCategory.${categoryName}`) };
    list.items = foundList.items.map(item => {
      let name = cms.translate(item.value);
      if (item.children) {
        if (item.children.length > 0) {
          const _children = item.children.map(_c => cms.translate(_c.value));
          name += ` (${_children.join(', ')})`;
        }
      }
      return name;
    });
    return list;
  };
}

export default withCMS(CarsForSaleSingleEquipmentLists);
