import React, { Component } from 'react';
import { Portal } from 'react-portal';
import { Transition, config } from 'react-spring';

export default class PopupModal extends Component {
  render() {
    const { show, children } = this.props;

    return (
      <Portal>
        <Transition
          native
          from={{ opacity: show ? 0 : 1, transform: `translateX(${show ? 275 : 0}px)` }}
          enter={{ opacity: show ? 1 : 0, transform: `translateX(${show ? 0 : 275}px)` }}
          leave={{ opacity: show ? 1 : 0, transform: `translateX(${show ? 0 : 275}px)` }}
          items={show}
          config={{ ...config.default, friction: 32, tension: 330, duration: 410 }}
        >
          {show => (show ? style => children(style) : null)}
        </Transition>
      </Portal>
    );
  }
}
