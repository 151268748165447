import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import withFilters from 'js/hoc/FiltersData';
import Gallery from '../Gallery';
import Section from '../sections/Section';
import Button from '../core/Button';
import PageLink from 'js/components/core/PageLink';
import lightboxTheme from 'js/util/lightbox-theme';

class HeicoInfoSection extends Component {
  render() {
    const { data, cms, filters } = this.props;
    // Remove gallery elements without any images.
    const galleryImages = data.gallery
      .filter(d => d.galleryImage.length !== 0 && d.galleryImage && cms.translate(d.galleryImage))
      .map(galleryObj => {
        const localeImage = cms.translate(galleryObj.galleryImage);
        const localeCaption = cms.translate(galleryObj.imageCaption);
        return { src: localeImage.url, thumbnail: localeImage.thumbnailUrl, caption: localeCaption };
      });

    const viewHeicoCarsTitle = data.heicoFormSubmit && cms.translate(data.heicoFormSubmit.submitFormTitle);
    const viewHeicoCarsButtonText = data.heicoFormSubmit && cms.translate(data.heicoFormSubmit.buttonText);

    const heicoCarsLink = data.heicoFormSubmit && data.heicoFormSubmit.link;

    return (
      <div className="heico-info-section">
        <div className="section-content-wrapper">
          <h3>{cms.translate(data.title)}</h3>
          <Section>
            {data.infoSection.map((paragraph, i) => (
              <span key={i}>
                {i !== 0 && <br />}
                {i !== 0 && <br />}
                {cms.translate(paragraph)}
              </span>
            ))}
          </Section>

          <Gallery images={galleryImages} showThumbnails theme={lightboxTheme} />
          {viewHeicoCarsTitle && <h3>{viewHeicoCarsTitle}</h3>}
          <div className="heico-sportive-button">
            <PageLink
              link={heicoCarsLink}
              onClick={() => {
                this.props.filters.deleteFilters();
                filters.setOnlyHeicoSportiv(true);
              }}
            >
              {viewHeicoCarsButtonText && <Button theme="dark-gray">{viewHeicoCarsButtonText}</Button>}
            </PageLink>
          </div>
        </div>
      </div>
    );
  }
}

export default withFilters(withCMS(HeicoInfoSection));
