import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import TextInput from '../inputs/TextInput';
import TextArea from '../inputs/TextArea';
import Button from '../core/Button';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Transition, animated, config } from 'react-spring';
import API from 'js/util/api';

const States = {
  Form: 'form',
  Success: 'success',
  Error: 'error'
};
class PreOrderFormSection extends Component {
  state = { state: States.Form, formValues: void 0, loading: false };
  render() {
    const { data, cms } = this.props;
    const { state } = this.state;

    if (!data) return null;

    const title = cms.translate(data.formTitle);
    const subtitle = cms.translate(data.formSubtitle);

    return (
      <div className="pre-order-form-section" ref={this.props.refprop}>
        <div className="form-wrapper">
          {state === States.Form && title && <h1>{title}</h1>}
          {state === States.Form && subtitle && <p>{subtitle}</p>}

          <Transition
            native
            items={state}
            config={config.default}
            from={{ opacity: 0, transform: 'scale3d(0.9, 0.9, 0.9)' }}
            enter={{ opacity: 1, transform: 'scale3d(1, 1, 1)', display: 'block' }}
            leave={{ opacity: 0, transform: 'scale3d(0.9, 0.9, 0.9)', display: 'none' }}
          >
            {state => {
              if (state === States.Form) return this.FormView;

              if (state === States.Success) return this.SuccessView;

              if (state === States.Error) return this.ErrorView;
            }}
          </Transition>
        </div>
      </div>
    );
  }

  FormView = style => {
    const { cms, data, refprop } = this.props;
    const buttonTitle = cms.translate(data.buttonTitle);

    const carFormTitle = cms.translate(data.carQueryTitle);

    const contactInfoTitle = cms.translate(data.contactInfoTitle);

    return (
      <animated.div style={style}>
        <Formik
          validationSchema={this.PreOrderSchema}
          onSubmit={values => {
            //Set default values for the form reload
            this.setState({ formValues: values, loading: true });

            API.post('api/forms/pre-order', { formBody: values })
              .then(() => {
                this.setState({ state: States.Success, loading: false });
                setTimeout(() => this.scrollToForm(refprop));
              })
              .catch(e => {
                setTimeout(() => this.setState({ state: States.Error, loading: false }), 500);
                setTimeout(() => this.setState({ state: States.Form }), 5000);
                setTimeout(() => this.scrollToForm(refprop));
              });
          }}
          mapPropsToValues
          initialValues={this.state.formValues || this.PreOrderSchema.cast()}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <Form>
              {carFormTitle && <h2>{carFormTitle}</h2>}
              {this.OldCarForm(values, touched, errors, handleChange, handleBlur)}

              {contactInfoTitle && <h2>{contactInfoTitle}</h2>}
              <div className="contact-info-form">
                {this.ContactForm(values, touched, errors, handleChange, handleBlur)}
              </div>
              <div className="button-wrapper">
                {buttonTitle && (
                  <Button showLoading={this.state.loading} type="submit" onClick={handleSubmit} theme="dark-gray">
                    {buttonTitle}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </animated.div>
    );
  };

  ContactForm = (values, touched, errors, handleChange, handleBlur) => {
    const { cms } = this.props;

    return (
      <React.Fragment>
        <TextInput
          id="name"
          label={cms.translate('form.name')}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.name}
          touched={touched.name}
        />
        <TextInput
          id="phone"
          type="tel"
          label={cms.translate('form.phone')}
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.phone}
          touched={touched.phone}
        />
        <TextInput
          id="email"
          type="email"
          label={cms.translate('form.email')}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.email}
          touched={touched.email}
        />
      </React.Fragment>
    );
  };

  OldCarForm = (values, touched, errors, handleChange, handleBlur) => {
    const { cms } = this.props;

    return (
      <React.Fragment>
        <div className="left">
          <TextInput
            label={cms.translate('form.make')}
            value={values.make}
            id="make"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.make}
            touched={touched.make}
          />
          <TextInput
            label={cms.translate('form.model')}
            value={values.model}
            id="model"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.model}
            touched={touched.model}
          />
          <TextInput
            id="gearbox"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.gearbox}
            touched={touched.gearbox}
            value={values.gearbox}
            label={cms.translate('form.gearbox')}
          />
          <TextInput
            label={cms.translate('form.engine')}
            value={values.engine}
            id="engine"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.engine}
            touched={touched.engine}
          />
          <TextInput
            label={cms.translate('form.carReleaseYear')}
            value={values.initialRegistration}
            id="initialRegistration"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.initialRegistration}
            touched={touched.initialRegistration}
          />
          <TextInput
            label={cms.translate('form.mileage')}
            value={values.mileage}
            id="mileage"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.mileage}
            touched={touched.mileage}
          />
        </div>
        <div className="right">
          <TextInput
            label={cms.translate('form.color')}
            value={values.color}
            id="color"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.color}
            touched={touched.color}
          />
          <div className="price-range">
            <div className="price-range-wrapper">
              <TextInput
                label={cms.translate('form.priceRange')}
                value={values.startingPrice}
                id="startingPrice"
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.startingPrice}
                touched={touched.startingPrice}
              />
              <div className="price-range-sign">—</div>
              <TextInput
                label={' '}
                value={values.maximumPrice}
                id="maximumPrice"
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.maximumPrice}
                touched={touched.maximumPrice}
              />
            </div>
          </div>

          <TextArea
            label={cms.translate('form.extraInfo')}
            value={values.extraInfo}
            id="extraInfo"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.extraInfo}
            touched={touched.extraInfo}
            flex
          />
        </div>
      </React.Fragment>
    );
  };

  ErrorView = style => {
    const { cms } = this.props;
    return (
      <animated.div className="_success-view" style={style}>
        <span>{cms.translate('error.sendEmail')}</span>
        <div className="form-retry-button">
          <Button theme="dark-gray" onClick={() => this.setState({ state: States.Form })}>
            {cms.translate('error.tryAgain')}
          </Button>
        </div>
      </animated.div>
    );
  };

  SuccessView = style => {
    const { cms } = this.props;
    return (
      <animated.div className="_success-view" style={style}>
        <span>{cms.translate('success.sendCarExchange')}</span>

        <div className="form-retry-button">
          <Button theme="dark-gray" onClick={() => this.setState({ state: States.Form })}>
            {cms.translate('form.newQuery')}
          </Button>
        </div>
      </animated.div>
    );
  };

  PreOrderSchema = yup.object().shape({
    make: yup
      .string()
      .required(this.props.cms.translate('validation.make'))
      .default(''),
    model: yup
      .string()
      .required(this.props.cms.translate('validation.model'))
      .default(''),
    gearbox: yup.string().default(''),
    engine: yup.string().default(''),
    initialRegistration: yup.string().default(''),
    mileage: yup.string().default(''),
    color: yup.string().default(''),
    startingPrice: yup.string().default(''),
    maximumPrice: yup.string().default(''),
    extraInfo: yup.string().default(''),
    name: yup.string().default(''),
    phone: yup.string().default(''),
    email: yup
      .string()
      .required(this.props.cms.translate('validation.email'))
      .email(this.props.cms.translate('validation.emailFormat'))
      .default('')
  });

  scrollToForm = refprop => {
    refprop.current.scrollIntoView({
      behavior: 'smooth'
    });
  };
}

export default withCMS(PreOrderFormSection);
