import React, { Component } from 'react';
import ImageContentBox from '../ImageContentBox';

export default class FourBoxSection extends Component {
  renderImageBox = (data, dots = false) => (
    <ImageContentBox
      dots={dots}
      imageUrl={data.imageUrl}
      title={data.title}
      text={data.description}
      links={data.links}
    />
  );

  render() {
    const { data } = this.props;
    if (!data) return null;

    return (
      <div className="four-box-section">
        {/* Left side */}
        {this.renderImageBox(data[0], true)}

        {/* Right side */}
        <div className="_right-side-boxes">
          <div className="_top">{this.renderImageBox(data[1])}</div>

          <div className="_bottom">
            {this.renderImageBox(data[2])}
            {this.renderImageBox(data[3])}
          </div>
        </div>
      </div>
    );
  }
}
