import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import { animated } from 'react-spring';
import TextInput from '../inputs/TextInput';
import Select from '../inputs/Select';
import Button from '../core/Button';
import * as LeaseUtils from 'js/util/lease';
import PopupModal from 'js/components/PopupModal';
import * as CarUtils from 'js/util/cars';
import PageLink from 'js/components/core/PageLink';
import withLeasingData, { LeaseTypes, LeasePeriods } from '../../hoc/LeasingData';

function toPrice(percentage, carPrice) {
  return carPrice * (percentage / 100);
}

function toPercentage(downPayment, carPrice) {
  return (downPayment / carPrice) * 100;
}

class LeaseModalSection extends Component {
  constructor(props) {
    super(props);
    this.props.leasingData.setPrice(CarUtils.getCurrentPrice(this.props.cms, this.props.car));
    this.props.leasingData.setCarMake(this.props.car.make);
    this.props.leasingData.setCarModel(this.props.car.model.model);
    this.props.leasingData.setCarProductionYear(CarUtils.getRegistrationYear(this.props.cms, this.props.car));
  }

  renderLeaseModal = style => {
    const { cms, close, leasingData, contentRef } = this.props;

    const leasePage = cms.getPage('leasing');
    if (!leasePage) return null;

    const {
      downPaymentType,
      downPayment,
      interestRate,
      residualValuePercentage,
      leaseType,
      leasePeriod,
      carPrice
    } = leasingData;

    const downPaymentAmount =
      downPaymentType === 'percent' ? LeaseUtils.tryConvert(downPayment, carPrice, toPrice) : downPayment;
    const downPaymentPercentage =
      downPaymentType === 'downPayment' ? LeaseUtils.tryConvert(downPayment, carPrice, toPercentage) : downPayment;

    const monthlyPayment = LeaseUtils.calcMonthlyPayment(
      carPrice,
      downPaymentPercentage,
      leaseType,
      interestRate,
      residualValuePercentage,
      leasePeriod
    );

    return (
      <animated.div className="lease-popup-section" style={style} ref={contentRef}>
        <div className="lease-popup-wrapper">
          <h2>LIISINGU KALKULAATOR</h2>
          <div className="close-button" onClick={close} />
          <Select
            label="Liisingu tüüp"
            options={LeaseTypes(leasePage, cms)}
            value={leaseType}
            onChange={event => leasingData.setLeaseType(event.target.value)}
          />
          <TextInput
            label="Sõiduki hind"
            value={carPrice}
            onChange={event => leasingData.setPrice(event.target.value)}
          />
          <Select
            label="Liisinguperioodi pikkus"
            options={LeasePeriods(leasePage, cms)}
            onChange={event => leasingData.setLeasePeriod(event.target.value)}
            value={leasePeriod}
          />
          <div className="affix-label-wrapper">
            <TextInput
              label="Esimene sissemakse (%)"
              value={downPaymentPercentage}
              onChange={event => leasingData.setDownPayment('percent', event.target.value)}
            />
            <div className="affix">VÕI</div>
          </div>
          <TextInput
            label="Esimene sissemakse (EUR)"
            value={downPaymentAmount}
            onChange={event => leasingData.setDownPayment('downPayment', event.target.value)}
          />
          <TextInput
            value={interestRate}
            label="Intressimäär (%)"
            onChange={event => leasingData.setInterestRate(event.target.value)}
          />
          <TextInput
            value={residualValuePercentage}
            label="Jääkväärtus (%)"
            onChange={event => leasingData.setResidualValuePercentage(event.target.value)}
          />
          <div className="monthly-payment">
            Kuumakse: <span>{monthlyPayment} €</span>
          </div>
          <div className="lease-popup-disclaimer">
            Kalkulaatori arvestus on näitlik ja võib erineda liisinguandja pakutavatest tingimustest.
          </div>
          <PageLink className="_link" page={leasePage}>
            <Button theme="dark-gray">TÄIDA LIISINGUTAOTLUS</Button>
          </PageLink>
        </div>
      </animated.div>
    );
  };

  render() {
    const { show } = this.props;

    return <PopupModal show={show}>{style => this.renderLeaseModal(style)}</PopupModal>;
  }
}

export default withLeasingData(withCMS(LeaseModalSection));
