import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';

export default class Loader extends Component {
  render() {
    return (
      <MDSpinner
        singleColor={this.props.color || '#29a8e0'}
        className="loader"
        size={this.props.size || 35}
        style={this.props.style}
      />
    );
  }
}
