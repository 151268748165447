import React, { Component } from 'react';
import withCMS from '../../hoc/CMSData';
import { Transition, animated, config, Trail } from 'react-spring';
import { Portal } from 'react-portal';
import CarThumbWithCheckbox from './CarThumbWithCheckbox';
import Button from '../core/Button';
import { createEqualizedGroups } from 'js/util/volvo-models';
import withFilters from '../../hoc/FiltersData';
import PageLink from '../core/PageLink';
import LabeledSquareCheckbox from '../inputs/LabeledSquareCheckbox';

class CarSelectionDropdown extends Component {
  renderDropdown = style => {
    const { cms, filters, contentRef, close } = this.props;
    const volvoGroups = createEqualizedGroups(cms);
    const carsForSalePage = cms.getPage('cars-for-sale');

    return (
      <animated.div className="car-selection-dropdown" style={style} ref={contentRef}>
        <div className="_content-wrapper">
          <div className="_left-content">
            <div className="_cars-list">
              {Object.values(volvoGroups).map((group, i) => (
                <div className="_group" key={i}>
                  <Trail
                    delay={(i + 1.5) * 100}
                    native
                    items={group}
                    keys={volvo => volvo.id}
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}
                    clamp={true}
                  >
                    {volvo => style =>
                      volvo._empty ? (
                        <div key={volvo.id} className="_empty-box car-thumb-with-checkbox" />
                      ) : (
                        <CarThumbWithCheckbox
                          style={style}
                          key={volvo.id}
                          car={volvo}
                          selected={filters.selectedVolvoModels.includes(volvo.name)}
                          onClick={() => filters.toggleModel(volvo.name)}
                        />
                      )}
                  </Trail>
                </div>
              ))}
            </div>
          </div>

          <div className="_right-buttons">
            <LabeledSquareCheckbox
              className="_generic-checkbox"
              onClick={filters.toggleOnlyNew}
              checked={filters.onlyNew}
            >
              {cms.translate('carFilters.onlyNew')}
            </LabeledSquareCheckbox>

            <LabeledSquareCheckbox
              className="_generic-checkbox"
              onClick={filters.toggleOnlyHeicoSportiv}
              checked={filters.onlyHeicoSportiv}
            >
              {cms.translate('carFilters.onlyHeicoSportiv')}
            </LabeledSquareCheckbox>

            <LabeledSquareCheckbox
              className="_generic-checkbox"
              onClick={filters.toggleOtherMakes}
              checked={filters.otherMakes}
            >
              {cms.translate('carFilters.otherMakes')}
            </LabeledSquareCheckbox>

            <PageLink page={carsForSalePage} urlParams={filters.getUrlParams()} onClick={close}>
              <Button theme="dark-gray">{cms.translate('carFilters.showOffersButton')}</Button>
            </PageLink>
          </div>
        </div>
      </animated.div>
    );
  };

  render() {
    const { show } = this.props;

    return (
      <Portal>
        <Transition
          native
          from={{ opacity: show ? 0 : 1, transform: `translateY(${show ? -175 : 0}px)` }}
          enter={{ opacity: show ? 1 : 0, transform: `translateY(${show ? 0 : -175}px)` }}
          leave={{ opacity: show ? 1 : 0, transform: `translateY(${show ? 0 : -175}px)` }}
          items={show}
          config={{ ...config.default, friction: 32, tension: 330 }}
        >
          {show => (show ? style => this.renderDropdown(style) : null)}
        </Transition>
      </Portal>
    );
  }
}

export default withFilters(withCMS(CarSelectionDropdown));
