import React, { Component, Fragment } from 'react';
import withCMS from '../../hoc/CMSData';
import { Transition, animated, Trail } from 'react-spring';
import PageLink from '../core/PageLink';
import MobileModal from './MobileModal';

class MobileMenuModal extends Component {
  state = {
    showServices: false
  };

  renderMenu = style => {
    const { cms, close } = this.props;
    const { showServices } = this.state;

    const header = cms.getRegion('header');
    const landingPage = cms.getPage('landing');
    const services = header.meta.servicesDropdown;
    if (!header || !landingPage || !services) return null;

    return (
      <animated.div className="mobile-menu-modal mobile-modal" style={style}>
        <div className="_title-area">
          <div className="_title">MENÜÜ</div>
          <div className="_close-button" onClick={close} />
        </div>
        <div className="_links">
          <PageLink className="_link" page={landingPage} onClick={close}>
            AVALEHT
          </PageLink>

          <div
            className={this.state.showServices ? '_link _dropdown --open' : '_link _dropdown'}
            onClick={() => this.setState({ showServices: !showServices })}
          >
            {cms.translate(services.title)}
            <span className="_chevron" />
          </div>

          {this.renderServicesLinks(services)}

          {header.meta.links.links.map((link, i) => (
            <PageLink key={i} className="_link" link={link.link} onClick={close}>
              {cms.translate(link.title)}
            </PageLink>
          ))}
        </div>

        {false && <div className="_locales">{this.renderLocaleButtons()}</div>}
      </animated.div>
    );
  };

  renderServicesLinks = services => {
    const { cms, close } = this.props;
    const { showServices } = this.state;

    return (
      <Transition items={showServices} from={{ height: 0 }} enter={{ height: 'auto' }} leave={{ height: 0 }}>
        {toggle =>
          toggle
            ? style => (
                <div className="_services-links" style={style}>
                  <Trail
                    keys={Object.keys(services.links)}
                    items={services.links}
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}
                  >
                    {link => linkStyle => (
                      <PageLink className="_link" style={linkStyle} link={link.link} onClick={close}>
                        {cms.translate(link.title)}
                      </PageLink>
                    )}
                  </Trail>
                </div>
              )
            : () => null
        }
      </Transition>
    );
  };

  renderLocaleButtons() {
    const { cms } = this.props;

    const keys = Object.keys(cms.data.locales);
    return keys.map((locale, i) => {
      return (
        <Fragment key={locale}>
          <a className="_locale" onClick={() => cms.setLocale(locale)}>
            {locale.toUpperCase()}
          </a>
          {i < keys.length - 1 && <div className="_bullet">&bull;</div>}
        </Fragment>
      );
    });
  }

  render() {
    const { show } = this.props;

    return <MobileModal show={show}>{style => this.renderMenu(style)}</MobileModal>;
  }
}

export default withCMS(MobileMenuModal);
