import React from 'react';
import withCMS from './CMSData';
import * as CarUtils from 'js/util/cars';

const { Provider, Consumer } = React.createContext();
function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export const SortingOptions = [
  { value: '-', label: 'Sorteeri', disabled: true },
  { value: 'priceDescending', label: 'Hind kahanevalt' },
  { value: 'priceAscending', label: 'Hind kasvavalt' },
  { value: 'nameAscending', label: 'A-z' },
  { value: 'nameDescending', label: 'Z-a' },
  { value: 'ageAscending', label: 'Uuemad eespool' },
  { value: 'ageDescending', label: 'Vanemad eespool' },
  { value: 'mileageDescending', label: 'Läbisõit kahanevalt' },
  { value: 'mileageAscending', label: 'Läbisõit kasvavalt' }
];

export const LocationOptions = [
  { value: '-', label: 'Vali asukoht', disabled: true },
  { value: 'Pärnu', label: 'Pärnu' },
  { value: 'Tallinn', label: 'Tallinn' }
];

export const PageSizeOptions = [
  { value: '10', label: '10 kuulutust' },
  { value: '25', label: '25 kuulutust' },
  { value: '50', label: '50 kuulutust' },
  { value: '999', label: 'Kõik' }
];

class _FiltersDataProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVolvoModels: [],
      otherMakes: false,
      onlyNew: false,
      onlyHeicoSportiv: false,
      toggleModel: this.toggleModel,
      toggleOnlyNew: this.toggleOnlyNew,
      toggleOnlyHeicoSportiv: this.toggleOnlyHeicoSportiv,
      setOnlyHeicoSportiv: this.setOnlyHeicoSportiv,
      toggleOtherMakes: this.toggleOtherMakes,
      filteredCars: [],
      perPage: 10,
      currentPage: 1,
      maxPages: 1,
      sortBy: void 0,
      setSortBy: this.setSortBy,
      onlySelectModel: this.onlySelectModel,
      setPage: this.setPage,
      setPerPage: this.setPerPage,
      deleteFilters: this.deleteFilters,
      goToNextPage: this.goToNextPage,
      goToPrevPage: this.goToPrevPage,
      getUrlParams: this.getUrlParams,
      salonLocation: void 0,
      setSalonLocation: this.setSalonLocation
    };
  }

  componentDidMount = () => {
    const urlModels = getParameterByName('models');
    if (urlModels) {
      this.setState({ selectedVolvoModels: urlModels.split(',') });
    }
    let urlFilters = getParameterByName('filters');
    if (urlFilters) {
      urlFilters = urlFilters.split(',');
      if (urlFilters.find(f => f === 'heico')) this.toggleOnlyHeicoSportiv();
      if (urlFilters.find(f => f === 'others')) this.setOtherMakes(true);
      if (urlFilters.find(f => f === 'new')) this.toggleOnlyNew();
    }
    const urlOrderBy = getParameterByName('orderBy');
    if (urlOrderBy) this.setSortBy(urlOrderBy);
    const urlPage = getParameterByName('page') && Number(getParameterByName('page'));
    if (urlPage) window.setTimeout(() => this.setPage(urlPage));
    const perPage = getParameterByName('perPage') && Number(getParameterByName('perPage'));
    if (perPage) this.setPerPage(perPage);

    this._updateFilteredCars();
  };

  deleteFilters = () => {
    this.setState({ onlyNew: false, onlyHeicoSportiv: false, otherMakes: false, selectedVolvoModels: [] }, () =>
      this._updateFilteredCars()
    );
  };

  goToNextPage = () => {
    if (this.state.currentPage >= this.state.maxPages) return;
    this.setPage(this.state.currentPage + 1);
  };

  goToPrevPage = () => {
    if (this.state.currentPage <= 1) return;
    this.setPage(this.state.currentPage - 1);
  };

  onlySelectModel = model => {
    this.setState({ selectedVolvoModels: [model], onlyHeicoSportiv: false, onlyNew: false, sortBy: void 0 }, () =>
      this._updateFilteredCars(true)
    );
  };

  setPage = page => {
    page = page > this.state.maxPages ? this.state.maxPages : page;
    this.setState({ currentPage: page }, () => this._updateFilteredCars());
  };

  setPerPage = perPage => {
    this.setState({ perPage, currentPage: 1 }, () => this._updateFilteredCars());
  };

  toggleModel = model => {
    if (this.state.selectedVolvoModels.includes(model)) {
      this.setState({ selectedVolvoModels: this.state.selectedVolvoModels.filter(vm => vm !== model) }, () =>
        this._updateFilteredCars(true)
      );
    } else {
      const newSelection = [...this.state.selectedVolvoModels, model];
      this.setState({ selectedVolvoModels: newSelection }, () => this._updateFilteredCars(true));
    }
  };

  toggleOnlyNew = () => {
    this.setState({ onlyNew: !this.state.onlyNew }, () => this._updateFilteredCars(true));
  };

  toggleOtherMakes = () => {
    this.setOtherMakes(!this.state.otherMakes);
  };

  setOtherMakes = state => {
    this.setState({ otherMakes: state }, () => this._updateFilteredCars(true));
  };

  toggleOnlyHeicoSportiv = () => {
    this.setOnlyHeicoSportiv(!this.state.onlyHeicoSportiv);
  };

  setOnlyHeicoSportiv = state => {
    !this.state.onlyHeicoSportiv
      ? this.setState({ onlyHeicoSportiv: state, otherMakes: false }, () => this._updateFilteredCars(true))
      : this.setState({ onlyHeicoSportiv: state }, () => this._updateFilteredCars(true));
  };

  setSortBy = value => {
    this.setState({ sortBy: value }, () => this._updateFilteredCars(true));
  };

  setSalonLocation = value => {
    this.setState({ salonLocation: value }, () => this._updateFilteredCars(true));
  };

  getUrlParams = (changeType, model) => {
    let models = this.state.selectedVolvoModels;

    //Models
    if (changeType === 'models') {
      models = this.state.selectedVolvoModels.includes(model)
        ? this.state.selectedVolvoModels.filter(vm => vm !== model)
        : [...this.state.selectedVolvoModels, model];
    }
    if (changeType === 'solo-model') {
      //Clear all other filters
      return `?models=${model}`;
    }

    //Filters
    let filterParams = [];
    if (this.state.onlyHeicoSportiv) filterParams.push('heico');
    if (this.state.onlyNew) filterParams.push('new');
    if (this.state.otherMakes) filterParams.push('others');
    if (changeType === 'filters') {
      filterParams = filterParams.includes(model) ? filterParams.filter(vm => vm !== model) : [...filterParams, model];
    }
    let filters = '';
    if (filterParams && filterParams.length > 0) filters = `&filters=${filterParams}`;

    //Order by
    let orderBy = '';
    orderBy = changeType === 'orderBy' ? model : this.state.sortBy;
    orderBy = orderBy ? `&orderBy=${orderBy}` : '';

    //Pagination
    let page = '';
    page = changeType === 'page' ? model : this.state.currentPage;
    page = page ? `&page=${page}` : '';

    //Page size
    let perPage = '';
    perPage = changeType === 'perPage' ? model : this.state.perPage;
    perPage = perPage ? `&perPage=${perPage}` : '';

    return (models && models.length > 0) || filters || orderBy || page || perPage
      ? `?models=${models}${filters}${orderBy}${page}${perPage}`
      : '?';
  };

  _updateFilteredCars = (goToPageOne = false) => {
    let _cars = [...this.props.cms.data.cars];
    const { cms } = this.props;

    // Sort
    const S = this.state.sortBy || 'nameDescending';
    if (S) {
      _cars = _cars.sort((a, b) => {
        const aPrice = a.price.bargain !== '0' ? a.price.bargain : a.price.default;
        const bPrice = b.price.bargain !== '0' ? b.price.bargain : b.price.default;
        const aTitle = CarUtils.getTitle(cms, a);
        const bTitle = CarUtils.getTitle(cms, b);

        if (S === 'priceDescending') return bPrice - aPrice;
        if (S === 'priceAscending') return aPrice - bPrice;
        if (S === 'nameAscending') return ('' + aTitle).localeCompare(bTitle);
        if (S === 'nameDescending') return ('' + bTitle).localeCompare(aTitle);
        if (S === 'ageAscending') {
          if (Number(CarUtils.getRegistrationDateObject(b)) < Number(CarUtils.getRegistrationDateObject(a))) {
            return -1;
          } else if (Number(CarUtils.getRegistrationDateObject(b)) === Number(CarUtils.getRegistrationDateObject(a))) {
            return 0;
          }
          return 1;
        }
        if (S === 'ageDescending') {
          if (Number(CarUtils.getRegistrationDateObject(b)) > Number(CarUtils.getRegistrationDateObject(a))) {
            return -1;
          } else if (Number(CarUtils.getRegistrationDateObject(b)) === Number(CarUtils.getRegistrationDateObject(a))) {
            return 0;
          }
          return 1;
        }
        if (S === 'mileageAscending') return a.mileage - b.mileage;
        if (S === 'mileageDescending') return b.mileage - a.mileage;

        return 0;
      });
    }

    // Salon locations filter
    if (this.state.salonLocation) {
      _cars = _cars.filter(c => c.storeLocation === this.state.salonLocation);
    }

    // SelectedVolvoModels filter
    if (this.state.selectedVolvoModels.length > 0) {
      _cars = _cars.filter(car => {
        // Replace CC with Cross Country due to car names having Cross Country in them instead
        const selectedModels = [...this.state.selectedVolvoModels].map(s => s.replace('CC', ' Cross Country'));
        return !!selectedModels.find(model => car.model.model.includes(model)) || car.make !== 'Volvo';
      });
    }

    // Only Heico Sportiv filter
    if (this.state.onlyHeicoSportiv) {
      _cars = _cars.filter(car => car.model.trim.toLowerCase().includes('heico sportiv'));
    }

    // Other makes
    if (!this.state.otherMakes && this.state.selectedVolvoModels.length > 0) {
      _cars = _cars.filter(car => car.make === 'Volvo');
    } else if (this.state.otherMakes && this.state.selectedVolvoModels.length === 0) {
      _cars = _cars.filter(car => car.make !== 'Volvo');
    }

    // Only new
    if (this.state.onlyNew) {
      _cars = _cars.filter(car => car.isNew === 1);
    }

    const page = goToPageOne ? 1 : this.state.currentPage;

    this.setState({
      filteredCars: _cars.slice((page - 1) * this.state.perPage, page * this.state.perPage),
      currentPage: page,
      maxPages: _cars.length === 0 ? 0 : Math.ceil(_cars.length / this.state.perPage)
    });
  };

  render() {
    return (
      <Provider deleteFilters={this.deleteFilters} value={this.state}>
        {this.props.children}
      </Provider>
    );
  }
}

export const FiltersDataProvider = withCMS(_FiltersDataProvider);

export default Component => {
  class FiltersWrapper extends React.Component {
    render() {
      return <Consumer>{context => <Component filters={context} {...this.props} />}</Consumer>;
    }
  }
  FiltersWrapper.displayName = `Filters(${Component.displayName || Component.name || 'Component'})`;
  return FiltersWrapper;
};
