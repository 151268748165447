import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import * as CarUtils from '../../util/cars';
import withCMS from '../../hoc/CMSData';
import LabeledSquareCheckbox from '../inputs/LabeledSquareCheckbox';
import withCarComparison from 'js/hoc/CarComparison';
import lightboxTheme from 'js/util/lightbox-theme';
import Lightbox from 'react-images';

class CarsForSaleSingleTopDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompared: false,
      hasMounted: false,
      lightboxIsOpen: false,
      currentImage: 0,
      width: window.innerWidth
    };
    this.updateWidth = this.updateWidth.bind(this);
  }

  componentDidMount = () => {
    this.setState({ hasMounted: true });
    window.addEventListener('resize', this.updateWidth);
  };

  updateWidth() {
    this.setState({
      width: window.innerWidth
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  render() {
    const { cms, car } = this.props;
    const { hasMounted, lightboxIsOpen } = this.state;

    let images = [];

    // Map images for both galleries
    if (car.media) {
      images = car.media.map(({ url, thumbnailUrl }) => ({ original: url, thumbnail: thumbnailUrl, src: url }));
    }

    if (car.mainMedia) {
      images.unshift({ original: car.mainMedia.url, thumbnail: car.mainMedia.thumbnailUrl, src: car.mainMedia.url });
    }

    return (
      <div className="cars-for-sale-single-top-details">
        <div className="_horizontal-wrapper">
          {
            <div className={`_gallery ${images.length === 0 ? 'placeholder' : ''}`}>
              {images.length > 0 && hasMounted ? (
                <ImageGallery
                  ref={ig => (this._imageGallery = ig)}
                  onClick={this.openLightbox}
                  items={images}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  onSlide={i => (this._actualCurrentIndex = i)}
                  onThumbnailClick={(event, index) => this.thumbnailClicked(event, index)}
                  disableArrowKeys={lightboxIsOpen}
                />
              ) : (
                <img
                  src={require('img/placeholder.png')}
                  alt="Import Auto, sõltumatu Volvo spetsialist - placeholder car"
                />
              )}
            </div>
          }

          <div className="_data-area">
            {this.renderCarData(cms, car)}
            {this.renderCheckboxesAndPriceArea(cms, car)}
          </div>
        </div>

        {this.renderCheckboxesAndPriceArea(cms, car, '--mobile')}

        {images.length > 0 && (
          <Lightbox
            currentImage={this.state.currentImage}
            images={images}
            isOpen={this.state.lightboxIsOpen}
            onClickImage={this.handleClickImage}
            onClickNext={this.gotoNext}
            onClickPrev={this.gotoPrevious}
            onClickThumbnail={this.gotoImage}
            onClose={this.closeLightbox}
            theme={lightboxTheme}
            backdropClosesModal={true}
          />
        )}
      </div>
    );
  }

  renderCarData = (cms, car) => {
    return (
      <div className="_car-data">
        <div className="_data-item">
          <img
            src={require('../../../img/icons/gray-icons/car-icon.svg')}
            alt="Import Auto, sõltumatu Volvo spetsialist - Body type"
          />
          {CarUtils.getBodyTypeDescription(cms, car)}
        </div>

        <div className="_data-item">
          <img
            src={require('../../../img/icons/gray-icons/gearbox-icon.svg')}
            alt="Import Auto, sõltumatu Volvo spetsialist - Gearbox"
          />
          {CarUtils.getTransmission(cms, car)}
        </div>

        <div className="_data-item">
          <img
            src={require('../../../img/icons/gray-icons/calendar-icon.svg')}
            alt="Import Auto, sõltumatu Volvo spetsialist - Calendar"
          />
          {CarUtils.getRegistrationDate(cms, car)}
        </div>

        <div className="_data-item">
          <img
            src={require('../../../img/icons/gray-icons/color-icon.svg')}
            alt="Import Auto, sõltumatu Volvo spetsialist - Color"
          />
          {CarUtils.getColor(cms, car)}
        </div>

        <div className="_data-item">
          <img
            src={require('../../../img/icons/gray-icons/engine-icon.svg')}
            alt="Import Auto, sõltumatu Volvo spetsialist - Engine"
          />
          {CarUtils.getEngine(cms, car)}
        </div>

        <div className="_data-item">
          <img
            src={require('../../../img/icons/gray-icons/VIN-icon.svg')}
            alt="Import Auto, sõltumatu Volvo spetsialist - VIN"
          />
          {car.vin}
        </div>

        <div className="_data-item">
          <img
            src={require('../../../img/icons/gray-icons/engine-layout-icon.svg')}
            alt="Import Auto, sõltumatu Volvo spetsialist - Engine layout"
          />
          {CarUtils.getDrive(cms, car)}
        </div>

        <div className="_data-item">
          <img
            src={require('../../../img/icons/gray-icons/mileage-icon.svg')}
            alt="Import Auto, sõltumatu Volvo spetsialist - Mileage"
          />
          {CarUtils.getMileage(cms, car)}
        </div>

        <div className="_data-item">
          <img
            src={require('../../../img/icons/gray-icons/fuel-icon.svg')}
            alt="Import Auto, sõltumatu Volvo spetsialist - Fuel type"
          />
          {CarUtils.getFuelType(cms, car)}
        </div>

        {car.serviceBook && (
          <div className="_data-item">
            <img
              src={require('../../../img/icons/gray-icons/service-history-icon.svg')}
              alt="Import Auto, sõltumatu Volvo spetsialist - Service history"
            />
            {cms.translate('car.servicebook')}
          </div>
        )}

        {car.warranty && (
          <div className="_data-item">
            <img
              src={require('../../../img/icons/gray-icons/warranty-icon.svg')}
              alt="Import Auto, sõltumatu Volvo spetsialist - In warranty"
            />
            {cms.translate('car.warranty')}
          </div>
        )}

        {car.status.exchangable && (
          <div className="_data-item">
            <img
              src={require('../../../img/icons/gray-icons/trade-in-icon.svg')}
              alt="Import Auto, sõltumatu Volvo spetsialist - Trade in"
            />
            {cms.translate('car.tradeInOption')}
          </div>
        )}

        {car.storeLocation && (
          <div className="_data-item">
            <img
              src={require('../../../img/icons/gray-icons/location-on.svg')}
              alt="Import Auto, sõltumatu Volvo spetsialist - Location"
            />
            {car.storeLocation}
          </div>
        )}
      </div>
    );
  };

  renderCheckboxesAndPriceArea = (cms, car, additionalClass) => {
    const { comparedCars, openLeaseModal } = this.props;

    return (
      <div
        className={`_checkboxes-and-price-area ${additionalClass || ''}`}
        style={
          this.state.width < 1100 && additionalClass !== '--mobile'
            ? { display: 'none' }
            : this.state.width > 1099 && additionalClass === '--mobile'
            ? { display: 'none' }
            : this.state.width < 1100 && additionalClass === '--mobile'
            ? { display: 'flex' }
            : { display: 'flex' }
        }
      >
        <div className="_checkboxes comparison-checkbox-container">
          <LabeledSquareCheckbox
            onClick={() => this.props.toggleCarToComparison(car)}
            checked={comparedCars.find(_car => _car.id === car.id)}
          >
            {cms.translate('carsForSaleView.inComparison')}
          </LabeledSquareCheckbox>

          {this.props.renderToggleMessages()}
        </div>

        <div className="_prices-container">
          <div className="_prices-content">
            {CarUtils.getDiscountPrice(cms, car) !== 0 ? (
              <div className="_prices discounted">
                <div className="_discountPrice">{CarUtils.getDiscountPrice(cms, car)} €</div>
                <div className="_price">TAVAHIND: {CarUtils.getDefaultPrice(cms, car)} €</div>
              </div>
            ) : (
              <div className="_prices">
                <div className="_price">{CarUtils.getDefaultPrice(cms, car)} €</div>
                <span className="_tax-text">{CarUtils.getTaxString(cms, car)}</span>
              </div>
            )}

            <div className="_leasing">
              <div className="_monthly-payment-text">
                {cms.translate('car.monthlyPayment')} <span>{CarUtils.getMonthlyPayment(cms, car)} €</span>
              </div>

              <span className="_leasing-button" onClick={openLeaseModal}>
                {cms.translate('general.leasingCalculator')}
              </span>
            </div>
          </div>
          {CarUtils.getDiscountPrice(cms, car) !== 0 ? (
            <div className="_tax-text-discount">{CarUtils.getTaxString(cms, car)}</div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  // Lightbox
  openLightbox = event => {
    event.preventDefault();
    this.setState({ lightboxIsOpen: true, currentImage: this._actualCurrentIndex || 0 });
  };

  closeLightbox = () => {
    this.setState({ lightboxIsOpen: false });
    this._imageGallery.slideToIndex(this._onCloseIndex || 0);
  };

  gotoPrevious = () => {
    if (this.state.currentImage <= 0) return;
    this.gotoImage(this.state.currentImage - 1);
  };

  gotoNext = () => {
    if (this.state.currentImage >= this.props.car.media.length) return;
    this.gotoImage(this.state.currentImage + 1);
  };

  gotoImage = index => {
    this.setState({ currentImage: index });
    this._onCloseIndex = index;
  };

  thumbnailClicked = (event, index) => {
    event.preventDefault();
    this.gotoImage(index);
  };

  handleClickImage = () => {
    if (this.state.currentImage === this.props.car.media.length) return;
    this.gotoNext();
  };
}

export default withCarComparison(withCMS(CarsForSaleSingleTopDetails));
