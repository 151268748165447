import React, { PureComponent } from 'react';
import withCMS from 'js/hoc/CMSData';
import { _getCmsUrl } from 'js/util/api';

class AccidentStatementDownload extends PureComponent {
  render() {
    const { cms, data } = this.props;

    if (!data) return null;

    const title = cms.translate(data.title) || cms.translate('insuranceView.accidentForm');

    const pdfFile = `${cms.translate(data.pdf)}`;
    const fileSubtitle = cms.translate(data.subtitle) || cms.translate('general.download');

    return (
      <div className="accident-statement-download">
        <h3>{title}</h3>
        <a className="pdf-icon" href={_getCmsUrl() + '/api/files/insuranceClaim?path=' + pdfFile} download>
          <img src={require('../../img/icons/pdf.svg')} alt="Import Auto, sõltumatu Volvo spetsialist - pdf icon" />
          {fileSubtitle}
        </a>
      </div>
    );
  }
}
export default withCMS(AccidentStatementDownload);
