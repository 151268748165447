import React, { Component } from 'react';
import Input from './Input';

export default class TextArea extends Component {
  render() {
    const { label, errors, touched, flex, ...props } = this.props;

    return (
      <Input label={label} flex={flex}>
        <textarea
          className={`input input--textarea ${flex ? '--flex' : ''} ${touched && errors ? 'invalid' : ''}`}
          {...props}
          onChange={this.props.onChange}
        />
        {touched && errors && <span className={'input-errors'}>{errors}</span>}
      </Input>
    );
  }
}
