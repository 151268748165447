import React, { Component } from 'react';

export default class ImageThumbnailBox extends Component {
  render() {
    const { imageUrl, thumbCaption } = this.props;
    return (
      <div className="image-thumbnail-box">
        <img src={imageUrl} alt={'Import Auto, sõltumatu Volvo spetsialist - ' + thumbCaption} />
        <div className="thumb-caption">{thumbCaption}</div>
      </div>
    );
  }
}
