import React, { Component } from 'react';
import withCMS from '../hoc/CMSData';
import HeroSection from '../components/sections/HeroSection';
import HorizontalContentSection from '../components/sections/HorizontalContentSection';
import SingleIcon from '../components/SingleIcon';
import PreOrderFormSection from '../components/sections/PreOrderFormSection';
import Section from '../components/sections/Section';
import InfoBox from '../components/InfoBox';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';

class PreOrderPage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef(); // Create a ref object
  }

  render() {
    const { cms } = this.props;
    const preOrderPage = cms.getPage('pre-order');
    if (!preOrderPage) return null;

    const heroHeader = cms.translate(preOrderPage.meta.heroSection.title);
    const heroImage = cms.translate(preOrderPage.meta.heroSection.heroImage);

    const heroButton = preOrderPage.meta.heroSection.heroButton;
    heroButton.action = () => {
      this.formRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    };

    const preOrderTexts = preOrderPage.meta.preOrderSection.textbox;

    const preOrderReasonTitle = cms.translate(preOrderPage.meta.horizontalSection.title);
    const preOrderReasons = preOrderPage.meta.horizontalSection.reasons;

    const preOrderForm = preOrderPage.meta.preOrderForm;

    if (
      !heroHeader ||
      !heroImage ||
      !heroButton ||
      !preOrderTexts ||
      !preOrderReasonTitle ||
      !preOrderReasons ||
      !preOrderForm
    )
      return null;

    return (
      <div className="page pre-order-page">
        <ScrollToTopOnMount />

        <HeroSection imageUrl={heroImage.url} button={heroButton} headerText={heroHeader} />

        <Section>
          <InfoBox>
            {preOrderTexts.map((introText, i) => {
              const text = cms.translate(introText);
              return (
                text && (
                  <span key={i}>
                    {i !== 0 && <br />}
                    {i !== 0 && <br />}
                    {text}
                  </span>
                )
              );
            })}
          </InfoBox>
        </Section>

        <HorizontalContentSection title={preOrderReasonTitle}>
          <div className="icons">
            {preOrderReasons.map((reason, i) => {
              const icon = cms.translate(reason.iconImage);
              return <SingleIcon key={i} imgUrl={icon && icon.url} iconText={cms.translate(reason.iconText)} />;
            })}
          </div>
        </HorizontalContentSection>
        <PreOrderFormSection refprop={this.formRef} data={preOrderForm} />
      </div>
    );
  }
}

export default withCMS(PreOrderPage);
