import React, { Component } from 'react';

export default class Button extends Component {
  render() {
    const { children, theme = 'white', small, showLoading, ...props } = this.props;

    const classes = ['button', `--${theme}`];
    if (small) classes.push('--small');

    return (
      <button className={`${classes.join(' ')} ${showLoading ? showLoading : ''}`} {...props}>
        <div className="_content">{children}</div>
        <div className="_anim-area">
          <span className="_anim-text">{children}</span>
        </div>
      </button>
    );
  }
}
