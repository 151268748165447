import React, { Component } from 'react';
import withCMS from 'js/hoc/CMSData';
import InfoBox from '../components/InfoBox';
import AccidentStatementDownload from '../components/AccidentStatementDownload';
import SingleIcon from '../components/SingleIcon';
import HeroSection from '../components/sections/HeroSection';
import Section from '../components/sections/Section';
import HorizontalContentSection from '../components/sections/HorizontalContentSection';
import ContactUsSection from '../components/sections/ContactUsSection';
import ScrollToTopOnMount from 'js/components/core/ScrollToTopOnMount';

class InsurancePage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef(); // Create a ref object
  }
  render() {
    const { cms } = this.props;
    const insurancePage = cms.getPage('insurance');
    if (!insurancePage) return null;

    const heroHeader = cms.translate(insurancePage.meta.heroSection.title);
    const heroImage = cms.translate(insurancePage.meta.heroSection.heroImage);
    const heroButton = insurancePage.meta.heroSection.heroButton;
    heroButton.action = () => {
      this.formRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    };
    const insuranceInfo = insurancePage.meta.introSection.textbox;
    const accidentStatement = insurancePage.meta.accidentFile;
    const horizontalSectionTitle = cms.translate(insurancePage.meta.horizontalSection.title);
    const horizontalSection = insurancePage.meta.horizontalSection.reasons;
    const contactFormData = insurancePage.meta.contactForm;

    return (
      <div className="page insurance-page">
        <ScrollToTopOnMount />

        {heroHeader && heroImage && heroButton && (
          <HeroSection imageUrl={heroImage.url} button={heroButton} headerText={heroHeader} />
        )}
        {insuranceInfo && (
          <Section>
            <InfoBox>
              {insuranceInfo.map((introText, i) => (
                <span key={i}>
                  {i !== 0 && <br />}
                  {i !== 0 && <br />}
                  {cms.translate(introText)}
                </span>
              ))}
            </InfoBox>
            <AccidentStatementDownload data={accidentStatement} />
          </Section>
        )}
        {horizontalSectionTitle && horizontalSection && (
          <HorizontalContentSection title={horizontalSectionTitle}>
            <div className="icons">
              {horizontalSection.map((reason, i) => {
                const icon = cms.translate(reason.iconImage);
                return <SingleIcon key={i} imgUrl={icon && icon.url} iconText={cms.translate(reason.iconText)} />;
              })}
            </div>
          </HorizontalContentSection>
        )}
        {contactFormData && <ContactUsSection refprop={this.formRef} data={contactFormData} />}
      </div>
    );
  }
}

export default withCMS(InsurancePage);
