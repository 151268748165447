import React, { Component } from 'react';
import IntersectionObserver from './IntersectionObserver';

export default class Dots extends Component {
  render() {
    const { color = '#FAFAFA', mirrored = false, mirroredTurned = false, hideOnMobile = false } = this.props;

    const style = {
      transform: mirrored ? 'scaleY(-1)' : mirroredTurned ? 'scaleX(-1) scaleY(-1)' : void 0
    };

    return (
      <IntersectionObserver>
        {isIntersecting => (
          <svg
            viewBox="0 0 351 75"
            xmlns="http://www.w3.org/2000/svg"
            className={`dots${hideOnMobile ? ' --hide-on-mobile' : ''}${isIntersecting ? ' --animate' : ''}`}
            style={style}
          >
            <g fill={color} fillRule="evenodd">
              <path
                d="M4.18678682,43.2719272 C3.33860127,43.3663955 3.21848555,45.065797 3.89176576,44.9980263 C4.75786331,44.8778873 4.8231894,43.1928614 4.18678682,43.2719272"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M9.94587858,42.1193912 C9.0924699,42.2669581 8.9929384,44.5151089 9.64925501,44.4201527 C10.5282857,44.2648866 10.5844569,42.0077536 9.94587858,42.1193912"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M16.2910002,41.5422996 C15.4353211,41.6886447 15.3258848,43.9433528 15.9975975,43.8428955 C16.8551634,43.6866286 16.9306367,41.4331608 16.2910002,41.5422996"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M22.4059551,40.9656206 C21.1305367,41.1072027 20.9816025,43.3701156 21.971338,43.2657289 C23.2738354,43.1133482 23.3794433,40.8528349 22.4059551,40.9656206"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M28.7472521,39.8115865 C27.5056638,39.9660316 27.2912427,42.2141945 28.3184098,42.1120052 C29.5882452,41.9703338 29.7371844,39.7012686 28.7472521,39.8115865"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M35.0758785,39.2342974 C33.7716885,39.3806664 33.6960654,41.6555309 34.6456941,41.5337429 C35.9226101,41.4030164 36.0887332,39.1292693 35.0758785,39.2342974"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M41.9897059,38.6575216 C40.6851681,38.7864075 40.6130877,41.078194 41.5666764,40.9568897 C42.8239483,40.8236714 43.0165567,38.5502972 41.9897059,38.6575216"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M48.3364794,37.5046497 C47.0685675,37.6326491 46.9117827,39.9198517 47.9195227,39.8033932 C49.1828901,39.6502136 49.3362665,37.3839945 48.3364794,37.5046497"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M55.2442043,36.352133 C53.9669184,36.5366988 53.8482751,39.3795068 54.8252118,39.2246697 C56.1057043,39.0537296 56.2532067,36.1985347 55.2442043,36.352133"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M62.4972456,35.7791673 C61.0608717,35.9495842 60.6693667,38.1133997 61.5910079,38.5802462 C62.7963298,39.2127091 64.4522032,35.5547449 62.4972456,35.7791673"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M69.440272,34.6204845 C67.824957,34.8060403 67.4920096,37.6360513 68.8836256,37.4948923 C70.5236515,37.31389 70.8162811,34.4793254 69.440272,34.6204845"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M76.928678,34.0438827 C75.2552908,34.2163186 75.0306751,37.0615107 76.3808652,36.9178141 C78.0679789,36.7442729 78.2701331,33.8979754 76.928678,34.0438827"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M84.4162561,32.8900903 C82.7755485,33.0662171 82.5012846,35.9083136 83.8774799,35.7639115 C85.5413475,35.5877846 85.7790429,32.7435002 84.4162561,32.8900903"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M91.9055879,31.7364696 C90.301032,31.9307247 89.9679669,34.755385 91.3738524,34.609959 C93.0286602,34.432688 93.2764139,31.5867976 91.9055879,31.7364696"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M99.3937402,31.1607298 C97.7876743,31.3855924 97.4647445,34.8013626 98.8691134,34.6074335 C100.479471,34.4206428 100.802401,30.9798879 99.3937402,31.1607298"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M107.4575,30.0084486 C105.831417,30.2304149 105.552837,33.6338984 106.940579,33.4547069 C108.595552,33.223492 108.836988,29.8084477 107.4575,30.0084486"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M115.889759,28.8542654 C113.855658,29.0868958 113.48334,32.4891155 115.243166,32.3001033 C117.295883,32.0674729 117.599943,28.6585427 115.889759,28.8542654"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M124.523145,27.7000403 C122.537819,27.9145937 122.10398,31.3231177 123.886206,31.1472724 C125.906383,30.9294011 126.27773,27.5087117 124.523145,27.7000403"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M132.583539,26.547159 C130.566918,26.7632366 130.20714,30.1925273 131.950143,29.9914999 C133.997036,29.7657472 134.334692,26.3439816 132.583539,26.547159"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M141.232127,25.3952525 C139.261892,25.6609648 138.807737,29.6393367 140.609889,29.4138469 C142.62131,29.1359459 142.991981,25.1526986 141.232127,25.3952525"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M150.438839,24.2409203 C148.479282,24.5157463 148.038968,28.4959842 149.823679,28.2590653 C151.809889,27.9901623 152.247005,24.0040014 150.438839,24.2409203"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M160.017762,23.0885658 C157.738084,23.3491185 157.054889,27.3464863 159.295628,27.1048627 C161.602445,26.8487639 162.207763,22.8335805 160.017762,23.0885658"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M169.222575,21.9358201 C166.95975,22.206152 166.284022,26.2017898 168.507573,25.9501394 C170.818912,25.6842031 171.442662,21.6687849 169.222575,21.9358201"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M179.023973,21.3588222 C176.737141,21.6271093 176.092734,25.6380018 178.314427,25.3719504 C180.591953,25.1025454 181.256135,21.0927708 179.023973,21.3588222"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M188.817924,20.2054431 C186.588647,20.4764357 185.847899,24.4761492 188.121714,24.2188778 C190.388497,23.9433115 191.055405,19.9344505 188.817924,20.2054431"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M199.17585,19.0511893 C196.952039,19.3273362 196.238808,23.3320488 198.483945,23.0640581 C200.739745,22.7983978 201.45653,18.7843638 199.17585,19.0511893"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M266.7357,9.82476797 C264.316565,10.1080787 262.601675,14.1066482 265.463784,14.4066242 C268.752239,14.7543742 269.849579,9.47035183 266.7357,9.82476797"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M254.632252,12.1324603 C252.213117,12.415771 250.498227,16.4143405 253.360335,16.7143165 C256.648791,17.0620666 257.74613,11.7780441 254.632252,12.1324603"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M241.376094,13.8632295 C238.956959,14.1465402 237.24207,18.1451097 240.104178,18.4450858 C243.392633,18.7928358 244.489973,13.5088134 241.376094,13.8632295"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M230.079698,15.5939987 C227.962955,15.8773094 226.462427,19.875879 228.966771,20.175855 C231.84417,20.523605 232.804342,15.2395826 230.079698,15.5939987"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M219.705314,16.7478449 C217.588571,17.0311556 216.088042,21.0297251 218.592387,21.3297012 C221.469786,21.6774512 222.429958,16.3934288 219.705314,16.7478449"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M209.330929,17.901691 C207.214187,18.1850018 205.713658,22.1835713 208.218003,22.4835473 C211.095401,22.8312973 212.055573,17.5472749 209.330929,17.901691"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M278.801396,8.09857078 C275.951096,8.47047501 274.822562,13.6401823 277.949782,13.248014 C280.859174,12.8904138 281.903126,7.70163453 278.801396,8.09857078"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M291.483149,6.94458382 C288.643388,7.31920138 287.486368,12.4838983 290.643956,12.0944331 C293.537051,11.7563636 294.580873,6.54826586 291.483149,6.94458382"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M305.067716,5.21498553 C301.877452,5.58675072 300.594556,10.7618133 304.131614,10.3626789 C307.381293,10.012581 308.579309,4.80786844 305.067716,5.21498553"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M318.314821,3.48645216 C315.145726,3.85316766 313.844476,9.03160047 317.3857,8.6318578 C320.662832,8.25944797 321.821232,3.05937666 318.314821,3.48645216"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M332.162718,1.75606119 C328.962492,2.12489509 327.673413,7.30678377 331.244989,6.90038345 C334.475964,6.52358091 335.65624,1.32575496 332.162718,1.75606119"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M346.553136,0.0271630767 C343.374478,0.407010942 342.087821,5.58376111 345.643914,5.16863326 C348.859954,4.78996139 350.093553,-0.419716765 346.553136,0.0271630767"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M3.17317208,51.9269206 C1.48369499,52.1365211 1.26455267,55.5175208 2.57940658,55.3805659 C4.32519958,55.2352746 4.47088637,51.7923475 3.17317208,51.9269206"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M8.9337581,51.3501728 C7.18588827,51.5216162 7.07873004,54.9458508 8.33724394,54.8033674 C10.0970202,54.6655176 10.2303727,51.2123231 8.9337581,51.3501728"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M14.7008857,50.7735546 C12.974104,50.9722692 12.8179589,54.37187 14.1107489,54.2262212 C15.8490118,54.0455716 15.9994163,50.6312929 14.7008857,50.7735546"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M20.9949698,49.6249235 C19.3919266,49.8160211 19.2951469,52.4696723 20.0580596,52.9951907 C21.1730857,53.7194073 23.0221966,49.3860515 20.9949698,49.6249235"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M26.8047614,49.0434925 C25.0640998,49.2614275 24.9254264,53.2317392 26.2293703,53.0722449 C27.9865899,52.8530925 28.0724846,48.8779107 26.8047614,49.0434925"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M33.4861526,48.4662526 C31.3585998,48.691642 31.1415541,52.6510218 32.7794921,52.4955393 C34.942378,52.2858188 35.1329239,48.305949 33.4861526,48.4662526"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M40.4086828,47.889877 C38.2449041,48.1106652 38.0767849,52.0918244 39.7035497,51.917518 C41.8842613,51.7083502 42.0245623,47.7202187 40.4086828,47.889877"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M46.7437169,46.7361725 C44.6275076,46.9589658 44.3831953,50.9240098 46.0456819,50.7645485 C48.2014466,50.5496716 48.3840991,46.5642709 46.7437169,46.7361725"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M53.6566421,46.1596145 C51.5511028,46.3882784 51.2917004,50.3539946 52.9682711,50.1871915 C55.1074991,49.9739928 55.3085079,45.9817649 53.6566421,46.1596145"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M60.5665528,45.5837672 C58.5492361,45.8346572 58.1438423,50.3863438 59.8908965,50.1858777 C61.9457497,49.9694235 62.2867953,45.375922 60.5665528,45.5837672"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M67.8272879,44.4300719 C65.4286884,44.7005335 64.9268842,49.2402555 67.023665,49.0314781 C69.4762483,48.8049072 69.9216149,44.2201083 67.8272879,44.4300719"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M75.3244152,43.8527388 C72.9483879,44.1111859 72.3910628,48.6577691 74.5328005,48.4549519 C76.9324934,48.1976637 77.4365709,43.6499215 75.3244152,43.8527388"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M82.2384617,42.7006918 C79.8640029,42.9701463 79.307937,47.5101148 81.4496507,47.3006649 C83.8894616,47.0402677 84.3228491,42.470863 82.2384617,42.7006918"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M90.2943885,42.1237276 C87.9042278,42.3741655 87.4024384,46.9416231 89.5150606,46.7231795 C91.9351955,46.4727416 92.415892,41.8942515 90.2943885,42.1237276"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M97.7933759,40.9714764 C95.4425,41.2611803 94.8529084,46.3788783 97.0229478,46.1461455 C99.4155553,45.8443201 99.9120534,40.7060155 97.7933759,40.9714764"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M106.199453,40.3945002 C103.457443,40.6887852 102.802314,45.8212548 105.30967,45.5678428 C108.108856,45.2817324 108.694898,40.1294102 106.199453,40.3945002"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M114.278562,39.2417881 C111.547975,39.5500352 110.846582,44.6737869 113.393746,44.4134894 C116.205086,44.139492 116.735745,38.9609407 114.278562,39.2417881"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M122.296965,38.0915567 C119.784881,38.3915488 119.166338,42.292557 120.753317,43.1180908 C123.09533,44.3413918 125.66591,37.7260108 122.296965,38.0915567"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M130.97161,37.51137 C128.284282,37.8482184 127.552659,43.5504072 130.098663,43.2583912 C132.875134,42.9639517 133.494785,37.2108722 130.97161,37.51137"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M139.969573,36.3587298 C136.924307,36.6913707 136.028781,42.3936168 138.985571,42.1047756 C142.128879,41.78634 142.854625,36.0426617 139.969573,36.3587298"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M148.613561,35.2054319 C145.544248,35.5354707 144.68982,41.240915 147.638051,40.9508463 C150.78504,40.6322277 151.49097,34.8822452 148.613561,35.2054319"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M157.825789,34.0510257 C154.798979,34.3978926 153.885615,40.1019271 156.86681,39.7958032 C159.950094,39.4830724 160.758112,33.7349913 157.825789,34.0510257"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M167.380911,32.8996016 C163.998941,33.2749271 162.962969,39.5583729 166.309501,39.2173831 C169.78243,38.8610015 170.705,32.5373 167.380911,32.8996016"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M177.187701,32.3233704 C173.827723,32.693967 172.726544,38.9742352 176.130293,38.641049 C179.625038,38.2716215 180.461288,31.9527738 177.187701,32.3233704"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M186.970566,31.169823 C183.591058,31.5520692 182.55121,37.8218122 185.931814,37.487064 C189.406752,37.1195195 190.27768,30.7954931 186.970566,31.169823"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M196.758039,30.0172078 C193.383561,30.4117973 192.358039,36.6849648 195.723657,36.33179 C199.194485,35.9544566 200.089326,29.6283704 196.758039,30.0172078"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M207.709049,28.8624977 C204.358103,29.2469028 203.284357,35.5259166 206.689441,35.1784048 C210.110315,34.7999502 211.063378,28.4840431 207.709049,28.8624977"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M218.070237,27.709788 C214.727497,28.0950738 213.666202,34.3880759 217.060768,34.0231946 C220.490259,33.6607138 221.440017,27.3173005 218.070237,27.709788"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M229.028086,26.5541417 C225.700782,26.9249766 224.590557,32.6371544 228.030232,32.2927292 C231.42833,31.9648099 232.393597,26.1998129 229.028086,26.5541417"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M240.540799,25.4020811 C237.226955,25.7671679 236.122715,31.4838925 239.553386,31.1388409 C242.925643,30.7804326 243.950127,25.0280898 240.540799,25.4020811"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M252.05969,24.2476173 C248.744047,24.6042395 247.65764,30.3457457 251.082808,29.9835687 C254.43496,29.6502769 255.493709,23.8798854 252.05969,24.2476173"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M264.161368,23.094234 C260.888447,23.4517551 259.725452,29.1855828 263.199004,28.8303103 C266.527043,28.4694165 267.604054,22.7220974 264.161368,23.094234"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M276.591675,21.9410252 C272.983688,22.3135386 271.671693,28.0392492 275.532638,27.675712 C279.214455,27.3144189 280.453831,21.5617796 276.591675,21.9410252"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M289.514489,20.7856976 C286.277306,21.124889 285.056518,26.2707502 288.576766,25.9468772 C291.859379,25.6175333 292.983482,20.4410354 289.514489,20.7856976"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M301.837931,19.6320488 C298.986987,19.9639917 297.88759,25.1285982 301.016137,24.7919301 C303.917754,24.4777065 304.959869,19.2847491 301.837931,19.6320488"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M315.651405,17.9002956 C312.817927,18.2160558 311.731082,22.8001775 314.834526,22.4844173 C317.738986,22.2033683 318.811868,17.5822959 315.651405,17.9002956"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M329.498839,16.7470317 C326.673191,17.0455962 325.531032,21.6338802 328.696177,21.3318839 C331.564992,21.0367512 332.637151,16.4233008 329.498839,16.7470317"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M343.893706,15.0167019 C341.074291,15.3166011 339.951156,19.9261205 343.098248,19.5987496 C345.980188,19.3114416 347.048903,14.6881864 343.893706,15.0167019"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M1.4424634,61.1569243 C-0.261656308,61.3232315 -0.450728328,64.7373504 0.848060513,64.6120174 C2.5633021,64.4939152 2.76843906,61.0364118 1.4424634,61.1569243"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M7.20208792,60.5797585 C5.54685166,60.7384995 5.27750497,64.1508422 6.61593257,64.035608 C8.30439222,63.8992084 8.55000792,60.4645244 7.20208792,60.5797585"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M12.9655322,60.0030363 C11.3251279,60.1726706 11.0269765,63.5722321 12.3863644,63.4587605 C14.0633236,63.313196 14.3157813,59.8838339 12.9655322,60.0030363"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M19.3030583,59.4259693 C17.6268061,59.5714741 17.3955608,63.0087463 18.727711,62.8811498 C20.3962181,62.7445991 20.661763,59.3095654 19.3030583,59.4259693"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M25.6419003,58.849373 C23.9829293,58.9959304 23.7190499,62.4314084 25.0773455,62.3042483 C26.7920361,62.1598462 26.9570921,58.7265234 25.6419003,58.849373"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M31.9830431,58.2725561 C30.3164558,58.4292947 30.0613239,61.8359605 31.4223702,61.7282693 C33.1249641,61.5736632 33.3008816,58.1478049 31.9830431,58.2725561"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M38.6783337,57.6961914 C36.5968043,57.90033 36.2793864,61.8577535 37.9873392,61.7276301 C40.0884019,61.5485612 40.3459988,57.5505486 38.6783337,57.6961914"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M45.0068843,57.1190928 C42.940496,57.3162512 42.6210712,61.2846363 44.3215728,61.1505227 C46.4158382,60.9671196 46.7015782,56.9769554 45.0068843,57.1190928"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M51.9188316,56.5433205 C49.9048356,56.7426539 49.4989139,60.7270818 51.2441541,60.5725425 C53.2871445,60.3956061 53.6529201,56.3798223 51.9188316,56.5433205"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M58.8263242,55.9659384 C56.8088937,56.1638833 56.4259055,60.1632453 58.16068,59.9948281 C60.1748741,59.8165683 60.5945428,55.8106446 58.8263242,55.9659384"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M66.1028889,54.8139703 C63.7164258,55.0373885 63.1783141,59.5985364 65.3082355,59.4178485 C67.752264,59.2115225 68.1840049,54.6149695 66.1028889,54.8139703"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M73.5973816,54.2367344 C71.2046087,54.4551427 70.6701038,59.0217559 72.8093141,58.8409232 C75.2128008,58.6319088 75.698498,54.042985 73.5973816,54.2367344"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M80.4996145,53.6594342 C78.1353263,53.8866073 77.5805582,58.4458916 79.7202162,58.2639271 C82.1105978,58.0695303 82.6358691,53.4718186 80.4996145,53.6594342"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M87.9925531,53.0836016 C85.6367231,53.2947803 85.064736,57.8680847 87.2169208,57.6870743 C89.626166,57.4859517 90.1102407,52.8780096 87.9925531,53.0836016"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M96.0546545,51.9285162 C93.7222122,52.1478943 93.1203607,56.7133586 95.2910956,56.5332722 C97.6406725,56.3106198 98.2221767,51.744064 96.0546545,51.9285162"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M103.895886,51.3544367 C101.149884,51.6092692 100.495021,56.7581001 103.011268,56.5311778 C105.801501,56.2811992 106.388789,51.111739 103.895886,51.3544367"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M111.959758,50.1995627 C109.241682,50.4650616 108.54255,55.6034505 111.092188,55.3773707 C113.835645,55.1570877 114.484015,49.9723235 111.959758,50.1995627"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M120.597284,49.6231152 C117.882318,49.8746308 117.197117,55.022771 119.727865,54.8007121 C122.500119,54.5661908 123.120169,49.3885938 120.597284,49.6231152"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M128.663692,49.0465545 C125.935472,49.3173794 125.276557,54.4652901 127.800931,54.222443 C130.562207,54.0086928 131.194678,48.8059456 128.663692,49.0465545"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M137.664825,47.8946102 C134.612105,48.1683212 133.724477,53.8923475 136.688435,53.6461271 C139.834716,53.3927353 140.538821,47.6125325 137.664825,47.8946102"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M146.865722,47.3164472 C143.800773,47.6060911 142.978893,53.3265572 145.900101,53.0684472 C149.011419,52.7916504 149.805478,47.0536655 146.865722,47.3164472"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M156.089654,46.1648287 C153.028357,46.4538857 152.190541,52.1763111 155.133414,51.9143532 C158.246728,51.6456205 159.013712,45.8689969 156.089654,46.1648287"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M165.653973,45.0111503 C162.249472,45.3012937 161.242777,51.0302484 164.59322,50.7599627 C168.068599,50.479748 168.961169,44.7132843 165.653973,45.0111503"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M174.877855,44.4347554 C171.484438,44.7602216 170.448673,51.0552982 173.827212,50.7591006 C177.264119,50.4593908 178.199168,44.115143 174.877855,44.4347554"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M185.004659,43.2814023 C181.2001,43.6090906 180.145298,49.9097455 183.852865,49.6046564 C187.718045,49.3052171 188.68919,42.954844 185.004659,43.2814023"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M194.794801,42.1288916 C191.018376,42.5022271 189.927306,49.3606278 193.656192,49.0266535 C197.498708,48.6843298 198.502817,41.7698692 194.794801,42.1288916"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M205.510245,40.9772753 C201.433131,41.3669289 200.099873,48.8154905 204.273951,48.4484083 C208.435332,48.0753863 209.631223,40.573366 205.510245,40.9772753"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M216.218324,39.8241111 C211.762779,40.2305349 210.342709,47.6611456 214.878545,47.2945672 C219.425166,46.9325427 220.725399,39.4108567 216.218324,39.8241111"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M227.17238,38.6698767 C222.715536,39.0765643 221.281451,46.5275403 225.851261,46.1391822 C230.353291,45.7680082 231.68749,38.2620434 227.17238,38.6698767"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M238.685091,38.0946514 C234.257958,38.4894108 232.800927,45.9468799 237.375909,45.5625701 C241.897236,45.1817434 243.208803,37.6650604 238.685091,38.0946514"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M249.852451,36.9395176 C245.830974,37.3200486 244.465341,44.1957319 248.668521,43.8322396 C252.775706,43.4835143 254.053345,36.541948 249.852451,36.9395176"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M261.623969,36.3598234 C257.948548,36.7208722 256.715282,43.015678 260.558019,42.6792972 C264.285519,42.3653418 265.416844,36.0133512 261.623969,36.3598234"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M274.283945,35.2056657 C270.628215,35.5279043 269.402898,41.2584367 273.228744,40.9494863 C276.947524,40.6493946 278.120498,34.8789976 274.283945,35.2056657"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M286.634482,34.6301954 C283.332256,34.9419197 282.218914,40.6896377 285.699825,40.3719186 C289.035268,40.0889688 290.056977,34.2849007 286.634482,34.6301954"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M299.535851,33.4745394 C296.638384,33.7641995 295.612731,38.9361885 298.711985,38.6417406 C301.649586,38.3772163 302.625071,33.1681221 299.535851,33.4745394"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M313.355163,32.8964057 C310.474441,33.1595868 309.446984,37.7565609 312.540159,37.4887422 C315.471293,37.2383144 316.472344,32.6216307 313.355163,32.8964057"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M327.090571,31.7399326 C324.948499,31.9380474 324.136522,35.3821962 326.491807,35.1840814 C328.662602,35.0001177 329.433704,31.5298439 327.090571,31.7399326"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M341.158182,31.1624415 C339.35112,31.3189461 338.719626,34.2045721 340.662419,34.0309319 C342.490186,33.8755696 343.102125,30.9762352 341.158182,31.1624415"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M185.917301,68.6592605 C182.552169,68.8820127 181.340813,75.187335 184.689855,74.9957203 C188.096362,74.8053031 189.211177,68.4424964 185.917301,68.6592605"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M195.698093,67.5055535 C192.301274,67.7251523 191.176674,74.0403526 194.486901,73.8415579 C197.936333,73.6508536 198.987494,67.2859547 195.698093,67.5055535"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M205.842107,66.9287885 C202.095465,67.1636557 200.813198,73.463689 204.510926,73.264611 C208.321623,73.0610595 209.521201,66.7062238 205.842107,66.9287885"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M215.989368,66.3529349 C211.945223,66.5963991 210.40548,73.4818372 214.5447,73.2641779 C218.672325,73.0397869 220.076413,66.099373 215.989368,66.3529349"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M226.353147,65.2001445 C222.3011,65.4785515 220.794825,72.9221515 224.918599,72.6868452 C229.051201,72.4561984 230.452644,64.9159132 226.353147,65.2001445"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M237.658026,64.6227647 C233.235113,64.9005547 231.582793,72.3633768 236.114427,72.1090291 C240.580351,71.8722629 242.138287,64.347319 237.658026,64.6227647"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M249.169673,64.0469517 C244.777472,64.3131433 243.102541,71.7807682 247.639711,71.5324019 C252.125364,71.2864124 253.660118,63.7510513 249.169673,64.0469517"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M260.327361,63.4691858 C256.336705,63.7272481 254.773747,70.6135586 258.943551,70.3787452 C263.019187,70.1509064 264.494869,63.1994991 260.327361,63.4691858"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M272.08843,63.4675948 C268.441898,63.7154311 267.028504,70.0168979 270.844332,69.802336 C274.540984,69.6049848 275.853023,63.2381168 272.08843,63.4675948"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M284.170025,62.8905468 C280.542046,63.1093094 279.141795,68.8504117 282.939574,68.6486513 C286.629732,68.4548254 287.978566,62.6740512 284.170025,62.8905468"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M296.51185,62.3139021 C293.235615,62.4994568 291.96357,67.6817363 295.417086,67.495077 C298.729008,67.3360301 299.91011,62.102944 296.51185,62.3139021"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M309.404415,61.73598 C306.528323,61.91018 305.36331,66.5145043 308.442354,66.3413931 C311.355448,66.1900568 312.470003,61.5541587 309.404415,61.73598"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M322.646757,61.1591269 C319.785534,61.3336425 318.623425,65.9422782 321.693418,65.7642011 C324.602912,65.6003702 325.739679,60.9763011 322.646757,61.1591269"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M335.786973,60.5812592 C333.657591,60.7116137 332.75055,64.1609959 335.086431,64.0350979 C337.241348,63.9136565 338.112862,60.4375349 335.786973,60.5812592"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M349.845254,60.5809311 C348.049893,60.6804235 347.337301,63.5762359 349.266852,63.4580156 C351.080722,63.3596938 351.775961,60.4545173 349.845254,60.5809311"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M209.666687,53.6556204 C208.544786,53.735196 208.209295,56.0403193 209.338746,55.9594603 C210.461725,55.8734674 210.805847,53.5811788 209.666687,53.6556204"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M220.738056,52.5028304 C218.863428,52.6187633 218.283245,55.4791868 220.189201,55.3822044 C222.06007,55.2974841 222.608926,52.39693 220.738056,52.5028304"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M232.013699,51.3502175 C229.786994,51.5025116 229.091642,55.5053293 231.368978,55.3818152 C233.61031,55.2463094 234.295536,51.2003217 232.013699,51.3502175"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M243.640891,50.1979086 C240.683716,50.3984852 239.749871,55.5510241 242.783705,55.3804738 C245.789664,55.1871035 246.682856,49.9985329 243.640891,50.1979086"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M255.84873,49.0441393 C252.116674,49.2557101 251.006399,54.988163 254.791155,54.803317 C258.559143,54.6028816 259.631091,48.8325686 255.84873,49.0441393"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M267.944544,47.8915214 C264.2154,48.129826 263.114306,54.4390004 266.895829,54.22547 C270.656856,54.0201977 271.742008,47.6461384 267.944544,47.8915214"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M280.619965,47.3140353 C276.93045,47.5686325 275.76214,53.8579969 279.589819,53.648739 C283.297019,53.4418061 284.45096,47.0792013 280.619965,47.3140353"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M293.290595,46.7378007 C289.606811,46.9769546 288.445775,53.3028091 292.270691,53.0706892 C295.987552,52.8502925 297.126537,46.4904405 293.290595,46.7378007"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M306.543704,46.1608012 C302.835963,46.3764212 301.719963,52.1285331 305.533442,51.9174052 C309.269916,51.7085234 310.36293,45.9260899 306.543704,46.1608012"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M320.609072,45.5828112 C317.303561,45.7830126 316.252016,50.9509246 319.710686,50.7642961 C323.060773,50.5787987 324.058598,45.3780854 320.609072,45.5828112"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M334.666745,45.0054984 C331.731395,45.1833709 330.818256,49.7824716 333.881808,49.6106907 C336.846185,49.4328182 337.762953,44.8190977 334.666745,45.0054984"
                transform="matrix(-1 0 0 1 351 0)"
              />
              <path
                d="M349.08177,44.4283176 C346.143222,44.5849812 345.219189,48.615805 348.300932,48.4569655 C351.256614,48.317709 352.165961,44.2575108 349.08177,44.4283176"
                transform="matrix(-1 0 0 1 351 0)"
              />
            </g>
          </svg>
        )}
      </IntersectionObserver>
    );
  }
}
