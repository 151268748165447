import React, { Component } from 'react';
import withCMS from 'js/hoc/CMSData';
import HeroSection from 'js/components/sections/HeroSection';

class UniversalPage extends Component {
  render() {
    const { cms } = this.props;
    const universalPage = cms.getPage('universal');
    if (!universalPage) return null;
    const heroImage = cms.translate(universalPage.meta.heroSection.heroImage);
    const heroButton = universalPage.meta.heroSection.heroButton;
    const heroTitle = cms.translate(universalPage.meta.heroSection.title);

    const content = universalPage.meta.content;

    return (
      <div className="page universal-page">
        {heroImage && heroButton && universalPage && (
          <HeroSection imageUrl={heroImage.url} button={heroButton} headerText={heroTitle} />
        )}
        <div className="content-wrapper">
          <div className="content" dangerouslySetInnerHTML={{ __html: cms.translate(content) }} />
        </div>
      </div>
    );
  }
}

export default withCMS(UniversalPage);
