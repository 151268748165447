import React, { Component } from 'react';
import { COMPARISON_CARS_BOX } from '../components/CarsArray';
import withCarComparison from 'js/hoc/CarComparison';
import withSubscriptionHandler from 'js/hoc/SubscriptionHandler';
import withCMS from '../hoc/CMSData';
import { Link } from 'react-router-dom';

class CarComparisonBox extends Component {
  allCars = COMPARISON_CARS_BOX;

  constructor(props) {
    super(props);

    this.state = {
      visibleCars: [],
      animatingCar: null,
      animatingCarOut: null,
      animatingIn: false,
      animateAreaOut: props.comparedCars.length === 0
    };
  }

  openModal = () => {
    document.body.classList.add('modal-open');
  };

  closeModal = () => {
    document.body.classList.remove('modal-open');
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (e.target.className === 'car-comparison-section' && document.body.classList[0] === 'modal-open') {
      this.closeModal();
    }
  };

  animateCarIn(car) {
    this.setState({ animatingCar: car });

    this.props.setTimeout(() => {
      this.setState({ animatingIn: true });

      this.props.setTimeout(() => {
        this.setState({ animatingIn: false, visibleCars: [...this.props.comparedCars], animatingCar: null });
      }, 350);
    }, 100);
  }

  animateCarOut(car) {
    this.setState({ animatingCarOut: car });

    // this.props.clearAllSubscriptions();

    this.props.setTimeout(() => {
      this.setState({ animatingOut: true });

      this.props.setTimeout(() => {
        this.setState({
          animatingOut: false,
          animatingCarOut: null,
          visibleCars: [...this.state.visibleCars.filter(_car => _car.id !== car.id)]
        });

        if (this.state.visibleCars.length === 0) {
          this.props.setTimeout(() => {
            this.setState({ animateAreaOut: true });
          }, 20);
        }
      }, 350);
    }, 50);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.comparedCars.length < this.props.comparedCars.length) {
      if (prevProps.comparedCars.length === 0) {
        // this.props.clearAllSubscriptions();
        this.setState({ animateAreaOut: false });

        this.props.setTimeout(() => {
          this.animateCarIn(this.props.comparedCars[this.props.comparedCars.length - 1]);
        }, 500);
      } else {
        this.animateCarIn(this.props.comparedCars[this.props.comparedCars.length - 1]);
      }
    }

    if (prevProps.comparedCars.length > this.props.comparedCars.length) {
      // if (this.state.animatingCarOut) {
      //   this.props.clearAllSubscriptions();
      // }

      const leavingCars = prevProps.comparedCars.filter(
        car => !this.props.comparedCars.map(_car => _car.id).includes(car.id)
      );
      let offset = 0;

      for (let i = 0; i < leavingCars.length; i++) {
        this.props.setTimeout(() => {
          this.animateCarOut(leavingCars[i]);
        }, offset);

        offset = offset + 500;
      }
    }
  }

  render() {
    const { cms } = this.props;
    const { visibleCars, animatingIn, animatingCar, animatingCarOut, animatingOut, animateAreaOut } = this.state;

    const emptyIteration = 5 - visibleCars.length;
    const placeholders = [];

    for (let i = 0; i < emptyIteration; i++) {
      if (animatingCar !== null && typeof animatingCar !== 'undefined' && i === 0) {
        placeholders.push(
          <div key={i} className="car-placeholder">
            <div className={`car-picture-wrapper ${animatingIn ? 'animate-in' : ''}`} key={i}>
              <div className="img-wrap">
                <img
                  src={
                    animatingCar.mainMedia && animatingCar.mainMedia.thumbnailUrl
                      ? animatingCar.mainMedia.thumbnailUrl
                      : require('img/placeholder.png')
                  }
                  alt="Import Auto, sõltumatu Volvo spetsialist - car placeholder"
                />
              </div>
            </div>
          </div>
        );
      } else {
        placeholders.push(<div key={i} className="car-placeholder" />);
      }
    }

    return (
      <div className={`car-comparison-box ${animateAreaOut ? 'animate-out' : ''}`}>
        <div className="comparison-pictures-container">
          <div className="maxwidth-wrapper">
            <div className="comparison-pictures">
              {visibleCars.map((car, i) => {
                if (animatingCarOut !== null && animatingCarOut.id === car.id) {
                  return (
                    <div key={i} className="car-placeholder">
                      <div className={`car-picture-wrapper ${!animatingOut ? 'animate-in' : ''}`} key={i}>
                        <div className="img-wrap">
                          <img
                            src={
                              animatingCarOut.mainMedia && animatingCarOut.mainMedia.thumbnailUrl
                                ? animatingCarOut.mainMedia.thumbnailUrl
                                : require('img/placeholder.png')
                            }
                            alt="Import Auto, sõltumatu Volvo spetsialist - car"
                          />
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="car-picture-wrapper">
                      <Link to={cms.getCarUrl(car)} key={i}>
                        <div className="img-wrap">
                          <img
                            src={
                              car.mainMedia && car.mainMedia.thumbnailUrl
                                ? car.mainMedia.thumbnailUrl
                                : require('img/placeholder.png')
                            }
                            alt=""
                          />
                        </div>
                      </Link>
                      <span className="close" onClick={() => this.props.removeCarFromComparison(car)} />
                    </div>
                  );
                }
              })}

              {placeholders.length ? placeholders : null}
            </div>
          </div>

          <div className="options">
            <div className="option-item" onClick={this.openModal}>
              Ava võrdlus
            </div>
            <div className="option-item icon-cancel" onClick={this.props.clearCarsFromComparison}>
              Nulli võrdlus
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSubscriptionHandler(withCarComparison(withCMS(CarComparisonBox)));
