import React, { Component } from 'react';
import Section from './Section';
import Button from '../core/Button';
import InfoBox from '../InfoBox';
import withCMS from '../../hoc/CMSData';
import PageLink from '../core/PageLink';
import Dots from '../Dots';
import withFilters from '../../hoc/FiltersData';

class TwoCarColumnSection extends Component {
  render() {
    const { cms, data } = this.props;
    const leftCol = data.columns[0];
    const rightCol = data.columns[1];

    const lBackground = cms.translate(leftCol.backgroundImage);
    const rBackground = cms.translate(rightCol.backgroundImage);

    return (
      <Section className="two-car-column-section">
        <div className="_titles">
          <h2>{cms.translate(data.smallTitle)}</h2>
          <h1>{cms.translate(data.largeTitle)}</h1>
        </div>

        <div className="_cars-wrapper">
          <CarArea imageUrl={lBackground && lBackground.url} mirrored />
          <CarArea imageUrl={rBackground && rBackground.url} mirroredTurned />
        </div>

        <div className="_text-wrapper">
          <InfoBox>{cms.translate(leftCol.description)}</InfoBox>
          <InfoBox>{cms.translate(rightCol.description)}</InfoBox>
        </div>

        <div className="_button-wrapper">
          <PageLink link={data.link.link}>
            <Button onClick={() => this.props.filters.deleteFilters()} theme="dark-gray">
              {cms.translate(data.link.title)}
            </Button>
          </PageLink>
        </div>
      </Section>
    );
  }
}

const CarArea = ({ imageUrl, mirrored, mirroredTurned }) => (
  <div className="_car-area">
    <Dots color="#29a8e0" mirrored={mirrored} mirroredTurned={mirroredTurned} />
    <div className="_car-image" style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}} />
  </div>
);

export default withFilters(withCMS(TwoCarColumnSection));
