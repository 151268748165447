export default [
  {
    image: require('../../img/thumbnails/volvo-v40(1).png'),
    name: '	Volvo V60 AWD Summum R-design Xenium Intelli SAFE Winter 2.4 D5 158 kW',
    type: 'Luukpära',
    year: '05/2014',
    fuel: 'D',
    milage: 100000 + ' KM',
    color: 'VALGE',
    transmission: 'A',
    totalPrice: 10000,
    monthlyPayment: 'kuumakse: 222 €',
    taxIncluded: true,
    model: 'V40',
    isNew: false,
    isCarHeicoTuned: false
  },

  {
    image: require('../../img/thumbnails/volvo-v40(2).png'),
    name: 'Volvo V40 Momentum Business 2.0 88kW',
    type: 'Luukpära',
    year: '05/2015',
    fuel: 'D',
    milage: 10000 + ' KM',
    color: 'VALGE',
    transmission: 'A',
    totalPrice: 5000,
    monthlyPayment: 'kuumakse: 222 €',
    taxIncluded: false,
    model: 'V40',
    isNew: false,
    isCarHeicoTuned: false
  },

  {
    image: require('../../img/thumbnails/volvo-xc60.png'),
    name: 'Volvo XC60 AWD SUMMUM XENIUM INTELLI SAFE WINTER PRO M15 5-kohta 2.4 D5 158Kw',
    type: 'Luukpära',
    year: '05/2016',
    fuel: 'D',
    milage: 20000 + ' KM',
    color: 'VALGE',
    transmission: 'A',
    totalPrice: 8000,
    monthlyPayment: 'kuumakse: 222 €',
    taxIncluded: false,
    model: 'XC60',
    isNew: false,
    isCarHeicoTuned: false
  },

  {
    image: require('../../img/thumbnails/volvo-v40(1).png'),
    name: 'Volvo XC60 AWD INSCRIPTION INTELLI SAFE WINTER PRO MY16 2.4 D5 162Kw',
    type: 'Luukpära',
    year: '05/2017',
    fuel: 'D',
    milage: 150000 + ' KM',
    color: 'VALGE',
    transmission: 'A',
    totalPrice: 4500,
    monthlyPayment: 'kuumakse: 222 €',
    taxIncluded: false,
    model: 'XC60',
    isNew: false,
    isCarHeicoTuned: true
  },

  {
    image: require('../../img/thumbnails/volvo-v40(1).png'),
    name: 'Volvo XXX',
    type: 'Luukpära',
    year: '05/2002',
    fuel: 'D',
    milage: 50000 + ' KM',
    color: 'VALGE',
    transmission: 'A',
    totalPrice: 9500,
    monthlyPayment: 'kuumakse: 222 €',
    taxIncluded: false,
    model: 'xxx',
    isNew: true,
    isCarHeicoTuned: true
  }
];

export const CAR_DETAILS = {
  name: 'Volvo V40 OCEAN RACE INTELLI SAFE PRO WINTER FULL MY15',
  id: 3,
  imgSrc: require('../../img/thumbnails/volvo_v40_rear.jpg'),
  bodyType: 'luukpära, 5 ust, 5 istekohta',
  regDate: '08/2014',
  engine: '2.0 D4 130 Kw',
  engineLayout: 'esivedu',
  mileage: '42 000 km',
  fuel: 'diisel',
  gearbox: 'automaat (Geartronic)',
  color: 'tumesinine met. (Ocean Blue)',
  vin: 'YV1MV5550E2160284',
  serviceHistory: 'hooldusraamat',
  inWarranty: 'garantii',
  tradeInOption: 'vahetusvõimalus',
  rainSensor: true,
  seatBeltTensioner: true,
  tractionControl: true,
  brakeControl: true,
  stabilityControl: true,
  extraBrakeLight: false,
  alarm: true,
  immobiliser: true,
  powerSteering: true,
  airBags: true,
  abs: true,
  centralLocking: true,
  parkingAid: 'taga',
  doorLights: true,
  electricMirrors: true,
  electricWindows: true,
  sunVisorMirrors: false,
  cruiseControl: true,
  price: 14990,
  monthlyPayment: 222,
  currency: '€',
  images: [
    {
      original: require('../../img/thumbnails/volvo_v40_rear.jpg'),
      thumbnail: require('../../img/thumbnails/2010_volvo_c70_by_heico_sportiv_front_2.png'),
      caption: 'Volvo C70'
    },
    {
      original: require('../../img/banners/box-2.jpg'),
      thumbnail: require('../../img/thumbnails/2011_volvo_s60_by_heico_sportiv_drive5.png'),
      caption: 'Volvo C60'
    },
    {
      original: require('../../img/thumbnails/volvo_v40_rear.jpg'),
      thumbnail: require('../../img/thumbnails/heico_volution_x_forged_image.png'),
      caption: 'Forged exhaust'
    },
    {
      original: require('../../img/banners/main.jpg'),
      thumbnail: require('../../img/thumbnails/2010_volvo_c70_by_heico_sportiv_front_2.png'),
      caption: 'Volvo C70'
    },
    {
      original: require('../../img/thumbnails/volvo_v40_rear.jpg'),
      thumbnail: require('../../img/thumbnails/2011_volvo_s60_by_heico_sportiv_drive5.png'),
      caption: 'Volvo C60'
    },
    {
      original: require('../../img/thumbnails/volvo_v40_rear.jpg'),
      thumbnail: require('../../img/thumbnails/heico_volution_x_forged_image.png'),
      caption: 'Forged exhaust'
    },
    {
      original: require('../../img/thumbnails/volvo_v40_rear.jpg'),
      thumbnail: require('../../img/thumbnails/2010_volvo_c70_by_heico_sportiv_front_2.png'),
      caption: 'Volvo C70'
    },
    {
      original: require('../../img/thumbnails/volvo_v40_rear.jpg'),
      thumbnail: require('../../img/thumbnails/2011_volvo_s60_by_heico_sportiv_drive5.png'),
      caption: 'Volvo C60'
    }
  ],
  options: [
    {
      title: 'Oluline lisavarustus',
      type: 'important',
      options: [
        'R-DESIGN Erimudel',
        'INSCRIPTION BURSTING BLUE',
        'navigatsiooniseade (hääljuhtimisega, kaardiga)',
        'ekraan',
        'kõlarid',
        'helivõimendi',
        'winter pack',
        'kõlarid',
        'helivõimendi',
        'light pack',
        'kõlarid',
        'helivõimendi',
        'acc - adaptiivne püsikiirushoidik',
        'kolmnurk turvavöö',
        'helivõimendi',
        'winter pack',
        'kõlarid',
        'helivõimendi',
        'light pack',
        'kõlarid'
      ]
    },
    {
      title: 'Turva- ja ohutusvarustus',
      type: 'normal',
      options: ['vihmasensor', 'turvavööde eelpingutid esiistmetel']
    },
    {
      title: 'Mugavusvarustus',
      type: 'normal',
      options: ['Parkimisandurid', 'kohtvalgustid', 'elektrilised välispeeglid']
    },
    {
      title: 'Sisutus',
      type: 'normal',
      options: ['iluliistud salongis', 'jalamatid', 'nahkkattega käigukanginupp']
    },
    {
      title: 'Rool',
      type: 'normal',
      options: ['nahkkattega rool', 'multifunktsionaalne rool', 'reguleeritav roolisammas']
    },
    {
      title: 'Audio, video, kommunikatsioon',
      type: 'normal',
      options: [
        'käed vabad süsteem',
        'autotelefon',
        'autokompuuter',
        'navigatsiooniseade (hääljuhtimisega, kaardiga)',
        'ekraan',
        'kõlarid',
        'helivõimendi',
        'stereo (CD, MP3, originaal, USB pesaga)',
        'kõlarid',
        'helivõimendi',
        'stereo (CD, MP3, originaal, USB pesaga)',
        'kõlarid',
        'helivõimendi',
        'stereo (CD, MP3, originaal, USB pesaga)'
      ]
    },
    {
      title: 'Muu varustus',
      type: 'normal',
      options: [
        'käed vabad süsteem',
        'autotelefon',
        'autokompuuter',
        'navigatsiooniseade (hääljuhtimisega, kaardiga)',
        'ekraan',
        'kõlarid',
        'helivõimendi',
        'winter pack',
        'kõlarid',
        'helivõimendi',
        'light pack',
        'kõlarid',
        'helivõimendi',
        'acc - adaptiivne püsikiirushoidik',
        'kolmnurk turvavöö',
        'helivõimendi',
        'winter pack',
        'kõlarid',
        'helivõimendi',
        'light pack',
        'kõlarid',
        'helivõimendi',
        'acc - adaptiivne püsikiirushoidik',
        'kolmnurk turvavöö',
        'helivõimendi',
        'winter pack',
        'kõlarid',
        'helivõimendi',
        'light pack',
        'kõlarid',
        'helivõimendi',
        'acc - adaptiivne püsikiirushoidik',
        'kolmnurk turvavöö'
      ]
    }
  ]
};

export const COMPARISON_CARS = [
  {
    name: 'Volvo V40 OCEAN RACEBOYS DREAM',
    imgSrc: require('../../img/thumbnails/volvo_v40_rear.jpg'),
    bodyType: 'luukpära, 5 ust, 5 istekohta',
    regDate: '08/2014',
    engine: '2.0 D4 130 Kw',
    engineLayout: 'esivedu',
    mileage: '42 000 km',
    fuel: 'diisel',
    gearbox: 'automaat (Geartronic)',
    color: 'tumesinine met. (Ocean Blue)',
    vin: 'YV1MV5550E2160284',
    rainSensor: true,
    seatBeltTensioner: true,
    tractionControl: true,
    brakeControl: true,
    stabilityControl: true,
    extraBrakeLight: false,
    alarm: true,
    immobiliser: true,
    powerSteering: true,
    airBags: true,
    abs: true,
    centralLocking: true,
    parkingAid: 'taga',
    doorLights: true,
    electricMirrors: true,
    electricWindows: true,
    sunVisorMirrors: false,
    cruiseControl: true,
    price: 14990,
    monthlyPayment: 222
  },
  {
    name: 'Volvo S90 AWD INSCRI…',
    imgSrc: require('../../img/banners/lease-banner.jpg'),
    bodyType: 'luukpära, 5 ust, 5 istekohta',
    regDate: '08/2014',
    engine: '2.5 D4 225 Kw',
    engineLayout: 'esivedu',
    mileage: '42 000 km',
    fuel: 'diisel',
    gearbox: 'automaat (Geartronic)',
    color: 'valge met. (Crystal)',
    vin: 'YV1PS68BCH1002305',
    rainSensor: true,
    seatBeltTensioner: true,
    tractionControl: true,
    brakeControl: true,
    stabilityControl: true,
    extraBrakeLight: false,
    alarm: true,
    immobiliser: true,
    powerSteering: false,
    airBags: true,
    abs: true,
    centralLocking: true,
    parkingAid: 'ees, taga',
    doorLights: true,
    electricMirrors: true,
    electricWindows: true,
    sunVisorMirrors: false,
    cruiseControl: true,
    price: 14990,
    monthlyPayment: 222
  },
  {
    name: 'Volvo V40 AWD INSCRI…',
    imgSrc: require('../../img/thumbnails/volvo_v40_rear.jpg'),
    bodyType: 'luukpära, 5 ust, 5 istekohta',
    regDate: '08/2014',
    engine: '2.0 D4 130 Kw',
    engineLayout: 'esivedu',
    mileage: '42 000 km',
    fuel: 'diisel',
    gearbox: 'automaat (Geartronic)',
    color: 'valge met. (Crystal)',
    vin: 'YV1PS68BCH1002305',
    rainSensor: true,
    seatBeltTensioner: true,
    tractionControl: true,
    brakeControl: true,
    stabilityControl: true,
    extraBrakeLight: false,
    alarm: true,
    immobiliser: true,
    powerSteering: false,
    airBags: true,
    abs: true,
    centralLocking: true,
    parkingAid: 'ees, taga',
    doorLights: true,
    electricMirrors: true,
    electricWindows: true,
    sunVisorMirrors: false,
    cruiseControl: true,
    price: 34990,
    monthlyPayment: 328
  },
  {
    name: 'Volvo S90 AWD INSCRI…',
    imgSrc: require('../../img/banners/contact-banner.jpg'),
    bodyType: 'luukpära, 5 ust, 5 istekohta',
    regDate: '08/2014',
    engine: '2.5 D4 225 Kw',
    engineLayout: 'esivedu',
    mileage: '42 000 km',
    fuel: 'diisel',
    gearbox: 'automaat (Geartronic)',
    color: 'valge met. (Crystal)',
    vin: 'YV1PS68BCH1002305',
    rainSensor: true,
    seatBeltTensioner: true,
    tractionControl: true,
    brakeControl: true,
    stabilityControl: true,
    extraBrakeLight: false,
    alarm: true,
    immobiliser: true,
    powerSteering: false,
    airBags: true,
    abs: true,
    centralLocking: true,
    parkingAid: 'ees, taga',
    doorLights: true,
    electricMirrors: true,
    electricWindows: true,
    sunVisorMirrors: false,
    cruiseControl: true,
    price: 44990,
    monthlyPayment: 422
  }
];

export const COMPARISON_CARS_BOX = [
  {
    id: 2,
    modelName: 'S60',
    modelGroup: 'S',
    manufacturer: 'Volvo',
    src: require('../../img/thumbnails/volvo-xc60.png'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    id: 6,
    modelName: 'S80',
    modelGroup: 'S',
    src: require('../../img/thumbnails/volvo-xc60.png'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    id: 3,
    modelName: 'S90',
    modelGroup: 'S',
    src: require('../../img/thumbnails/volvo_v40_rear.jpg'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    id: 5,
    modelName: 'V40',
    modelGroup: 'V',
    src: require('../../img/thumbnails/volvo-xc60.png'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  }
];

export const SELECTION_CARS = [
  {
    modelName: 'S60',
    modelGroup: 'S',
    manufacturer: 'Volvo',
    src: require('../../img/thumbnails/S60x.svg'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    modelName: 'S80',
    modelGroup: 'S',
    src: require('../../img/thumbnails/S60x.svg'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    modelName: 'S90',
    modelGroup: 'S',
    src: require('../../img/thumbnails/S60x.svg'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    modelName: 'V40',
    modelGroup: 'V',
    src: require('../../img/thumbnails/S60x.svg'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    modelName: 'V60',
    modelGroup: 'V',
    src: require('../../img/thumbnails/S60x.svg'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    modelName: 'V90',
    modelGroup: 'V',
    src: require('../../img/thumbnails/S60x.svg'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    modelName: 'V90CC',
    modelGroup: 'V',
    src: require('../../img/thumbnails/S60x.svg'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    modelName: 'XC60',
    modelGroup: 'XC',
    src: require('../../img/thumbnails/S60x.svg'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    modelName: 'XC90',
    modelGroup: 'XC',
    src: require('../../img/thumbnails/S60x.svg'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  },
  {
    modelName: 'XC90CC',
    modelGroup: 'XC',
    src: require('../../img/thumbnails/S60x.svg'),
    isSelected: false,
    isNew: false,
    isHeicoTuned: false
  }
];

export const CAR_COMPARISON = [
  {
    id: 1,
    carName: 'VOLVO V40',
    src: require('../../img/thumbnails/volvo_v40_rear.jpg'),
    mainInfo: [
      {
        fieldName: 'HIND',
        fieldValue: '14 990£'
      }
    ],
    equipmentInfo: [
      {
        equipmentType: 'TURVA-JA OHUTUSVARUSTUS',
        items: [
          {
            fieldName: 'Vihmasensor11',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          }
        ]
      },
      {
        equipmentType: 'Mugavusvarustus',
        items: [
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          }
        ]
      }
    ]
  },
  {
    id: 2,
    carName: 'VOLVO V40',
    src: require('../../img/thumbnails/volvo_v40_front_blue.png'),
    mainInfo: [
      {
        fieldName: 'HIND',
        fieldValue: '14 990£'
      }
    ],
    equipmentInfo: [
      {
        equipmentType: 'TURVA-JA OHUTUSVARUSTUS',
        items: [
          {
            fieldName: 'Vihmasensor21',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          }
        ]
      },
      {
        equipmentType: 'Mugavusvarustus',
        items: [
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          }
        ]
      }
    ]
  },
  {
    id: 3,
    carName: 'VOLVO V40',
    src: require('../../img/thumbnails/volvo_v40_rear.jpg'),
    mainInfo: [
      {
        fieldName: 'HIND',
        fieldValue: '14 990£'
      }
    ],
    equipmentInfo: [
      {
        equipmentType: 'TURVA-JA OHUTUSVARUSTUS',
        items: [
          {
            fieldName: 'Vihmasensor21',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          }
        ]
      },
      {
        equipmentType: 'Mugavusvarustus',
        items: [
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          }
        ]
      }
    ]
  },
  {
    id: 4,
    carName: 'VOLVO V40',
    src: require('../../img/thumbnails/volvo_v40_rear.jpg'),
    mainInfo: [
      {
        fieldName: 'HIND',
        fieldValue: '14 990£'
      }
    ],
    equipmentInfo: [
      {
        equipmentType: 'TURVA-JA OHUTUSVARUSTUS',
        items: [
          {
            fieldName: 'Vihmasensor21',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          }
        ]
      },
      {
        equipmentType: 'Mugavusvarustus',
        items: [
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          },
          {
            fieldName: 'Vihmasensor22',
            fieldValue: true
          },
          {
            fieldName: 'Vihmasensor23',
            fieldValue: false
          }
        ]
      }
    ]
  }
];
